import React, {Component} from 'react';
import { Hub } from 'aws-amplify';
import autobind from 'class-autobind';

export default class AddisonButton extends Component {
	getInitialState() {
		return {
			on: true,
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

    	autobind(this);

        Hub.listen("UIReset", (capsule) => this.setState(this.getInitialState()));
    }

    reset() {
        this.setState(this.getInitialState());
    }

    show() {
      this.setState({on: true});
    }

    hide() {
      this.setState({on: false});
    }

    handleClick(event) {
        event.preventDefault();
        console.log("clicked Addison Button, reloading page...");
        window.location.reload();
    }

	render() {
		return (
 
			<button style={{zIndex: "10000"}} onClick={this.handleClick} className="btnaddison left-button ui-button" id="btnaddison"> 

			&nbsp;Refresh&nbsp;
			  
			</button>

		);
	}
}