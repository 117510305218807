//AppChartContainer.jsx
import React from 'react';
import { Hub } from 'aws-amplify';
import { ResponsiveContainer, LineChart, BarChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { AppChart } from '../components';
import moment from 'moment';
import  './css/chartStyle.css';

// eslint-disable-next-line
const AppWeightChart = (props) => {
    return <AppChart>
        <ResponsiveContainer>
      		<BarChart width={props.width} height={props.height} data={props.data} margin={props.margin }>
	            <CartesianGrid strokeDasharray="3 3" />
	            <XAxis dataKey="name" />
	            <YAxis />
	            <Tooltip />
	            <Legend />
	            <Bar dataKey="weight" fill="#9944BB" />
          </BarChart>
          </ResponsiveContainer>
    	</AppChart>
};

// eslint-disable-next-line
const AppOxygenChart = (props) => {
    return (
            <AppChart>
            {/*<div style={{left: "60vw", top: "25vh", height: "30vh", width: "40vw", position: "absolute"}} >*/}
            <svg style={{position: "absolute", left:"0", width: "100%", height: "100%"}} width="100%" height="100%" viewBox="0 0 800 500" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <linearGradient id="colorOxygen" x1="100%" y1="0" x2="100%" y2="100%">
                    <stop offset="0%" stopColor="#46aa1e" stopOpacity={.8} />
                    <stop offset="100%" stopColor="#da1c1c" stopOpacity={.9} />
                </linearGradient>
            </defs>

                <rect width="100%" height="100%" fill="url(#colorOxygen)" />
            </svg>
            <ResponsiveContainer width="100%" height="100%">


            <LineChart width={props.width} height={props.height} data={props.data} margin={props.margin }>
            {/*<AreaChart width={props.width} height={props.height} data={props.data} margin={props.margin }>*/}
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dwataKey="name" />
                    <YAxis domain={[80, 100]} />
                    <Tooltip />
                    <Legend />
                    {/*<Bar dataKey="Oxygen levels" fill="#9944BB" />*/}
                    <Line type="monotone" dataKey="Oxygen levels" stroke="#9944BB" />
            </LineChart>
            </ResponsiveContainer>
            {/*</div>*/}
            </AppChart>
            );
};


// eslint-disable-next-line
const AppBloodPressureChart = (props) => {
    return <AppChart>
            <ResponsiveContainer width="100%" height="100%">
      		        <LineChart width={props.width} height={props.height} data={props.data} margin={props.margin }>
        	            <CartesianGrid strokeDasharray="3 3" />
        	            <XAxis dataKey="name" />
        	            <YAxis />
        	            <Tooltip />
        	            <Legend />
        	            <Line dataKey="Systolic" fill="#9944BB" />
        	            <Line dataKey="Diastolic" fill="#009F93" />
                </LineChart>
            </ResponsiveContainer>
    	</AppChart>
};


// eslint-disable-next-line
const AppHeartRateChart = (props) => {
    return <AppChart>
            <ResponsiveContainer width="100%" height="100%">
      		<BarChart width={props.width} height={props.height} data={props.data} margin={props.margin }>
	            <CartesianGrid strokeDasharray="3 3" />
	            <XAxis dataKey="name" />
	            <YAxis />
	            <Tooltip />
	            <Legend />
	            <Bar dataKey="Heart rate" fill="#9944BB" />
          </BarChart>
            </ResponsiveContainer>
    	</AppChart>
};


// eslint-disable-next-line
const AppGlucoseChart = (props) => {
    return <AppChart>
            <ResponsiveContainer>
      		<BarChart width={props.width} height={props.height} data={props.data} margin={props.margin }>
	            <CartesianGrid strokeDasharray="3 3" />
	            <XAxis dataKey="name" />
	            <YAxis />
	            <Tooltip />
	            <Legend />
	            <Bar dataKey="Glucose" fill="#9944BB" />
          </BarChart>
            </ResponsiveContainer>
    	</AppChart>
};

// eslint-disable-next-line
const AppTemperatureChart = (props) => {
    return <AppChart>
            <ResponsiveContainer>
      		<BarChart width={props.width} height={props.height} data={props.data} margin={props.margin }>
	            <CartesianGrid strokeDasharray="3 3" />
	            <XAxis dataKey="name" />
	            <YAxis domain={[80, 105]} />
	            <Tooltip />
	            <Legend />
	            <Bar dataKey="Temperature" fill="#9944BB" />
          </BarChart>
            </ResponsiveContainer>
    	</AppChart>
};


const AppSingleResultChart = (props) => {
    return (
        <div id="vitalCont" {...props}>
            {props.children}
        </div>
    );
};

const AppOxygenSingleResultChart = (props) => {
    return (
        <AppSingleResultChart {...props}>
            <header className="vitalName">Pulse/Oxygen</header>
            <div>
                <div className="vitalNumber">{props.oxygen}</div><span className="vitalName">% Sp02</span>
            </div>
            <div>
                <div className="vitalNumber">{props.bpm}</div><span className="vitalName"> bpmPR</span>
            </div>
        </AppSingleResultChart>
    );
};


const AppBloodPressureSingleResultChart = (props) => {
    return (
        <AppSingleResultChart {...props}>
            <header className="vitalName">Blood Pressure</header>
            <div>
                <div className="vitalNumber">{props.systolic}</div><span className="vitalName">SYS. mmHg</span>
            </div>
            <div>
                <div className="vitalNumber">{props.diastolic}</div><span className="vitalName">DIA. mmHg</span>
            </div>
            <div>
                <div className="vitalNumber">{props.bpm}</div><span className="vitalName">Pulse /min</span>
            </div>
        </AppSingleResultChart>
    );
}

const AppTemperatureSingleResultChart = (props) => {
    return (
        <AppSingleResultChart {...props}>
            <header className="vitalName">Temperature</header>
            <div>
                <div className="vitalNumber">{props.temperature}</div><span className="vitalName">°F</span>
            </div>
        </AppSingleResultChart>
    );
};

const AppGlucoseSingleResultChart = (props) => {
    return (
        <AppSingleResultChart {...props}>
            <header className="vitalName">Glucose</header>
            <div>
                <div className="vitalNumber">{props.glucose}</div><span className="vitalName">mg/dL</span>
            </div>
        </AppSingleResultChart>
    );
};

// eslint-disable-next-line
const AppWeightSingleResultChart = (props) => {
    return (
        <AppSingleResultChart {...props}>
             <header className="vitalName">Weight</header>
            <div>
                <div className="vitalNumber">{props.weight}</div><span className="vitalName">Lbs.</span>
            </div>      
        </AppSingleResultChart>
    );
}

export default class AppChartContainer extends React.Component {
    getInitialState() {
        return {
            on: false,
            data: null,
            width: 800,
            height: 500,
            margin: {
                left: 10,
                right: 10, 
                top: 10,
                bottom: 10
            },

            weightChartOn: false,
            glucoseChartOn: false,
            bloodPressureChartOn: false,
            bloodOxygenChartOn: false,
            temperatureChartOn: false,
            weightHistoryChartOn: false,
            glucoseHistoryChartOn: false,
            bloodPressureHistoryChartOn: false,
            bloodOxygenHistoryChartOn: false,
            temperatureHistoryChartOn: false,

            latestBPM: null,
            latestOxygen: null,
            latestGlucose: null,
            latestTemperature: null,
            latestWeight: null,
            latestSystolic: null,
            latestDiastolic: null
        }
    }

    getInitialChartState() {
    	return {
            weightChartOn: false,
            glucoseChartOn: false,
            bloodPressureChartOn: false,
            bloodOxygenChartOn: false,
            temperatureChartOn: false,
            weightHistoryChartOn: false,
            glucoseHistoryChartOn: false,
            bloodPressureHistoryChartOn: false,
            bloodOxygenHistoryChartOn: false,
            temperatureHistoryChartOn: false,
        }
    }


    resetCharts(chartToEnable, data) {
        console.log("in resetCharts, data: ", data);

        const { payload } = data;

        this.setState({
            weightChartOn: false,
            glucoseChartOn: false,
            bloodPressureChartOn: false,
            bloodOxygenChartOn: false,
            temperatureChartOn: false,
            weightHistoryChartOn: false,
            glucoseHistoryChartOn: false,
            bloodPressureHistoryChartOn: false,
            bloodOxygenHistoryChartOn: false,
            temperatureHistoryChartOn: false,
            latestBPM: payload.latestBPM, 
            latestOxygen: payload.latestOxygen,
            latestSystolic: payload.latestSystolic,
            latestDiastolic: payload.latestDiastolic,
            latestWeight: payload.latestWeight,
            latestGlucose: payload.latestGlucose,
            latestTemperature: payload.latestTemperature
        });

        switch (chartToEnable) {
            case "AppGlucoseChart":
                this.setState({ glucoseChartOn: true });
                break;
            case "AppWeightChart":
                this.setState({ weightChartOn: true });
                break;
            case "AppTemperatureChart":
                this.setState({ temperatureChartOn: true });
                break;
            case "AppHeartRateChart":
                this.setState({ heartRateChartOn: true });
                break;
            case "AppBloodPressureChart":
                this.setState({ bloodPressureChartOn: true });
                break;
            case "AppOxygenChart":
                this.setState({ bloodOxygenChartOn: true });
                break;
            default:
                console.log("shouldn't reach the defalut state you idiot");
        }
    }

    resetHistoricalCharts(chartToEnable, data) {
        console.log("in resetCharts, data: ", data);

        const { payload } = data;

        this.setState({
            weightChartOn: false,
            glucoseChartOn: false,
            bloodPressureChartOn: false,
            bloodOxygenChartOn: false,
            temperatureChartOn: false,
            weightHistoryChartOn: false,
            glucoseHistoryChartOn: false,
            bloodPressureHistoryChartOn: false,
            bloodOxygenHistoryChartOn: false,
            temperatureHistoryChartOn: false,
            latestBPM: payload.latestBPM, 
            latestOxygen: payload.latestOxygen,
            latestSystolic: payload.latestSystolic,
            latestDiastolic: payload.latestDiastolic,
            latestWeight: payload.latestWeight,
            latestGlucose: payload.latestGlucose,
            latestTemperature: payload.latestTemperature
        });

        switch (this.state.chartType) {
            case "AppGlucoseChart":
                this.setState({ glucoseHistoryChartOn: true });
                break;
            case "AppWeightChart":
                this.setState({ weightHistoryChartOn: true });
                break;
            case "AppTemperatureChart":
                this.setState({ temperatureHistoryChartOn: true });
                break;
            case "AppBloodPressureChart":
                this.setState({ bloodPressureHistoryChartOn: true });
                break;
            case "AppOxygenChart":
                this.setState({ bloodOxygenHistoryChartOn: true });
                break;
            default:
                console.log("shouldn't reach the defalut state you idiot");
        }
    }

    constructor(props) {
        super(props);

        this.state = this.getInitialState();
        this.resetCharts = this.resetCharts.bind(this);

        Hub.listen("vitalsDataReceived", async (data) => {
            console.log("vitalsDataReceived in AppChartContainer, data: ", data);

            const { payload } = data;

        this.setState({
            chartType: payload.chartType,
            singleResult: payload.singleResult,
            latestBPM: payload.bpm, 
            latestOxygen: payload.oxygen,
            latestSystolic: payload.systolic,
            latestDiastolic: payload.diastolic,
            latestWeight: payload.weight,
            latestGlucose: payload.glucose,
            latestTemperature: payload.temperature,
            absoluteLatest: payload.absoluteLatest
        })

        let parsedData, chart;

        if('bloodpressure' in payload.absoluteLatest) {
            chart = "AppBloodPressureChart";
        } else if('heartrate' in payload.absoluteLatest) {
            chart = "AppHeartRateChart";
        } else if('glucose' in payload.absoluteLatest) {
            chart = "AppGlucoseChart";
        } else if('weight' in payload.absoluteLatest) {
            chart = "AppWeightChart";
        } else if('thermometer' in payload.absoluteLatest) {
            chart = "AppTemperatureChart";
        }

            if(this.state.singleResult) {
                parsedData = await parseData(chart, data);
                this.setState({data: parsedData});
                this.resetCharts(chart, data); 
            } else {
                console.log("this.state.chartType: ", this.state.chartType);
                parsedData = await parseData(this.state.chartType, data);
                this.setState({data: parsedData});
                this.resetHistoricalCharts(this.state.chartType, data);
            }
        })

        Hub.listen("showAppChart", (data) => {
            // const { payload } = data;

            // const { events } = payloasd;

        });

        Hub.listen("showAppGlucoseChart", (data) => {
            this.resetCharts("AppGlucoseChart", data);
        });

        Hub.listen("showAppTemperatureChart", (data) => {
            this.resetCharts("AppTemperatureChart", data);
        });

        Hub.listen("showAppHeartRateChart", (data) => {
            this.resetCharts("AppHeartRateChart", data);
        });

        Hub.listen("showAppBloodPressureChart", (data) => {
            this.resetCharts("AppBloodPressureChart", data);
        });

        Hub.listen("showAppWeightChart", (data) => {
            this.resetCharts("AppWeightChart", data);
        });

        Hub.listen("showAppOxygenChart", async (data) => {
            // let parsedData = await parseData("AppOxygenChart", data);
        	// this.setState({data: parsedData});
         //    this.resetCharts("AppOxygenChart");
        });
    }

    render() {
        let chart = null;

        if(this.state.weightHistoryChartOn) 
            chart = (<AppWeightChart data={this.state.data} width={this.state.width} height={this.state.height} margin={this.state.margin} />);
        if (this.state.weightChartOn)  
            chart = (<AppWeightSingleResultChart weight={this.state.latestWeight} />);
        else if(this.state.temperatureHistoryChartOn) 
            chart = (<AppTemperatureChart data={this.state.data} width={this.state.width} height={this.state.height} margin={this.state.margin} />);
        else if (this.state.temperatureChartOn)
            chart = (<AppTemperatureSingleResultChart temperature={this.state.latestTemperature} />);
        else if(this.state.glucoseHistoryChartOn) 
            chart = (<AppGlucoseChart data={this.state.data} width={this.state.width} height={this.state.height} margin={this.state.margin} />);
        else if (this.state.glucoseChartOn)
            chart = (<AppGlucoseSingleResultChart glucose={this.state.latestGlucose} />); 
        else if (this.state.bloodPressureHistoryChartOn) 
            chart = (<AppBloodPressureChart data={this.state.data} width={this.state.width} height={this.state.height} margin={this.state.margin} />);
        else if (this.state.bloodPressureChartOn)
            chart = (<AppBloodPressureSingleResultChart systolic={this.state.latestSystolic} diastolic={this.state.latestDiastolic} bpm={this.state.latestBPM} />)
        else if (this.state.heartRateChartOn)
            chart = (<AppOxygenSingleResultChart oxygen={this.state.latestOxygen} bpm={this.state.latestBPM} />)
        else if (this.state.bloodOxygenChartOn)
            chart = (<AppOxygenSingleResultChart bpm={this.state.latestBPM} oxygen={this.state.latestOxygen} />);
        else if(this.state.bloodOxygenHistoryChartOn)
            chart = (<AppOxygenChart data={this.state.data} width={this.state.width} height={this.state.height} margin={this.state.margin} />);



        return (<div >
        	{chart}
        	</div>)
    }
}

async function parseData(chartToEnable, data) {
    console.log("parsing data into rechart format, data:", data);

    let parsedData;

    switch (chartToEnable) {
        case "AppGlucoseChart":
        	parsedData = data.payload.glucoseObjects;

        	parsedData = parsedData.map( vital => {
        		return {
        			name: moment(vital.timestamp['$date']).format("MMM Do YY"),
        			"Glucose": vital.glucose.value
        		}
        	});
            break;
        case "AppWeightChart":
            parsedData = data.payload.weightObjects;

            parsedData = parsedData.map( vital => {
            	return {
            		name: moment(vital.timestamp['$date']).format("MMM Do YY"),
            		"Weight": vital.weight.value,
            	}
            });
            break;
        case "AppTemperatureChart":
            parsedData = data.payload.tempObjects;

            parsedData = parsedData.map(vital => {
            	return {
            		name: moment(vital.timestamp['$date']).format("MMM Do YY"),
            		"Temperature": vital.thermometer.value
            	}
            });
            break;
        case "AppHeartRateChart":
            parsedData = data.payload.hrObjects;

            parsedData = parsedData.map(vital => {
            	return {
            		name: moment(vital.timestamp['$date']).format("MMM Do YY"),
            		"Heart rate": vital.heartrate.bpm
            	}
            });
            break;
        case "AppBloodPressureChart":
            parsedData = data.payload.bpObjects;

            parsedData = parsedData.map(vital => {
                console.log("vital object: ", vital);

                return {
                    name: moment(vital.timestamp['$date']).format("MMM Do YY"),
                    "Systolic": vital.bloodpressure.systolic,
                    "Diastolic": vital.bloodpressure.diastolic, 
                    bpm: vital.heartrate.bpm
                }
            });
            break;
        case "AppOxygenChart":
            parsedData = data.payload.hrObjects;

            parsedData = parsedData.map(vital => {
                if(window.LOG_LEVEL === "DEBUG")
            
                    console.log("vital object: ", vital);

                return {
                    name: moment(vital.timestamp['$date']).format("MMM Do YY"),
                    "Oxygen levels": vital.pulseox.pulseavg,

                }
            });

            if(window.LOG_LEVEL === "DEBUG")
                console.log("pulse ox objects after mapping", parsedData);
            break;
        default:
            console.log("shouldn't reach the defalut state you idiot");
    }

    if(window.LOG_LEVEL === "DEBUG")
        console.log("parsedData after parsing into recharts format: ", parsedData);

    return parsedData.reverse();
}