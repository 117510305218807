import React from "react";
import "./styles.scss";

/**
 * React component that displays the 5 most recent balls
 * @param {Object} G
 * @returns {Component} RecentBalls
 */
const RecentBalls = ({ state }) => (
  <div className="recent-balls">
    <div
      className={
        state.history[0].number.length === 0
          ? "recent-balls__empty-spot"
          : "recent-balls__ball"
      }
      style={{ "background-color": state.history[0].color }}
    >
      {state.history[0].letter.length === 0 ? (
        state.currentletter
      ) : (
        <div className="recent-balls__ball-inner1">
          <div
            className="recent-balls__ball-inner2"
            style={{ "background-color": state.history[0].color }}
          >
            <div className="recent-balls__ball-inner3">
              <p className="recent-balls__ball-letter">
                {state.history[0].letter}
              </p>
              <p className="recent-balls__ball-number">
                {state.history[0].number}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
    <div
      className={
        state.history[1].number.length === 0
          ? "recent-balls__empty-spot"
          : "recent-balls__ball"
      }
      style={{ "background-color": state.history[1].color }}
    >
      {state.history[1].letter.length === 0 ? (
        state.currentletter
      ) : (
        <div className="recent-balls__ball-inner1">
          <div
            className="recent-balls__ball-inner2"
            style={{ "background-color": state.history[1].color }}
          >
            <div className="recent-balls__ball-inner3">
              <p className="recent-balls__ball-letter">
                {state.history[1].letter}
              </p>
              <p className="recent-balls__ball-number">
                {state.history[1].number}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
    <div
      className={
        state.history[2].number.length === 0
          ? "recent-balls__empty-spot"
          : "recent-balls__ball"
      }
      style={{ "background-color": state.history[2].color }}
    >
      {state.history[2].letter.length === 0 ? (
        state.currentletter
      ) : (
        <div className="recent-balls__ball-inner1">
          <div
            className="recent-balls__ball-inner2"
            style={{ "background-color": state.history[2].color }}
          >
            <div className="recent-balls__ball-inner3">
              <p className="recent-balls__ball-letter">
                {state.history[2].letter}
              </p>
              <p className="recent-balls__ball-number">
                {state.history[2].number}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
    <div
      className={
        state.history[3].number.length === 0
          ? "recent-balls__empty-spot"
          : "recent-balls__ball"
      }
      style={{ "background-color": state.history[3].color }}
    >
      {state.history[3].letter.length === 0 ? (
        state.currentletter
      ) : (
        <div className="recent-balls__ball-inner1">
          <div
            className="recent-balls__ball-inner2"
            style={{ "background-color": state.history[3].color }}
          >
            <div className="recent-balls__ball-inner3">
              <p className="recent-balls__ball-letter">
                {state.history[3].letter}
              </p>
              <p className="recent-balls__ball-number">
                {state.history[3].number}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
    <div
      className={
        state.history[4].number.length === 0
          ? "recent-balls__empty-spot"
          : "recent-balls__ball"
      }
      style={{ "background-color": state.history[4].color }}
    >
      {state.history[4].letter.length === 0 ? (
        state.currentletter
      ) : (
        <div className="recent-balls__ball-inner1">
          <div
            className="recent-balls__ball-inner2"
            style={{ "background-color": state.history[4].color }}
          >
            <div className="recent-balls__ball-inner3">
              <p className="recent-balls__ball-letter">
                {state.history[4].letter}
              </p>
              <p className="recent-balls__ball-number">
                {state.history[4].number}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default RecentBalls;
