import React from "react";

import '../styles.scss'

const  SpeakerInstructions = () => {
    

return (
    <div className='instruction'>
            If your speakers are connected Addison will say a phrase stating your microphone is connected,
            If your speakers are not connected, you won't hear Addison say these phrases
    </div>
  )
}

export default SpeakerInstructions; 