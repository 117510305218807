// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:8562f0f7-822f-49d5-b7df-814ef8e345da",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_9AfhY5nvf",
    "aws_user_pools_web_client_id": "2p309eaujl21qc8ln281td1jmu",
    "oauth": {},
    "aws_content_delivery_bucket": "addisoncare-hosting-jkeys",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1n9ks11szxsiy.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://jpl2zjoe6zfcnal3etgty5psbi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-dz74eew5jzha7au7vcb26ddllm",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "addisoncare-storage-jkeys",
            "region": "us-east-1"
        }
    ],
    "aws_mobile_analytics_app_id": "9d30dfc3e31d489e9742d7751d9070c4",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
