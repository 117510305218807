import React from 'react';
import Amplify, { Auth, XR, Hub, Credentials, Logger } from 'aws-amplify'; // eslint-disable-line no-unused-vars
import AppSceneInterface from '../interfaces/AppSceneInterface';
import { sceneConfigs } from '../AmplifySceneLoader';
import autobind from 'class-autobind';
import awsmobile from '../aws-exports';
import AWS from 'aws-sdk/global';                       // eslint-disable-line no-unused-vars
import Iot from 'aws-sdk/clients/iot';                  // eslint-disable-line no-unused-vars
import IotData from 'aws-sdk/clients/iotdata';          // eslint-disable-line no-unused-vars
import Polly from 'aws-sdk/clients/polly';              // eslint-disable-line no-unused-vars
import LexRuntime from 'aws-sdk/clients/lexruntime';    // eslint-disable-line no-unused-vars
import Lambda from 'aws-sdk/clients/lambda';            // eslint-disable-line no-unused-vars

import autoCreds, {amplifyConfig} from '../CredentialsModule';

const introConfig = require('../config/introScene.json');
const logger = new Logger("AppScene");

export default class AppScene extends AppSceneInterface {
    getInitialState() {
        return {
            load: true,
            loaded: false,
            play: true,
            sceneController: null,
            sceneConfig: sceneConfigs.introScene,
            domId: "sumerian-dom-id",
            sceneName: "introScene",
            currentSceneName: "introScene"
        };
    }

    constructor(props) {
        super(props);
        autobind(this);

        this.state = this.getInitialState();
        logger.debug("AppScene constructor finished");
    } //end cunstructor 


    async componentDidMount() {
        await autoCreds.deploy("appscene").then ((amplifyConfig) => {
            logger.debug("autoCreds deployed");
            this.setState({
                sceneConfig: introConfig,
                sceneName: "introScene",
            });

            Hub.listen("playIntroScene", this.onHubCapsule);
            Hub.listen("loadIntroScene", this.onHubCapsule);

            this.loadAndStartScene();
        }).catch ((err) => {logger.debug("Unable to load scene.", err); });
    }

    componentDidUpdate() {

    }

    toggle() {
        this.setState({ play: !this.state.play }); //toggle 
    }

    hide() {
        this.setState({ play: false });
    }

    show() {
        this.setState({ play: true });
    }

    async onHubCapsule(capsule) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

    }

    async loadAndStartScene() {
        logger.debug("in loadAndStartScene, this.state.sceneConfig: ", this.state.sceneConfig);

        await XR.loadScene(this.state.currentSceneName, this.state.domId);
        // this.setState({ loaded: true });
        const controller = XR.getSceneController(this.state.currentSceneName);
        this.props.onLoaded(controller);
        XR.start(this.state.currentSceneName);
    }

    render() { logger.debug("rendering AppScene");
        return (
          <div className={this.state.play ? "App-scene" : "hidden"}>
            <div id={this.state.domId} className={this.state.play ? "App-scene" : "hidden"}></div>
          </div>
        );
    }
}; //end AppScene
