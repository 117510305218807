import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
import AmplifySceneLoader from '../../AmplifySceneLoader';

export default class NextButton extends Component {
	getInitialState() {
		return {
			on: false,
            nextList: false
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();
        
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.reset = this.reset.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);
        this.handleClick = this.handleClick.bind(this);

        // this.state.on = this.props.on;

        Hub.listen("ShowNext", this.onHubCapsule);
        Hub.listen("showNextBtn", this.onHubCapsule);
        Hub.listen("ShowNextButton", this.onHubCapsule);
        Hub.listen("HideNext", this.onHubCapsule);
        Hub.listen("HideNextButton", this.onHubCapsule);
        Hub.listen("hideNextBtn", this.onHubCapsule);
        Hub.listen("toggleNext", this.onHubCapsule);
        Hub.listen("showNextBtn", this.onHubCapsule);
        Hub.listen("showListBtn", this.onHubCapsule);

        window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    reset() {
        this.setState(this.getInitialState());
    }

    show() {
      this.setState({on: true});
    }


    hide() {
      this.setState({on: false});
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "ShowNext" || channel === "ShowNextButton" || channel === "showNextBtn") {
        	this.setState({on: true});
        }
        
        else if(channel === "showListBtn" || channel === "HideNext" || channel === "HideNextButton" || channel === "hideNextBtn") {
        	this.setState({on: false});
        }
    }

    handleClick(event) {
      event.preventDefault();
      this.setState({on: false});
      window.eventManager.emit("post_to_lex", "next");
    }


    render() {
        return (
        	<button className={this.state.on ? "btnSmallSierra btnNutrition" : "hidden"} onClick={this.handleClick} id="btnNext">Next</button>
        );
    }
}


