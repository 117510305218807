import React, {Component} from 'react';
import $ from 'jquery';
import moment from 'moment';
//import Snap from 'snapsvg';
import {Hub} from 'aws-amplify';

export default class SnoozeTimer extends Component {
    getInitialState() {
        return {on: false}
    }

    constructor(props) {
        super(props);
        this.state = this.getInitialState();

        this.show = this.show.bind(this);
        this.reset = this.reset.bind(this);
        this.hide = this.hide.bind(this);
       
        this.getInitialState = this.getInitialState.bind(this);

        Hub.listen("ShowSnooze", () => {
            this.show();
        });
        Hub.listen("snoozeDone", () => {
            this.hide();
        })

        Hub.listen("UIReset", () => {
            this.reset();
        });

        Hub.listen("StartTimer", () => {
              this.show();
            $(function() {
                new Countdown({minutes: 0, seconds: 10}, $(".c-container"));
                // this.setState({timer});
                //this.setState({yeah:yeah})
            });
        });

    }

    reset() {
        this.setState(this.getInitialState());
    }

    show() {
        this.setState({on: true});
    }

    hide() {
        this.setState({on: false});
    }

    //ignore this for now
    componentDidMount() {}

    //ignore this for now
    componentDidUpdate() {}

    render() {
        return (<div className={this.state.on
                ? "timerBox"
                : "hidden"}>
            <div className="c-container">
                <div className="c-text"></div>
                <svg id="yeah" viewBox="0 0 100 100">
                    <path id="bg" strokeLinecap="round" strokeWidth="4" stroke="#758299" fill="none" d="M50 2
                       a 48 48 0 0 1 0 96
                       a 48 48 0 0 1 0 -96"></path>
                    <path id="progress" strokeLinecap="round" strokeWidth="4" stroke="#4BCBF3" fill="none" d="M50 2
                       a 48 48 0 0 1 0 96
                       a 48 48 0 0 1 0 -96"></path>
                    <svg viewBox="-400 -200 509.287 509.287" style={{
                            enableBackground: "new 0 0 509.287 509.287"
                        }} xmlSpace="preserve" width="2vw" height="2vw" overflow="visible">
                        <g>
                            <circle style={{
                                    fill: "#0098C6"
                                }} cx="254.644" cy="254.644" r="254.644"/>
                            <circle style={{
                                    fill: "#FCFCFD"
                                }} cx="254.644" cy="254.644" r="208.53"/>
                            <g>
                                <path style={{
                                        fill: "#D2D6E8"
                                    }} d="M254.644,272.954L254.644,272.954c-10.172,0-18.31-8.138-18.31-18.31v-139.02   c0-10.172,8.138-18.31,18.31-18.31l0,0c10.172,0,18.31,8.138,18.31,18.31v139.02C272.954,264.816,264.816,272.954,254.644,272.954z   "/>
                                <path style={{
                                        fill: "#D2D6E8"
                                    }} d="M272.954,253.287v3.052c0,9.494-7.46,16.954-16.954,16.954H140.715   c-9.494,0-16.954-7.46-16.954-16.954v-3.052c0-9.494,7.46-16.954,16.954-16.954H256   C265.494,236.334,272.954,243.793,272.954,253.287z"/>
                            </g>
                            <g>
                                <rect x="246.506" y="56.625" style={{
                                        fill: "#758299"
                                    }} width="16.615" height="22.718"/>
                                <rect x="246.506" y="429.944" style={{
                                        fill: "#758299"
                                    }} width="16.615" height="22.718"/>
                                <rect x="429.944" y="246.506" style={{
                                        fill: "#758299"
                                    }} width="22.718" height="16.615"/>
                                <rect x="56.625" y="246.506" style={{
                                        fill: "#758299"
                                    }} width="22.718" height="16.615"/>
                                <circle style={{
                                        fill: "#758299"
                                    }} cx="254.644" cy="254.644" r="41.367"/>
                            </g>
                            <circle style={{
                                    fill: "#0098C6"
                                }} cx="254.644" cy="254.644" r="18.31"/>
                        </g>
                    </svg>

                </svg>
            </div>
        </div>);
    }
}

class Util {
    static convertMS(ms) {
        var d,
            h,
            m,
            s;

        s = Math.floor(ms / 1000);
        m = Math.floor(s / 60);
        s = s % 60;
        h = Math.floor(m / 60);
        m = m % 60;
        d = Math.floor(h / 24);
        h = h % 24;
        return {d: d, h: h, m: m, s: s};
    };

    static addZ(n) {
        if (!n) return "00";
        return ( n < 10 ? '0': '') + n;
    }

    static formatTime(obj) {
        if (obj.d > 0) {
            return Util.addZ(obj.d) + "D " + Util.addZ(obj.h) + "H";
        }
        if (obj.h > 0) {
            return Util.addZ(obj.h) + "H " + Util.addZ(obj.m) + "M";
        }
        return Util.addZ(obj.m) + "M " + Util.addZ(obj.s) + "S";
    }
}

class Countdown {
    constructor(endTime, $element) {
        this.now = moment();
        this.end = moment().add(endTime);
        this.diff = this.end.diff(this.now);
        this.$el = $element;
        this.svg = this.$el.find("svg")[0];//Snap(this.$el.find("svg")[0]);
        //this.progress = this.svg.select("#progress");
        this.$txt = this.$el.find(".c-text");
        this.initCircle();
        this.initTimer();
    }

    initCircle() {
        // var self = this;
        //self.progress.attr({strokeDasharray: '0, 301.44'});
        // Snap.animate(0, 301.44, function(value) {
        //     self.progress.attr({
        //         'stroke-dasharray': value + ', 301.44'
        //     });
        // }, self.diff);
    }

    initTimer() {
        var self = this;
        self.timer = setInterval(function() {
            self.now = moment();
            self.diff = self.end.diff(self.now);
            if (self.diff > 0) {
                self.$txt.text(Util.formatTime(Util.convertMS(self.diff)));
            } else {
                self.$txt.text("");
                clearInterval(self.timer);
                 window.eventManager.emit("snoozeDone");
            }
        }, 200);
    }
}
