import React, { Component } from 'react';
import { Hub } from 'aws-amplify'; // eslint-disable-line no-unused-vars

import { AddisonButton, HelpButton, /*GotoButton,*/ SnoozeButton, YesButton, NoButton, DoneButton, MicDotButton, SupportButton, /*NotYetButton,*/ ReadyButton,  /*SaveAllButton,*/} from '../components';

const ListeningButton = (props) => {
    const style = {
        position: "fixed",
        top: "2vw",
        left: "2vw",
        borderRadius: ".75vw",
        backgroundColor: "#00B64A",
        padding: "1vw",

        fontFamily: "Roboto Condensed",
        fontSize: "4vw",
        fontWeight: "bold",
        textAlign: "center",
        color: "#EEF0F6",
    }

    return props.on ? (
        <div style={style}>
            I'm Listening
        </div>
    ) : (null);
}

export default class AppButtons extends Component {
    getInitialState() {
        return {
            on: true,
            debug: true,
            saveAllButtonOn: false,
            snoozeButtonOn: false,
            yesButtonOn: false,
            noButtonOn: false,
            helpButtonOn: true,
            addisonButtonOn: true,
            micDotButtonOn: true,
            doneButtonOn: false,
            listeningButtonOn: false,
            supportButtonOn: true,
            readyButtonOn: false,
        }
    }

    constructor(props) {
        super(props);
        this.onHubCapsule = this.onHubCapsule.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.reset = this.reset.bind(this);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);

        this.state = this.getInitialState();

        Hub.listen("UIReset", (capsule) => this.setState(this.getInitialState()));

        //SaveAllButton.jsx
        Hub.listen("ShowSaveAllButton", this.onHubCapsule, "AppButtons");
        Hub.listen("HideSaveAllButton", this.onHubCapsule, "AppButtons");

        //BTN_Yes.jsx
        Hub.listen("ShowYes", this.onHubCapsule, "AppButtons");
        Hub.listen("HideYes", this.onHubCapsule, "AppButtons");
        Hub.listen("toggleYes", this.onHubCapsule, "AppButtons");
        Hub.listen("sYN", this.onHubCapsule, "AppButtons");
        Hub.listen("hYN", this.onHubCapsule, "AppButtons");
        Hub.listen("snooze", this.onHubCapsule, "AppButtons");
        Hub.listen("snooze_last", this.onHubCapsule, "AppButtons");
        Hub.listen("YesBtn", this.onHubCapsule, "AppButtons");
        Hub.listen("NoBtn", this.onHubCapsule, "AppButtons");
        Hub.listen("yes", this.onHubCapsule, "AppButtons");
        Hub.listen("no", this.onHubCapsule, "AppButtons");
        Hub.listen("not yet", this.onHubCapsule, "AppButtons");

        //BTN_No.jsx
        Hub.listen("ShowNo", this.onHubCapsule, "AppButtons");
        Hub.listen("HideNo", this.onHubCapsule, "AppButtons");
        Hub.listen("toggleNo", this.onHubCapsule, "AppButtons");
        Hub.listen("sYN", this.onHubCapsule, "AppButtons");
        Hub.listen("hYN", this.onHubCapsule, "AppButtons");
        Hub.listen("YesBtn", this.onHubCapsule, "AppButtons");
        Hub.listen("NoBtn", this.onHubCapsule, "AppButtons");
        Hub.listen("no", this.onHubCapsule, "AppButtons");
        Hub.listen("yes", this.onHubCapsule, "AppButtons");

        //BTN_Done.JSX
       Hub.listen("ShowReady", this.onHubCapsule, "AppButtons");
       Hub.listen("HideReady", this.onHubCapsule, "AppButtons");
       Hub.listen("readybtn", this.onHubCapsule, "AppButtons");
       Hub.listen("toggleReady", this.onHubCapsule, "AppButtons");
       Hub.listen("ListDone", this.onHubCapsule, "AppButtons");
    }

    componentDidMount() {}

    componentDidUpdate() {}

    show() {
        this.setState({ on: true });
    }

    hide() {
        this.setState({ on: false });
    }

    reset() {
        this.setState(this.getInitialState());
    }

    onHubCapsule(capsule) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "UIReset") {
            this.setState(this.getInitialState());
        }

        // else if(channel === "showFinished" || channel === "ShowFinished") {
        //     this.setState({FinishedButtonOn: true});
        // }
        
        // else if(channel === "VitalsDone" || "SceneReset" || channel === "finished" || channel === "hideFinished") {
        //     this.setState({FinishedButtonOn: false});
        // }
 
    }


    render() {
        return (
            <div className={this.state.on ? "" : "hidden"}>
            <AddisonButton on={this.state.addisonButtonOn} />
            <HelpButton on={this.state.helpButtonOn} />
            <SnoozeButton on={this.state.snoozeButtonOn} />
            <YesButton on={this.state.yesButtonOn} />
            <NoButton on={this.state.noButtonOn} />
            <DoneButton on={this.state.doneButtonOn} />
            <MicDotButton on={this.state.micDotButtonOn} />
            <ListeningButton on={this.state.listeningButtonOn} />
            <SupportButton on={this.state.supportButtonOn} />
            <ReadyButton on={this.state.readyButtonOn} />
            {/*<NotYetButton />*/}
          </div>
        )
    }
}
