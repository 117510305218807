import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
// import AmplifySceneLoader,{ sceneConfigs } from '../../AmplifySceneLoader';

export default class Btn_Stressed extends Component {
  getInitialState() {
    return {
      on: false
    }
  }

  constructor( props ) {
    super( props );
    this.state = this.getInitialState();
    this.handleClick = this.handleClick.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.reset = this.reset.bind(this);
    this.getInitialState = this.getInitialState.bind(this);
    this.onHubCapsule = this.onHubCapsule.bind(this);
    //this.state.on = this.props.on;

    Hub.listen("showStressed",this);
    Hub.listen("hideStressed",this);
    Hub.listen("UIReset",this); 

    // window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();
  }

  componentDidMount() {

  }

  componentDidUpdate() {

  }

  reset() {
      this.setState(this.getInitialState());
  }

    show() {
      this.setState({on: true});
  }

    hide() {
      this.setState({on: false});
  }

  onHubCapsule( capsule ) {
    const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

    if(channel === "showStressed") {
      this.setState({on: true});
    }

    else if(channel === "hideStressed") {
      this.setState({on: false});
    }
  }

  handleClick(event) {
      event.preventDefault();
      this.hide();
      window.eventManager.emit("BeginChatbot", {});
    }

  render() {
    return (
      <button className={this.state.on ? "btnWide" : "hidden"} onClick={this.handleClick} id="stressed">I'm Stressed</button>
    );
  }
}
