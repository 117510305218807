import React, { useState, useEffect } from 'react';

import './styles.scss';

/**
 * React component that displays the most current ball
 * as well as the draw again button
 * @param {Object} state
 * @param {function} onDrawClick - function called when draw again button clicked
 * @param {Object} moves
 * @returns {Component} CurrentBall
 */
const CurrentBall = ({ state, onDrawClick, moves }) => {
  
  // animation controller for draw again button
  let [buttonClasses, setButtonClasses] = useState(
    "btn-ecg btn-draw-again btn-draw-again__startup--animation"
  );

  let [buttonText, setButtonText] = useState('Play');

  useEffect(() => {
    (state.history.length > 5 && buttonText === 'Play') && setTimeout(() => setButtonText("Draw Again"), 2000);
  });

  return (
    <div className='current-ball__container'>
      <div className='current-ball__div'>
        <h1 className='current-ball__label'>Current Ball</h1>
        <div
          className={ state.currentLetter.length === 0 ? 'current-ball__ball--empty' : 'current-ball__ball'}
          style={{ "background-color": state.currentColor }}
        >
          { state.currentLetter.length === 0 ? state.currentletter : 
            <div className='current-ball__ball-inner1'>
              <div className='current-ball__ball-inner2' style={{ "background-color": state.currentColor }}>
                <div className='current-ball__ball-inner3'>
                  <p className='current-ball__ball-letter'>{state.currentLetter}</p>
                  <p className='current-ball__ball-number'>{state.currentNumber}</p>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <button
        className={buttonClasses}
        id="drawCombination"
        onClick={() => {

          // if-else acts as debounce for button
          if (
            buttonClasses === "btn-ecg btn-draw-again" ||
            buttonClasses ===
              "btn-ecg btn-draw-again btn-draw-again__startup--animation"
          ) {
            setButtonClasses(
              "btn-ecg btn-draw-again btn-draw-again__clicked--animation"
            );
            setTimeout(() => {
              setButtonClasses("btn-ecg btn-draw-again");
            }, 4000);

            return onDrawClick(moves);
          }
        }}
      >
      { buttonText }
      </button>
    </div>
  );
};

export default CurrentBall;