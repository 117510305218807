//AppGameContainer.jsx

import React from 'react';
import { Hub } from 'aws-amplify';
import { AppBingo, AppCheckers, AppSudoku } from '../components';
import autobind from 'class-autobind';


export default class AppGameContainer extends React.Component {
	getInitialState() {
		return {
			on: false,
			bingoOn: false,
			checkersOn: false,
			sudokuOn: false
		}
	}

	constructor(props) {
		super(props);

		autobind(this);

		this.state = this.getInitialState();

		Hub.listen("ToggleBingo", (data) => {
			this.setState({bingoOn: !(this.state.bingoOn)});
			Hub.dispatch("HideAppInitialSidebar");
		});

		Hub.listen("PlayBingo", (data) => {
			console.log("in AppGameContainer, heard PlayBingo channel");
			this.setState({on: true, bingoOn: true, checkersOn: false});
			Hub.dispatch("HideAppInitialSidebar");
		})

		Hub.listen("ToggleCheckers", (data) => {
			this.setState({on: true, bingoOn: false, checkersOn: true, sudokuOn: false})
		});

		Hub.listen("PlayCheckers", (data) => {
			this.setState({on: true, bingoOn: false, checkersOn: true, sudokuOn: false})
		});

		Hub.listen("playSudoku", (capsule) => {
			this.setState({on: true, bingoOn: false, checkersOn: false, sudokuOn: true})
		});

		Hub.listen("stopSudoku", (capsule) => {
			this.setState({on: true, bingoOn: false, checkersOn: false, sudokuOn: false})
		});
	}

	render() {
		return this.state.on ? (
		  <React.Fragment>
			{this.state.bingoOn && <AppBingo on={this.state.bingoOn} />}
			{this.state.checkersOn && <AppCheckers on={this.state.checkersOn} />}
			{this.state.sudokuOn && <AppSudoku on={this.state.sudokuOn} />}
		  </React.Fragment>
		) : (null);
	}
}