import React, {Component} from 'react';
import { Hub } from 'aws-amplify';
import autobind from 'class-autobind';

export default class AppVideoContainer extends Component {
	getInitialState() {
		return {
			on: false,
			stretchingVideoOn: false,
			breathingExerciseVideoOn: false,
			hulaHoopVideoOn: false,
			yogaVideoOn: false,
			medVideoOn: false,
			gaitInstructionVideoOn: false,
		};
	}

	constructor(props) {
		super(props);
		this.state = this.getInitialState();

		autobind(this);

		Hub.listen("PlayStretchingVideo", this.onHubCapsule);
		Hub.listen("stretchingDone", this.onHubCapsule);

		Hub.listen("playYogaVideo", this.onHubCapsule);
		Hub.listen("yogaDone", this.onHubCapsule);

		Hub.listen("playHulaHoopVideo", this.onHubCapsule);
		Hub.listen("HulaHoopDone", this.onHubCapsule);

		Hub.listen("PlayBreathingExercise", this.onHubCapsule);
		Hub.listen("breathingExerciseDone", this.onHubCapsule);
		Hub.listen("ShowFocusBreathingVideo", this.onHubCapsule);
		Hub.listen("HideFocusBreathingVideo", this.onHubCapsule);

		Hub.listen("StartInstructions", this.onHubCapsule);
		Hub.listen("StopInstructions", this.onHubCapsule);

		Hub.listen("StartGaitInstructions", this.onHubCapsule);
		Hub.listen("strideSlot", this.onHubCapsule);
		Hub.listen("placeholderSlot", this.onHubCapsule);
		Hub.listen("cadenceSlot", this.onHubCapsule);
		Hub.listen("swingTimeSlot", this.onHubCapsule);


		Hub.listen("UIReset", this.onHubCapsule);

	}

	componentDidMount() {

	}

	componentDidUpdate() {

	}

	onHubCapsule(capsule) {
		const { channel, payload } = capsule; // eslint-disable-line no-unused-vars
		console.log(`event ${channel} with payload ${JSON.stringify(payload, null, 2)} detected, dispatching from Hub now...`);


		if(channel === "UIReset") {
			this.setState(this.getInitialState());
		}

		else if(channel === "StartInstructions") {
			this.setState({gaitInstructionVideoOn: true, on:true});
			let elem = this.refs.gaitInstructionVideoSrc;
			elem.src = "https://s3.us-east-2.amazonaws.com/addison-kioskgatevideos-v01/KioskDiagramTwitterExp.mp4";
		}

		else if(channel === "PlayStretchingVideo") {
			this.setState({stretchingVideoOn: true, on:true});
			let elem = this.refs.stretchingVideoSrc;
			elem.src = "https://s3.amazonaws.com/addison-project-anim-files/SumerianVideoFiles/Addison_Yoga_ChairShoulderStretch_v01.mp4"
		}
		else if (channel === "stretchingDone") {
			this.setState({stretchingVideoOn: false, on:false});
		}
		else if(channel === "PlayBreathingExercise") {
			this.setState({breathingExerciseVideoOn: true, on:true});
			let elem = this.refs.breathingExerciseVideoSrc;
			elem.src = "https://s3.amazonaws.com/addison-project-anim-files/SumerianVideoFiles/Addison_BreathEx_Sitting_v01.mp4"
		}
		else if(channel === "breathingExerciseDone") {
			this.setState({breathingExerciseVideoOn: false, on:false});
		}
		else if(channel === "playYogaVideo") {
			this.setState({yogaVideoOn: true, on:true});
			let elem = this.refs.yogaVideoSrc;
			elem.src = "https://s3.amazonaws.com/addison-project-anim-files/SumerianVideoFiles/Addison_Yoga_Warriors_v01.mp4";
		}
		else if(channel === "yogaDone") {
			this.setState({yogaVideoOn: false, on:false});
		}
		else if(channel === "playHulaHoopVideo") {
			this.setState({hulaHoopVideoOn: true, on:true});
			let elem = document.getElementById('hulaHoopVideoSrc');
			elem.src = "https://s3.amazonaws.com/addison-project-anim-files/SumerianVideoFiles/Addison_Hoops_SimpleHowTo-Long_v01.mp4"
		}
		else if(channel === "HulaHoopDone") {
			this.setState({hulaHoopVideoOn: false, on:false});
		}
		else if(channel === "ShowFocusBreathingVideo") {
			this.setState({medVideoOn: true, on:true});
			let elem = this.refs.medVideoSrc;
			elem.src = "https://s3.amazonaws.com/addison-project-anim-files/SumerianVideoFiles/Addison_GuidedMeditation_v01.mp4"
		}
		else if(channel === "HideFocusBreathingVideo") {
			this.setState({medVideoOn: false, on:false});
		} 
		else if(channel === "startGaitInstructions"){
			this.setState({medVideoOn: true, on:true});
			let elem = this.refs.gaitInstructionsVideoSrc;
			elem.src = "https://s3.us-east-2.amazonaws.com/addison-kioskgatevideos-v01/KioskDiagramTwitterExp.mp4"
		}
		else if(channel === "strideSlot"){
			this.setState({medVideoOn: true, on:true});
			let elem = this.refs.cadenceVideoSrc;
			elem.src = "https://s3.us-east-2.amazonaws.com/addison-kioskgatevideos-v01/AddisonCadenceRender_v01.mp4"
		}
		else if(channel === "cadenceSlot"){
			this.setState({medVideoOn: true, on:true});
			let elem = this.refs.velocityVideoSrc;
			elem.src = "https://s3.us-east-2.amazonaws.com/addison-kioskgatevideos-v01/AddisonVelocityRender_v01.mp4"
		}
		else if(channel === "swingTimeSlot"){
			this.setState({medVideoOn: true, on:true});
			let elem = this.refs.strideLengthVideoSrc;
			elem.src = "tps://s3.us-east-2.amazonaws.com/addison-kioskgatevideos-v01/AddisonStrideLengthRender_v01.mp4"
		}
		else if(channel === "placeholderSlot"){ 
			this.setState({medVideoOn: true, on:true});
			let elem = this.refs.swingTimeVideoSrc;
			elem.src = "https://s3.us-east-2.amazonaws.com/addison-kioskgatevideos-v01/AddisonSwingTimeRender_v01.mp4"
		}
	}

	injectVideoSource() {

	}

	handleMedVideoEnded(event) {
      event.preventDefault();
      this.setState({on:false, medVideoOn:false})
      window.amplifySceneLoader.emit("FeelingBetterOne", {});
    }

	handleYogaDone(event){
		event.preventDefault();
		this.setState({on:false, yogaVideoOn:false})
		window.amplifySceneLoader.emit("yogaDone", {});
	}

	handleHulaHoopDone(event){
		event.preventDefault();
		this.setState({on:false, hulaHoopVideoOn:false})
		window.amplifySceneLoader.emit("hulaHoopDone", {});
	}



	// stretchingVideo				Vitals - Heart Disease
	// breathingExerciseVideo		Vitals - Asthma
	// hulaHoopVideo				Meds - Diabetes
	// yogaVideo					Meds - Asthma
	render() {
		return this.state.on ? (
 		<div>
			<video className={this.state.yogaVideoOn ? "" : "hidden"} onEnded={this.handleYogaDone} width="1920" height="1080" controls id="yogaVideo" allow="autoplay; encrypted-media" allowFullScreen autoPlay>
			  <source src="" type="video/mp4" id='yogaVideoSrc' ref='yogaVideoSrc'></source>
			</video>

			<video className={this.state.breathingExerciseVideoOn ? "" : "hidden"} width="1920" height="1080" controls id="breathingExerciseVideo" allow="autoplay; encrypted-media" allowFullScreen autoPlay>
			  <source src="" type="video/mp4" id='breathingExerciseVideoSrc' ref='breathingExerciseVideoSrc'></source>
			</video>

			<video className={this.state.hulaHoopVideoOn ? "" : "hidden"}  onEnded={this.handleHulaHoopDone} width="1920" height="1080" controls id="hulaHoopVideo" allow="autoplay; encrypted-media" allowFullScreen autoPlay>
			  <source src="" type="video/mp4" id='hulaHoopVideoSrc' ref='hulaHoopVideoSrc'></source>
			</video>

			<video className={this.state.stretchingVideoOn ? "" : "hidden"} width="1920" height="1080" controls id="stretchingVideo" allow="autoplay; encrypted-media" allowFullScreen autoPlay>
			  <source src="" type="video/mp4" id='stretchingVideoSrc' ref='stretchingVideoSrc'></source>
			</video>

			<video className={this.state.medVideoOn ? "" : "hidden"} onEnded={this.handleMedVideoEnded} id="medVideo" allow="autoplay; encrypted-media"  autoPlay>
				<source src="" type="video/mp4" id='medVideoSrc' ref='medVideoSrc'></source>
			</video>

			<video className={this.state.gaitInstructionVideoOn ? "" : "hidden"} onEnded={() => { console.log("gait instruction video ended");}}>
				<source src="" type="video/mp4" id='gaitInstructionsVideoSrc' ref='gaitInstructionsVideoSrc'></source>
			</video>

			<video className={this.state.gaitSwingTimeVideoOn ? "" : "hidden"} onEnded={() => { console.log("gait instruction video ended");}}>
				<source src="" type="video/mp4" id='swingTimeVideoSrc' ref='swingTimeVideoSrc'></source>
			</video>

			<video className={this.state.gaitStrideLengthVideoOn ? "" : "hidden"} onEnded={() => { console.log("gait instruction video ended");}}>
				<source src="" type="video/mp4" id='strideLengthVideoSrc' ref='strideLengthVideoSrc'></source>
			</video>

			<video className={this.state.gaitVelocityVideoOn ? "" : "hidden"} onEnded={() => { console.log("gait instruction video ended");}}>
				<source src="" type="video/mp4" id='velocityVideoSrc' ref='velocityVideoSrc'></source>
			</video>

			<video className={this.state.gaitCadenceVideoOn ? "" : "hidden"} onEnded={() => { console.log("gait instruction video ended");}}>
				<source src="" type="video/mp4" id='cadenceVideoSrc' ref='cadenceVideoSrc'></source>
			</video>
		</div>

		) : (<div></div>);
	}
}
