import React from "react";

import '../styles.scss'

const  ConnectionInstructions = () => {

return (
    <div className='instruction'>
            If your internet is connected the internet connection button will display "Connected".
            If your Internet is not connected, The internet connection button will display "Not Connected" 
    </div>
  )
}

export default ConnectionInstructions; 