//AppRewardsSideBar.jsx

import React from 'react';
import { Hub } from 'aws-amplify';
  
export default class AppRewardsSidebar extends React.Component {
    containerStyle = {
        top: "10vh",
        left: "80vw",
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        justifyContent: "spaceBetween",
    };

    promptStyle = {
        fontSize: "1.5vw",
        color: "#d2d6e8",
        fontWeight: "bold",
        borderRadius: ".75vw",
        padding: "1vw",
        border: "0vw",
        alignItems: "center",
        textAlign: "center",
        width: "20vw",
        background: "#0B2545",
        margin: "0vw",
        position: "absolute",
        top: "25vh",
        left: "50vw",
    };

    buttonStyle = {
        width: "18vw",
        height: "25vh",
        margin: ".5vw .5vh",
    }

    constructor(props) {
        super(props);

        this.handleTBDClick = this.handleTBDClick.bind(this);
        this.handleDone = this.handleDone.bind(this);
    }

    handleTBDClick(event) {
        event.preventDefault();
        console.log("TBD clicked")
    }

    handleDone(event) {
        event.preventDefault();
        console.log("clicked Addison Button, reloading page...");
        Hub.dispatch("UIReset");
        // window.location.reload();
    }

    // handleTBDClick(event) {

    // }

    // handleTBDClick(event) {

    // }

    render() {
        return this.props.on ? (
            <div>
                <div className="appSidebarContainer" style={this.containerStyle}>
                    <button style={this.buttonStyle} className="btnTemplate" onClick={this.handleTBDClick} > TBD </button>
                    <button style={this.buttonStyle} className="btnTemplate"  onClick={this.handleTBDClick}> TBD </button>
                    <button style={this.buttonStyle} className="btnDone" onClick={this.handleDone} > Done </button>
                </div>
                <div style={this.promptStyle}>
                    Start by saying: "Addison"<br/><br/>
                    Wait until you see the green <br/>"I'm Listening" icon. <br/>
                </div>
            </div>
        ) : (null)
    }
}