import { Logger, Hub } from 'aws-amplify';
import { Bacon } from "baconjs"; // eslint-disable-line no-unused-vars
import $ from 'jquery'; // eslint-disable-line no-unused-vars
import autobind from 'class-autobind';

import { sceneConfigs } from './AmplifySceneLoader';

const logger = new Logger('EventManager');

export default class EventManager {
    constructor() {
        this.state = {
            sceneConfigs: sceneConfigs,
            currentScene: sceneConfigs.introScene,
            loaded: false,
            sceneController: null
        }

        autobind(this);
    }

    destroy() {
        this.sumerianEmits.forEach(channel => {
            Hub.remove(channel);
        });

        const customEmits = ["Diabetes_Coping", "Diabetes_Vitals", "Diabetes_Meds", "Diabetes_Gait", "Diabetes_Nutrition", "CS_Scene", "ActivityMonitoring"];

        customEmits.forEach(channel => {
            Hub.remove(channel);
        });

        this.state = {};
        this.sceneController = null;
    }
 
    /*** list of emits from Sumerian that need to be re-broadcast as Hub dispatches
     *
     * https://docs.google.com/spreadsheets/d/19V55p9QOYEGMXZtGLJlMYJ4qBSUauDJEXHqqYrRVcHM/
     *
     **/
    sumerianEmits = [
        "ReloadAddison",

        "showAppInitialSidebar",
        "hideAppInitialSidebar",

        "hideAppSplashScreen",
        "showAppSplashScreen",

        "displayVitalsHistory",
        "vitalsDataReceived",
        "successfulSetup",

        //charts
        "showAppOxygenChart",
        "showAppWeightChart",
        "showAppTemperatureChart",
        "showAppGlucoseChart",
        "showAppHeartRateChart",
        "showAppBloodPressureChart",

        //buttons
        "showYesNoBtn",
        "hideYesNoBtn",
        "HideYes",
        "sYN",
        "hYN",
        "HideNo",
        "ShowYes",
        "ShowNo",
        "YesBtn", //Progresses the scene down the Yes path.  Hides Yes and NotYet Btn
        "NoBtn",
        "ShowNotYet",
        "HideSnooze",
        "snooze", //Progresses the scene down the No path.  Hides Yes and NotYet Btn
        "helpbtn", //Pulls up the HTML_Help_Tab
        "closeHelp", //Closes the HTML_Help_Tab
        "btndone", //Progresses the scene to the GreatJob section.  Hides Done Btn
        "RRBtn",
        "CSBtn",
        "AddisonBtn",
        "readybtn",
        "ShowReady",
        "ready",
        "ReadyBtn",
        "yes",
        "no",
        "not yet",
        "finished",
        "loadHelpScreen",

        "ListDone",
        "ShowFoundIt",
        "ShowFoundItBtn",
        "nextMedUI",
        "MissedMedList", //Displays nextMed screen
        "showHulaHoop", //Display the HulaHoop Video
        "hulaHoopDone",
        "UpdateMedList",
        "LookUser",
        "GreatJob",
        "StartTimer",
        "ActiveAlarm",
        "LookUI",
        "HD_StopSmoking_TextChange",
        "Diabetes_StopSmoking_TextChange",
        "nextMedUI", // {medName:"Advil",medTime: "8:00 am",medDate: "Tomorrow"}
        "CountDownTimer", //{seconds: 10, minutes: 00}
        "UpdateMedList", // {<someData>}
        "PulseOxTutorial",
        "BloodPressureTutorial",
        "TempTutorial",


        "GlucoseValue",
        "GlucoseTutorial",
        "GlucoseTutorial1",
        "GlucoseTutorial2",
        "GlucoseTaken",
        "WeightTaken",
        "PulseOxTaken",
        "BloodPressureTaken",
        "TempTaken",

        "PulseOxTutorial",
        "PulseOxTutorial2",
        "BloodPressureTutorial",
        "BloodPressureTutorial1",
        "BloodPressureTutorial2",
        "WeightTutorial",
        "TempTutorial1",
        "TempTutorial2",

        "TempIntro",
        "BloodPressureIntro",
        "WeightIntro",
        "GlucoseIntro",

        "PlayStretchingVideo",
        "stretchingDone",

        //diagrams
        "showBloodPressureDiagram",
        "showHRSummaryDiagram",
        "showOxySummaryDiagram",
        "showTemperatureDiagram",
        "showWeightDiagram",
        "hideHRDiagram",
        "Buttons", //not sure what this does, in DisplayWeight intent, actually think it shows SummaryDiagram.jsx


        "StartInstructions", //instructions for gait scene
        "GaitDone",

        //asthma
        "PulseOxTutorial",
        "showYoga",
        "showAlertTab",
        "playYogaVideo",

        //nutrition
        "showPlatePro",
        "hidePlatePro",
        "showMeals1",
        "hideMeals1",
        "showMeals2",
        "hideMeals2",
        "showList1",
        "showList2",
        "hideList1",
        "hideList2",
        "ShowIdeas",
        "HideIdeas",
        "ShowIdeasButton",
        "HideIdeasButton",
        "showIdeasBtn",
        "hideIdeasBtn",
        "showNextIdeaBtn",
        "hideNextIdeaBtn",
        "HideNextIdeaBtn",
        "ShowNextIdeaButton",
        "showNextIdeasBtn",
        "hideNextIdeasBtn",
        "showListBtn",
        "hideListBtn",
        "showNextBtn",
        "ShowNext",
        "HideNext",
        "NutritionDone",
        "HideReady",
        "ready",

        "PopulateMedTable",
        "ListDone",
        "hideNextBtn",


        //med heart disease
        "MissedMedList",
        "showReadyAddison",
        "ReadyAddison",
        "SceneReset",
        "GreatJob",
        "ActiveAlarm",
        "DoneVisibility",
        "MedIntro",
        "showStopSmoking1",
        "showStopSmoking2",

        //coping
        "PlayBreathingExercise",
        "sBetter",
        "hBetter",

        "breathingExercise",
        "breathingExerciseDone",
        "showStressed",
        "hideStressed",
        "ShowFeelingBetterOne",
        "HideFeelingBetterOne",
        "ShowFocusBreathingVideo",
        "HideFocusBreathingVideo",
        //"BeginChatbot",

        "AneltoReceived",
        "VitalsTaken",
        "VitalsDone",
        "showFinished",
        "CloseAddisonBtn",
        "btndone",
        "snooze_last",
        "snooze_2ndLast",
        "snooze1",
        "snoozeDone",
        "YesNoGoAway",
        "TabletLook",
        "playHulaHoopVideo",
        "PollenCountReceived",
        "showPollenCount",
        "NextMedList",
        "sLeaf",
        "sLife",
        "hLife",
        "sPhoto",
        "sCook",
        "hCook",
        "hLeaf",
        "hLeaf",
        "hPhoto",

        "showNutritionFinished",
        "ShowLoadingBackground",
        "HideLoadingBackground",
        "hideSplashScreen",
        "ShowSceneSelectMenu",
        "HideSceneSelectMenu",

        //CFRSI
        "StartInstructions",
        "StopInstructions",
        "TermsShow",
        "talkingTime",
        "AddyOut",
        "Begin",
        "PagesHide",
        "PagesShow",
        "TermsShow",
        "TermsHide",

        //general
        "UIReset",
        "PlayingNewScene",

        //bingo

        //misc
        "PlayCalibration",
        "ToggleCalibration"
    ];


    initSceneSwitchCallbacks() {
        this.sceneController.sumerian.SystemBus.addListener("Diabetes_Coping", (data) => {
            logger.debug("Diabetes_Coping event detected");
            this.dispatchPlayCopingScene();
        });

        this.sceneController.sumerian.SystemBus.addListener("Diabetes_Vitals", (data) => {
            logger.debug("Diabetes_Vitals event detected");
            this.dispatchPlayDiabetesVitals();
        });

        this.sceneController.sumerian.SystemBus.addListener("Diabetes_Meds", (data) => {
            logger.debug("Diabetes_Meds event detected");
            this.dispatchPlayDiabetesMedReminder();
        });

        this.sceneController.sumerian.SystemBus.addListener("Diabetes_Gait", (data) => {
            logger.debug("Diabetes_Gait event detected");
            this.dispatchPlayGaitDiabetesScene();
        });

        this.sceneController.sumerian.SystemBus.addListener("Diabetes_Nutrition", (data) => {
            logger.debug("Diabetes_Nutrition event detected");
            this.dispatchPlayNutritionScene();
        });

        this.sceneController.sumerian.SystemBus.addListener("CS_Scene", (data) => {
            logger.debug("CS_Scene event detected; no scene to dispatch yet");
            Hub.dispatch("CS_Scene");
        });

        this.sceneController.sumerian.SystemBus.addListener("ActivityMonitoring", (data) => {
            logger.debug("ActivityMonitoring event detected");
            this.dispatchPlayActivityMonitoringScene();
        });
    }

    getSceneController() {
        return this.sceneController;
    }

    /**
     * [putSceneController description]
     * @param  {[type]} sceneController [description]
     * @return {[type]}                 [description]
     */
    putSceneController(sceneController) {
        logger.debug("has sceneController reference")
        this.state.sceneController = sceneController;
        this.sceneController = sceneController;
    }

    /**
     * { function_description }
     *
     * @param      {<type>}  channel  The channel
     * @param      {<type>}  data     The data
     */
    emit(channel, data) {
        logger.debug(`emitting ${channel} with payload of ${JSON.stringify(data, null, 2)}`)
        this.sceneController.sumerian.SystemBus.emit(channel, data);
    }

    /**
     * Adds a listener.
     *
     * @param      {<type>}    channel   The channel
     * @param      {Function}  callback  The callback
     */
    addListener(channel, callback) {
        logger.debug(`event ${channel} added as listener to SystemBus with callback of ${JSON.stringify(callback)}...`);
        this.sceneController.sumerian.SystemBus.addListener(channel, callback);
    }

    /**
     * { function_description }
     *
     * @param      {<type>}  channel  The channel
     * @param      {<type>}  payload  The payload
     */
    sumerianListenOnHub(channel, payload) {
        if (!this.sumerianEmits.hasOwnProperty(channel)) {
            this.sumerianEmits.push(channel);

            let data = payload || {};

            this.addListener(channel, () => Hub.dispatch(channel, data));
        }
    }


    /**
     * { function_description }
     */
    initHubDispatchFromSumerianEmit() {
        this.sumerianEmits.forEach((event) => {
            this.sceneController.sumerian.SystemBus.addListener(event, (data) => {
                logger.debug(`event ${event} with payload ${JSON.stringify(data, null, 2)} detected from Sumerian, dispatching from Hub now...`);
                Hub.dispatch(event, data);
            });
        });
    }
} //end EventManager