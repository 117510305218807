/**
 * @fileOverview functions which allow the scheduling and fetching of med reminders
 *
 * created: 04/04/19
 *
 * */

import { Auth } from 'aws-amplify';
import Lambda from 'aws-sdk/clients/lambda';
import moment from 'moment';

////////////////////////////////////////////////////////////////////////////////////////////////////
//                                           Helpers                                              //
////////////////////////////////////////////////////////////////////////////////////////////////////


// Queries endpoint for medications that need to be scheduled past the current time.
async function getReminders(userID , callback) {
  const creds = await Auth.currentCredentials();

  const lambda = new Lambda({
    region: 'us-east-1',
    credentials: Auth.essentialCredentials(creds),
  });

  const Payload = JSON.stringify({ userID, time: moment().format('HH:mm:00') });
  const lambdaParams = {
    FunctionName: 'arn:aws:lambda:us-east-1:479226928101:function:fetchMedReminders',
    InvocationType: 'RequestResponse',
    Payload,
  };

  lambda.invoke(lambdaParams, (err, data) => {
    if (err) { console.log(err); return err; }
    callback(null, JSON.parse(data.Payload));
  });
}

// Creates the timeout for the reminders passed in as an array of objects.
function scheduleReminders(reminders) {
  for (let i = reminders.length - 1; i >= 0; i -= 1) {
    const scheduleTime = moment(reminders[i].Time, 'HH:mm:ss');
    const timeout = scheduleTime.diff(moment());
    console.log(`${reminders[i].Time}`);
    setTimeout(() => {
      window.amplifySceneLoader.dispatchPlayMedScene(reminders[i]);
    }, timeout);
  }
}

// schedules to run MedicationSchedulingFlow() at midnight.
function scheduleMidnightFetch() {
  const midnight = moment('24:00:00', 'HH:mm:ss');
  const midnightTimer = midnight.diff(moment());
  setTimeout(() => {
    MedicationSchedulingFlow();
  }, midnightTimer);
}

////////////////////////////////////////////////////////////////////////////////////////////////////
//                                        Main/Exported                                           //
////////////////////////////////////////////////////////////////////////////////////////////////////


/**
 * Fetches reminders past the current time and creates timers for the reminders returned.
 * @param {String} userID account which to fetch data from
 */
const MedicationSchedulingFlow = (userID) => {
  getReminders(userID, function(err, data){
    console.log(`scheduling ${data.length} reminders`);
    if(data.length > 0 ) { scheduleReminders(data); }
  });
  scheduleMidnightFetch();
};

export default MedicationSchedulingFlow;
