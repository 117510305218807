import React, { Component } from 'react';
import Amplify, { Auth, Hub, Analytics, Logger } from 'aws-amplify';
import AWSAppSyncClient, { createAppSyncLink, createLinkWithCache } from 'aws-appsync'; // eslint-disable-line no-unused-vars
import autobind from 'class-autobind';
import { AppScene /*, AppMedicationManagementContainer*/ } from './containers';
import AppUserInterface from './AppUserInterface';
import AmplifySceneLoader, { sceneConfigs } from './AmplifySceneLoader';
import EventManager from './EventManager';
import AppSyncManager from './AppSyncManager';

import * as queries from './graphql/queries'; // eslint-disable-line no-unused-vars
import * as mutations from './graphql/mutations'; // eslint-disable-line no-unused-vars
import * as subscriptions from './graphql/subscriptions'; // eslint-disable-line no-unused-vars
import MedicationSchedulingFlow from './MedReminderManager';
import awsmobile from './aws-exports.js';
import autoCreds, {amplifyConfig} from './CredentialsModule';

const logger = new Logger('AppJS');
const sumerianLogger = new Logger('[Window] Sumerian');

class App extends Component {
    getInitialState() {
        return {
            on: true,
            counter: 0 //for testing web workers
        };
    }

    constructor(props) {
        super(props);
        Amplify.configure(amplifyConfig);
        autobind(this);
        this.state = {
            on: true,
            counter: 0 //for testing web workers
        };

        Analytics.configure({
            userId: this.state.userName
            // autoSessionRecord: false
        });


        this.eventManager = new EventManager();
        this.amplifySceneLoader = new AmplifySceneLoader();
        this.appSyncManager = new AppSyncManager();

        window.eventManager = this.eventManager; // make the EventManager global so that any script that needs it can access it
        window.amplifySceneLoader = this.amplifySceneLoader;
        window.appSyncManager = this.appSyncManager;
        window.sumerianLogger = sumerianLogger;

        // this.state.testWorker = new testWorker();
        // testWorker.postMessage(this.state.counter);

        Hub.listen("ReloadAddison", (capsule) => {
            logger.debug("ReloadAddison capsule: ", capsule);
            setTimeout(() => {
                window.location.reload(true);
            }, 60000); //reload page after 60 seconds
        });
    }

    shouldComponentUpdate() {
        return false; //App should always be on, 
    }

    //check the isDeveloper flag and modify the render prop 
    async componentDidMount() {
        await autoCreds.deploy("app")
            .then(async (amplifyConfig) => {
                logger.debug("autoCreds deployed");
                const user = (window.appSyncUser || await window.appSyncManager.getUser());
                this.setState({user});
            })
            .catch (err => {
                logger.error("autoCreds deployment threw an error: ", err);
            });
    }

    componentWillUnmount() {
        const listeners = ["ReloadAddison"]

        listeners.forEach(listener => Hub.remove(listener));

        window.amplifySceneLoader.cleanupListeners();

        window.sumerianCtx = null;
        window.introCtx = null;
        window._introCtx = null;
        window.addisonCtx = null;

        window.addisonAssets = null;
        window.introAssets = null;

        window.eventManager.destroy();

        delete window.eventManager; // make the EventManager global so that any script that needs it can access it
        delete window.amplifySceneLoader;
        delete window.appSyncManager;
        delete window.sumerianLogger;
    }

    async sceneLoaded(sceneController) {
        const _user = (window.appSyncUser || await window.appSyncManager.getUser());
        this.setState({ user: _user });

        Hub.dispatch("showAppInitialSidebar");

        window.amplifySceneLoader.putSceneController(sceneController);
        window.eventManager.putSceneController(sceneController);

        setTimeout(() => {
            window.sumerianCtx.world.entityManager._entitiesById.forEach(async (entity) => await entity.show());
        }, 500);

        if (this.state.user.firstRun !== null && this.state.user.firstRun) {
            setTimeout(() => window.eventManager.emit("firstRun"), 2000);
        } else {
            Hub.dispatch("notFirstRun");
            setTimeout(() => window.eventManager.emit("notFirstRun"), 2000);
        }
        window.amplifySceneLoader.putSceneController(sceneController);
        window.eventManager.putSceneController(sceneController);

        if (this.state.user){ 
            if(this.state.user.firstRun !== null && this.state.user.firstRun) {
                setTimeout(() => window.eventManager.emit("firstRun"), 2000);
            } else {
                Hub.dispatch("notFirstRun");
                setTimeout(() => window.eventManager.emit("notFirstRun"), 2000);
            }
        }else Hub.dispatch("noUser");

        this.setState({
            loading: false,
            sceneController,
        });

        this.setState({ userName: (await Auth.currentSession()).getIdToken().payload['cognito:username'] });

        // TLS loaded, record that this user has engaged with the TLS

        const event = 
            {            
                name: 'tlsLoaded',
                attributes: {
                    name: this.state.userName,
                },
            };

        //Analytics.configure(awsmobile);


        Analytics.record(event);

        window.amplifySceneLoader.generateCallbacks();
        window.amplifySceneLoader.initListeners();

        const user = await Auth.currentSession();
        sceneController.sumerian.SystemBus.emit('TokensGenerated', user);

        // add listeners for all emits from Sumerian in order to rebroadcast them as Hub events
        this.eventManager.initHubDispatchFromSumerianEmit();
        this.eventManager.initSceneSwitchCallbacks();

        logger.debug(`sceneController: ${sceneController}`);
        Hub.dispatch('IntroSceneLoaded');

        // setTimeout(() => Hub.dispatch("IntroSceneLoaded"), 1500); //wait a couple of seconds to let addison pop in gracefully

        // fetches and creates schedules for med reminders.
        MedicationSchedulingFlow(this.state.userName);
    }

    render() {
        return (
            <div id="App" ref="App" className="App">
            <AppUserInterface />
            {/*<AppMedicationManagementContainer />*/}
            <AppScene sceneConfig={sceneConfigs.introScene} play load onLoaded={controller => this.sceneLoaded(controller)} />
            </div>
        );
    }
}



export default App;