import { Cache, Auth, Logger, API, graphqlOperation } from 'aws-amplify';
import EventManager from './EventManager';
import autobind from 'class-autobind';
import moment from 'moment';

import * as queries from './graphql/queries'; // eslint-disable-line no-unused-vars
import * as mutations from './graphql/mutations'; // eslint-disable-line no-unused-vars
import * as subscriptions from './graphql/subscriptions'; // eslint-disable-line no-unused-vars

import awsmobile from './aws-exports.js';

const logger = new Logger('AppSyncManager');

export default class AppSyncManager {
    constructor() {
        autobind(this);

        logger.debug("[AppSyncManager] in constructor!");

        API.configure(awsmobile);

        this.initState();

        this.eventManager = window.eventManager || new EventManager();
    }

    async initState() {
        this.state = {};

        this.state.user = await this.getUser();

        //expire once a day
        const expires = moment().endOf('day').fromNow(); 

        const options = {
        	expires,
        }

        Cache.setItem("user", this.state.user, options);
    }



    async setFirstRun() {
        const token = await Auth.currentSession();
        const id = token.idToken.payload["cognito:username"];

        const query = `
            mutation SetFirstRun {
                updateUser(input: {
                    id: "${id}",
                    localOwner: "${id}",
                    firstRun: false
                }) {
                    id
                    firstRun
                }
            }
        `;

        try {
            const results = await API.graphql(graphqlOperation(query, { input: { id } }));
            console.log("results of setFirstRun: " , results);
            return results;
        } catch(e) {
            logger.warn("error setting first run");
            console.log("error: ", e);
            return null;
        }
    }

    async getUser() {
        const query = queries.getUser;
        const token = await Auth.currentSession();
        const id = token.idToken.payload["cognito:username"];

        // const cachedUser = Cache.getItem('user');

        // if(cachedUser) return cachedUser;

        logger.debug("token: ", token);

        try {
            const results = await API.graphql(graphqlOperation(query, { id }));
	        logger.debug("[AppSyncManager] results of getUser: ", results.data.getUser);

	        window.appSyncUser = results.data.getUser;
	        this.state.user = window.appSyncUser;

	        Cache.setItem('user', this.state.user);


	        return results.data.getUser;
        } catch (e) {
            logger.warn("[AppSyncManager] error running queries.getUser! e: ", e);
            return null;
        }
    }

    async isDeveloper() {
        if(!(this.state.user)) 
            if (this.state.user = await this.getUser()){
                if((this.state.user.isDeveloper === null) 
                  || (this.state.user.isDeveloper === undefined)) 
                    logger.warn("[AppSyncManager] isDeveloper is null or undefined");
            }else {
                logger.warn("[AppSyncManager] User item for current cognito user is null or undefined");
                this.state.user = {isDeveloper:true, firstRun:false};
                //this.setState ({user: {isDeveloper:true, firstRun:false}});
            }

        logger.debug(`[AppSyncManager] isDeveloper: ${this.state.user.isDeveloper === true} `)

        return (this.state.user.isDeveloper === true);
    }

    async isFirstRun() {
        if(!(this.state.user)) this.state.user = await this.getUser();

        if((this.state.user.firstRun === null) || (this.state.user.firstRun === undefined)) 
            logger.warn("[AppSyncManager] firstRun is null or undefined");

        return (this.state.user.firstRun === true);

    }
}
