import React from 'react';
import { XR, Auth } from 'aws-amplify';
import * as AWS from 'aws-sdk/global'; // eslint-disable-line no-unused-vars
import Iot from 'aws-sdk/clients/iot';
import IotData from 'aws-sdk/clients/iotdata';
import Polly from 'aws-sdk/clients/polly';
import LexRuntime from 'aws-sdk/clients/lexruntime';
import Lambda from 'aws-sdk/clients/lambda';

//var autoCreds = require ('../CredentialsModule');
import autoCreds from '../CredentialsModule';

export default class AppSceneInterface extends React.PureComponent {
  getInitialState() {
    return {
      loaded: false,
      load: false,
      play: false
    };
  }

  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.onHubCapsule = this.onHubCapsule.bind(this);
  } //end ctor  

  componentDidMount() {
        Auth.currentUserPoolUser().then (
            cogUser => { 
                var session = cogUser.getSignInUserSession(); // eslint-disable-line no-unused-vars
                new Lambda({credentials: autoCreds});
                
                new Iot(
                    {
                        credentials: autoCreds
                    }
                );
                new IotData(
                    { 
                        endpoint: 'my.host.tld', 
                        credentials: autoCreds
                    }
                );
                new Polly(
                    {
                        credentials: autoCreds
                    }
                );
                new LexRuntime(
                    {
                        credentials: autoCreds
                    }
                );
            }
        ).catch ((e) => {
            // do something
            console.log ("Failed to set config for AWS SDK");
            console.log(e);
        });
  }

  componentDidUpdate() {
    if(this.state.loaded && XR.isSceneLoaded(this.state.sceneName)) {  
      this.setState({loaded: true})
      // Hub.dispatch("sceneLoaded", {}, "SceneLoaded");
      if (XR.isMuted(this.state.sceneName)) {      // The scene is muted
          XR.setMuted(this.state.sceneName, false) // Unmute
      }
    }
  }

  toggle() {
    this.setState({play: !this.state.play }); //toggle 
  }

  hide() {
    this.setState({play: false});
  }

  show() {
    this.setState({play: true});
  }

  async loadScene() {
    await XR.loadScene(this.state.sceneName, this.state.domId);
  }

  async playScene() {
    if(! (XR.isSceneLoaded(this.state.sceneName)) ) {
      await this.loadScene();
    }

    await XR.start(this.state.sceneName);
  }
}; //end AppSceneMedInterface



