import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
import _ from 'lodash';
import autobind from 'class-autobind';
 
export default class DevButtons extends Component {
    getInitialState() {
        return {
        }
    }

    constructor( props ) {
        super( props );

        console.log("in DevButtons ctor, props:", props);

        this.state = this.getInitialState();

        autobind(this);
    }

    toggleSudoku() {
        const event = {
            event: "play",
            data: {},
            message: 'sudoku gives sick gains'
        }

        Hub.dispatch("AppSudoku", event);
        Hub.dispatch("hideAppInitialSidebar");
        Hub.dispatch("disableDevMode");        
    }

    toggleBingo() {
        const bingoEvent = {
            event: "playBingo",
            data: {},
            message: 'bingo is the nameo'
        }

        Hub.dispatch("PlayBingo", bingoEvent);
        console.log("in toggleBingo, window.eventManager: ", window.eventManager);
        let play = () => window.amplifySceneLoader.dispatchPlayBingo();
        let trap = _.once(play);

        trap(); //hack, refactor later
        window.eventManager.emit("StartBingo")
        Hub.dispatch("hideAppInitialSidebar");
        // Hub.dispatch("showAppSplashScreen");
        Hub.dispatch("disableDevMode");
    }

    toggleCalibration(){
        const calibrationEvent = {
            event: "playCalibration",
            data: {},
            message:"starting calibration scene"
        }

        Hub.dispatch("PlayCalibration", calibrationEvent);
        console.log("in toggleCalibration, window.eventManager: ", window.eventManager)
        let play = () => window.amplifySceneLoader.dispatchPlayCalibration();
        let trap = _.once(play)

        trap()
        window.eventManager.emit("StartCalibration")

        Hub.dispatch("disableDevMode")
    }

    playMedicationManagement() {
        window.amplifySceneLoader.dispatchPlayMedicationManagement();
        Hub.dispatch("disableDevMode");
        window.eventManager.emit("startMedicationManagement");
    }

    toggleCheckers() {
        Hub.dispatch("ToggleCheckers");
        Hub.dispatch("ToggleSceneSelectMenu");
        Hub.dispatch("HideAppInitialSidebar");
        Hub.dispatch("toggleDevMode");
    }

    toggleMenu() {
        Hub.dispatch("ToggleSceneSelectMenu"); 
        Hub.dispatch("HideAppInitialSidebar");
        Hub.dispatch("disableDevMode");
    }

    testVitals() {
        window.amplifySceneLoader.dispatchPlayAsthmaVitals();
        Hub.dispatch("disableDevMode");
    }

    componentDidMount() {

    }


    render() {
        return this.props.on ? (  
            <div style={{zIndex:"900000"}} className="vertical-container_development ui-button">
                    <button onClick={() => this.toggleMenu()} className="vertical-item btn-ecg">
                        Sidebar
                    </button>
                    <button onClick={this.toggleBingo} className="vertical-item btn-ecg">
                        Bingo
                    </button>
                    <button onClick={this.toggleSudoku} className="vertical-item btn-ecg">
                        Sudoku
                    </button>
                    <button onClick={this.toggleCheckers} className="vertical-item btn-ecg">
                        Checkers
                    </button>
                    <button onClick={() => this.testVitals()} className="vertical-item btn-ecg"> 
                        Test Vitals (Asthma)
                    </button>
                    <button onClick={this.toggleCalibration} className="vertical-item btn-ecg"> 
                        Calibration
                    </button>
                    <button onClick={this.playMedicationManagement} className="vertical-item btn-ecg">
                        Medication Management
                    </button>
            </div>
        ) : (null);
    }
}