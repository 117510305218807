// import { Hub } from 'aws-amplify';
import React from 'react';
import { Client } from 'boardgame.io/react';
// eslint-disable-next-line
import { Game, INVALID_MOVE } from 'boardgame.io/core';
// import { AI } from 'boardgame.io/ai';
import _ from 'lodash';
import $ from 'jquery';
// import Draughts from 'draughts';
// import ReactBoard from 'react-board';

// const draughts = new Draughts();

function IsVictory(cells) {
    // return draughts.game_over();
}

const NUM_TILES = 8 * 8; //8 tiles wide, 8 tiles deep

const CheckersGame = Game({
    setup: () => {
        let G = {
            tiles: Array(NUM_TILES).fill(null),
            whitePositions: [],
            blackPositions: []
        }

        let board = [
            [' ', '⛀', ' ', ' ', ' ', ' ', '⛂', ' '],
            ['⛀', ' ', '⛀', ' ', ' ', '⛂', ' ', '⛂'],
            [' ', '⛀', ' ', ' ', ' ', ' ', '⛂', ' '],
            ['⛀', ' ', '⛀', ' ', ' ', '⛂', ' ', '⛂'],
            [' ', '⛀', ' ', ' ', ' ', ' ', '⛂', ' '],
            ['⛀', ' ', '⛀', ' ', ' ', '⛂', ' ', '⛂'],
            [' ', '⛀', ' ', ' ', ' ', ' ', '⛂', ' '],
            ['⛀', ' ', '⛀', ' ', ' ', '⛂', ' ', '⛂'],
        ];

        var merged = [].concat.apply([], board);

        G.tiles = merged;

        return G;
    },

    moves: {
        clickCell(G, ctx, id) {

        },

        movePiece(G, ctx, fromId, toId) {
            // draughts.move( `${fromId}-${toId}` )
        }

    },


    flow: {
        movesPerTurn: 1,



        endGameIf: (G, ctx) => {
            if (IsVictory(G.tiles)) {
                return { winner: ctx.currentPlayer }
            }
        },
    }
});

class CheckersBoard extends React.Component {
    isActive(id) {
        if (!this.props.isActive) return false;
        if (this.props.G.tiles[id] !== 'X') return false;
        return true;
    }

    getInitialState() {
        return {
            on: false
        }
    }

    clickHandler({ col, row, cellName, cellValue }) {
        // ...
    }

    constructor(props) {
        super(props);

        this.clickHandler = this.clickHandler.bind(this);

        this.board = [
            [' ', '⛀', ' ', ' ', ' ', ' ', '⛂', ' '],
            ['⛀', ' ', '⛀', ' ', ' ', '⛂', ' ', '⛂'],
            [' ', '⛀', ' ', ' ', ' ', ' ', '⛂', ' '],
            ['⛀', ' ', '⛀', ' ', ' ', '⛂', ' ', '⛂'],
            [' ', '⛀', ' ', ' ', ' ', ' ', '⛂', ' '],
            ['⛀', ' ', '⛀', ' ', ' ', '⛂', ' ', '⛂'],
            [' ', '⛀', ' ', ' ', ' ', ' ', '⛂', ' '],
            ['⛀', ' ', '⛀', ' ', ' ', '⛂', ' ', '⛂'],
        ];

        this.state = this.getInitialState();

        this.state.whiteDraught = "⛂";
        this.state.whiteKing = "⛁";
        this.state.blackDraught = "⛂";
        this.state.blackKing = "⛃";

        this.onClick = this.onClick.bind(this);
        this.blackPositions = _.range(0, 64, 2);
        this.whitePositions = _.range(1, 63, 1);
    }

    componentDidMount() {
        let x = 0;
        $(".react-board-cell").each((i, el) => {
            if (x % 2 === 0) {
                $(this).attr("background-color", "white")
            } else {
                $(this).attr("background-color", "brown")
            }

        });
        // this.forceUpdate();
    }


    onClick(e, id) {
        e.preventDefault();

        // let x = id / 8;
        // let y = id % 8;

    }

    render() {
        if (!(this.props.on)) return (null);

        let winner = '';
        if (this.props.ctx.gameover) {
            winner =
                this.props.ctx.gameover.winner !== undefined ? (
                    <div id="winner">{this.props.ctx.gameover.winner}</div>
                ) : (
                    <div id="winner">Draw!</div>
                );
        }

        const whiteCellStyle = {
            border: '1px solid #555',
            padding: "2.5vh 2vw",
            width: '2vw',
            height: '2vw',
            lineHeight: '50px',
            textAlign: 'center',
            backgroundColor: "white"
        };

        const blackCellStyle = {
            border: '1px solid #555',
            padding: "2.5vh 2vw",
            width: '2vw',
            height: '2.5vh',
            lineHeight: '50px',
            textAlign: 'center',
            backgroundColor: 'black' //I know how awful this code duplication is :D
        };

        let tbody = [];
        this.tbody = tbody;
        let row = false;
        let x = 1;
        for (let i = 0; i < 8; i++) {
            let cells = [];
            for (let j = 0; j < 8; j++) {
                const id = 8 * i + j;
                cells.push(
                    <td style={ x % 2 === 0 ? blackCellStyle : whiteCellStyle } key={id}  className="checkers-board-cell" onClick={(e) => this.onClick(e, id)}>
                        {this.props.G.tiles[id]}
                    </td>
                );
                x++;
                row = !row;
            }
            tbody.push(<tr key={i}>{cells}</tr>);
            x++;
        }


        return (
            <div className="checkers-board" style={{display:"flex", flexDirection:"column",position: "absolute", left:"50vw", top: "10vh", zIndex: "10000"}}>

                <table style={{left:"45vw", top: "20vh"}} id="checkersBoard">
                    <tbody>{tbody}</tbody>
                </table>
                {winner}
            </div>
        );

        // return React.createElement(ReactBoard, {
        //     size: [8 /* width */ , 8 /* height */ ], // or just `size: 8`
        //     values: this.board,
        //     highlight: [
        //         [4 /* col index */ , 3 /* row index */ , ]
        //     ],
        //     clickHandler: this.clickHandler,
        // });
    }

}


const AppCheckersGame = Client({
    game: CheckersGame,
    board: CheckersBoard,
    debug: false,
    // debug: true,
    numPlayers: 2,

    /*    ai: AI( {
            enumerate: ( G, ctx ) => {
                return draughts.moves();
            },
        } ),*/
});

export default AppCheckersGame;

// export default CheckersBoard;