import React, { useState } from 'react';
import { Hub } from "aws-amplify";
import Mic from './Mic/index';
import Speaker from './Speaker/index';
import Connection from './Connection/index'
import CheckBox from './CheckBox/index'



const AppCalibration = () => {
  //state for loading the scene 
  const [sceneLoaded, setSceneLoaded] = useState(false);

  //set scene loaded to true if PlayCalibration is dispatched 
  Hub.listen("PlayCalibration", () => {
    setSceneLoaded(true)
  });

  return (
      <div>
      { sceneLoaded && (
        <div>
        <Mic />
        <Speaker  />
        <Connection />
        <CheckBox />
      </div>
      )
      }
      </div>
    );
  
}

export default AppCalibration;
