import React, {useState} from "react";
import SpeakerInstructions from './SpeakerInstructions';
import './styles.scss'
import _ from 'lodash'


const Speaker = () => {
  
  const [instrucitonsView, setInstructionsView] = useState(false);
  //List of phrases that are going to be emitted to sumerian scene 
  const phrases = 
    [`<speak>Hello, this is a test for your speakers</speak>`,
    `<speak>Test Test</speak>`,
    `<speak>If you can hear me, your speakers are working</speak>`,
    `<speak>The Speakers seem to be working</speak>`
    ]
  
  //emit a sample(random) phrase to sumerian when called. 
  const toggle = () => {
    setInstructionsView(true)
    window.eventManager.emit(
    "testSpeakers",
    _.sample(phrases)

  )

};

  return (
    <div>
      <button className="speakers-calibration" onClick={toggle}>Test Speakers</button>
      { 
          instrucitonsView?<SpeakerInstructions />:(null)
      }
    </div>
    
  );
};

export default Speaker;