import React, { useState } from 'react';
// import { ReactMic } from 'react-mic'
import MicInstructions from './MicInstructions'
import './styles.scss'


const Mic = () => {
  //state for microphone feedback data and microphone display
  const [micView, setMicView] = useState(false);
  const [recoding, setRecording] = useState(false); //eslint-disable-line no-unused-vars
  const [blobData, setBlobData] = useState(null);   //eslint-disable-line no-unused-vars
  const [instrucitonsView, setInstructionsView] = useState(false);
    
  const startRecording = () => {
    //set micView and Recording to True 
    micView?setMicView(false) :setMicView(true);
    micView?setRecording(false) :setRecording(true);
    setInstructionsView(true)
    //change the recoring to false before MicView to disable infinite feed from mic. 
    setTimeout(() => setRecording(false), 4700);
    setTimeout(() => setMicView(false), 5000);

  }
  
/* 
//console log the data when called 
  const onData = (recordedBlob)  =>  {
    console.log('chunk of real-time data is: ', recordedBlob);
    
}

//set the Blob State to null on stop and log the total data 
  const onStop = (recordedBlob) =>  {
    setBlobData(null);
    console.log('recordedBlob is: ', recordedBlob);
    setBlobData(recordedBlob);
  }

 */
  return (
      <div>
        
        {/*<ReactMic
                  onClick={startRecording}
                  record={recoding}
                  className="microphone-calibration"
                  onStop={onStop}
                  onData={onData}
                  strokeColor="#d2d6e8"
                  backgroundColor="#045C84"
                  />*/}

        { 
          micView?
          
          <div></div>
          :
          <button className="microphone-calibration" onClick={startRecording} type="button">{!blobData?"Test Microphone":"Microphone Connected"}</button>
        }
        {
          instrucitonsView?<MicInstructions />:(null)
        }
      </div>
    );
  
}

export default Mic;
