import React, { Component } from 'react';
// import $ from 'jquery';
import { Hub } from 'aws-amplify';
// import AmplifySceneLoader from '../../AmplifySceneLoader';
// import NoSvg from './svg/SVG_No.svg'

export default class SnoozeButton extends Component {
    getInitialState() {
        return {
            on: false,
        }
    }


    constructor(props) {
        super(props);
        this.state = this.getInitialState();

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.reset = this.reset.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);
        this.handleClick = this.handleClick.bind(this);

        Hub.listen("UIReset", this.onHubCapsule, "SnoozeButton");
        Hub.listen("ShowSnooze", this.onHubCapsule, "SnoozeButton");
        Hub.listen("HideSnooze", this.onHubCapsule, "SnoozeButton");
        Hub.listen("snooze", this.onHubCapsule, "SnoozeButton");
        Hub.listen("snooze_last", this.onHubCapsule, "SnoozeButton");
        Hub.listen("YesBtn", this.onHubCapsule, "SnoozeButton");
        Hub.listen("UIReset", this.onHubCapsule, "SnoozeButton");
        Hub.listen("SceneReset", this.onHubCapsule, "SnoozeButton");
    }

    //ignore this for now
    componentDidMount() {

    }

    //ignore this for now
    componentDidUpdate() {

    }

    reset() {
        this.setState(this.getInitialState());
    }

    show() {
        this.setState({ on: true });
    }


    hide() {
        this.setState({ on: false });
    }

    onHubCapsule(capsule) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if (channel === "UIReset") {
            this.reset();
        } else if (channel === "ShowSnooze") {
            this.show();
        } else if (channel === "HideSnooze" || channel === "snooze" || channel === "snooze_last" || channel === "YesBtn" || channel === "SceneReset") {
            this.hide();
        }
    }

    handleClick(event) {
        event.preventDefault();
        this.hide();
        window.eventManager.emit("snooze");
        window.eventManager.emit("post_to_lex", "not yet");
        Hub.dispatch("snooze");
        Hub.dispatch("HideYes");
        // window.eventManager.emit("post_to_lex", "no");
    }


    render() {
        return (
            <button className={ this.state.on ? "btnnotyet" : "hidden"} onClick={this.handleClick} id="btnnotyet">

                <svg viewBox="0 0 398 398" width="4vw" height="4vw" overflow="visible">
    <g transform="translate(-100,25)">
        <path className="NoXSvg" fill="#d2d6e8" d="M288.8,199l97.1-97.1c6.2-6.2,9.2-13.7,9.2-22.5s-3.1-16.3-9.2-22.5L341,12c-6.2-6.2-13.7-9.2-22.5-9.2 S302.2,5.9,296,12l-97,97.2L101.9,12c-6.2-6.2-13.6-9.2-22.5-9.2C70.6,2.8,63.1,5.9,57,12L12,57c-6.2,6.2-9.2,13.7-9.2,22.5 S5.9,95.8,12,102l97.1,97.1l-97.1,97c-6.2,6.2-9.2,13.7-9.2,22.5s3.1,16.3,9.2,22.5L57,386c6.2,6.2,13.7,9.2,22.5,9.2 s16.3-3.1,22.5-9.2l97.1-97.1l97.1,97.1c6.2,6.2,13.6,9.2,22.5,9.2c8.8,0,16.3-3.1,22.5-9.2l44.8-45c6.2-6.2,9.2-13.7,9.2-22.5 s-3.1-16.3-9.2-22.5L288.8,199z" />
    </g>
</svg>

        Not Yet

            </button>
        );
    }
}