import React, { useState } from 'react';
import "./styles.scss"

const CheckBox = () => {
  
//state for each checkbox whether it is checked or not 
const  [calibrated, setCalibrated] = useState({
  microphone:false,
  Speakers:false,
  Connection:false

});

//set each portion true if checked
const micCalibration = () => {
  setCalibrated({...calibrated, microphone:!calibrated.microphone})
}

const speakersCalibration = () => {
  setCalibrated({...calibrated, Speakers:!calibrated.Speakers})
}

const connectionCalibration = () => {
  setCalibrated({...calibrated, Connection:!calibrated.Connection})
  
}

//emit addison to state calibration is complete; load to TLS once done 
const sceneCalibrated  = () => {
  window.eventManager.emit(
    "sceneCalibrated",
    `<speak>Great you're all set! Lets get started!</speak>`
  )
  // setTimeout(() => window.location.reload(), 4000);

  
}
    return (
     
        <div className="checkbox__container">
        <input type="checkbox" className="cb-microphone-calibration" onChange={micCalibration}/>
        <input type="checkbox" className="cb-speakers-calibration" onChange={speakersCalibration}/>
        <input type="checkbox" className="cb-speed_test-calibration" onChange={connectionCalibration}/>
        

        {calibrated.microphone&&calibrated.Speakers&&calibrated.Connection?sceneCalibrated():(null)}
        
      </div>
    );
  
}

export default CheckBox;
