//AppHealthSidebar.jsx

import React from 'react';
import { Hub } from 'aws-amplify';
import autobind from 'class-autobind';
  
export default class AppVitalsSidebar extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);
    }

    containerStyle = {
        top: "10vh",
        left: "80vw",
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        justifyContent: "spaceBetween",
    };

    buttonStyle = {
        width: "18vw",
        height: "25vh",
        margin: ".5vw .5vh",
    }

    handleDisplayVitalHistory(e) {
        e.preventDefault();
        Hub.dispatch("showAppSplashScreen");
        window.amplifySceneLoader.dispatchPlayVitalsHistory();
    }

    handleTakeVital(e) {
        e.preventDefault();

        Hub.dispatch("showAppSplashScreen");
        window.amplifySceneLoader.dispatchPlayVitals();
    }

    handleDone(event) {
        event.preventDefault();
        console.log("clicked Addison Button, reloading page...");
        Hub.dispatch("UIReset");
        // window.location.reload();
    }

    render() {
        return this.props.on ? (
          <div id="appOptionsSidebarContainer" style={this.containerStyle}>
            <button style={this.buttonStyle} className="btnTemplate"  onClick={this.handleTakeVital}> Take Vital </button>
            <button style={this.buttonStyle} className="btnTemplate" onClick={this.handleDisplayVitalHistory}> Display Vital History </button>
            <button style={this.buttonStyle} className="btnDone"  onClick={this.handleDone}> Done </button>
          </div>
        ) : (null);
    }
}