import React, { useState } from 'react';
import ConnectionInstructions from './ConnectionInstructions';
import './styles.scss'

const FastSpeedtest = require("fast-speedtest-api");

//token from netflix internet connection API
    const token = "YXNkZmFzZGxmbnNkYWZoYXNkZmhrYWxm"

    const Connection = () => {
    //state for setting view and speed test amount 
    const [speed, setSpeed] = useState("");
    const [view, setView] = useState(false);
    const [instrucitonsView, setInstructionsView] = useState(false);

    // new speedtest object 
    let speedtest = new FastSpeedtest({
        token: token, // required
        verbose: false, // default: false
        timeout: 10000, // default: 5000
        https: true, // default: true
        urlCount: 5, // default: 5
        bufferSize: 8, // default: 8
        unit: FastSpeedtest.UNITS.Mbps // default: Bps
    });



    const toggle = () => {
        //on toggle set the view of the box to false 
        setView(!view);
        //set the speed test value to testing when fetching to the API
        setSpeed("Testing");
        setInstructionsView(true);
        speedtest.getSpeed().then(s => {
            console.log(`Speed: ${s} Mbps`);
            // set the speed test integer value to string
            setSpeed(s.toString());

        }).catch(e => {
        //log the error if there is one 
            console.error(e.message);
        //set the speed to 0 if there is an error 
            setSpeed(0);
        });
    
};




return(
    <div>
        <button className="speed_test-calibration" onClick={toggle}>
         {view?(speed>=20?"Internet Connected"
         :speed==="Testing"?speed :
         "not connected")
         :"Test Internet Connection"}   
        </button>
        { 
          instrucitonsView?<ConnectionInstructions />:(null)
        }
    </div>
    )
}

export default Connection; 