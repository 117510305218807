//AppHealthSidebar.jsx

import React from 'react';
import { Auth, Hub, Logger } from 'aws-amplify';
import API, { graphqlOperation } from "@aws-amplify/api";
import FitbitAuthButton from './buttons/FitbitAuth';

/*
 *
 *
const createmed = `mutation CreateScheduleReminder { 
  createScheduleReminder(input: {
    localOwner: "dcharles",
    timestamp: "1555104232",
    medName: "albuterol1",
    medDose: "2x puff prn",
    medicationReminderUserId: "dcharles"
  }) {
    id
    localOwner
  }
}
`; // eslint-disable-line no-unused-vars

const submed = `subscription {
 onUpdateScheduleReminder(localOwner: "dcharles") {
    id
    localOwner
    }
}
`
;
*/

let subscription; // eslint-disable-line no-unused-vars
const logger = new Logger('foo');

export default class AppMedicationsSidebar extends React.Component {

    getInitialState() {
        return {
            syncNotification: false,
            syncAwait: false,
            testSubMedId: null,
        }
    }

    constructor(props) {
        super(props);

        this.handleDone = this.handleDone.bind(this);
        this.handleShowNextMedReminder = this.handleShowNextMedReminder.bind(this);
        this.handleDidITakeMyMed = this.handleDidITakeMyMed.bind(this);

        this.getInitialState = this.getInitialState.bind(this);
        this.state = this.getInitialState();

        Hub.listen ("UIReset", (date) => this.setState(this.getInitialState()));
    }

    async componentDidMount() {
        // are we awaiting a sync?  If so, disable sync button(s)
    }

    /*
     * Authorization Code Grant Flow button/link
     *
     * Popup auth window for user, with uri params generated from app
     * Handles display and return from that authorization page
     */
    handleAuthFitbit = async (e) => {
//        e.preventDefault();
        /*
        try {
            //http://fitbit.com/oauth2/authorize
            //
            // get client_id from (AppSync | S3 | DynamoDB | Lambda)
            // get current user credentials
            Auth.currentAuthenticatedUser({bypassCache: false})
            .then (user => {
                // set up login popup
                let urlParamsAuth = {
                    client_id: "undefined", // 
                    response_type: "undefined", // code(grant)|token(implicit)
                    scope: "activity sleep", // space delimited
                    redirect_uri: "https://vt8h1vo4x7.execute-api.us-east-1.amazonaws.com/post/Fitbit_DB_POST", 
                    // OK to add uri params here???
                    expires_in: 1800, // 30min
                    //expires_in: 31536000, // 1 year
                    "prompt": "none", // use default behavior
                    "state": "anti-forgery token" // send and check in redirect_uri
                };
            //without exposing client_secret to client code, send client_secret in header
                // urlParamsAuth to be provided as uri params in call to fitbit
                // oauth: https://www.fitbit.com/oauth2/authorize
                let formParamsGrant = {
                    code: "undefined", // Base64(client_id:client_secret)
                    grant_type: "authorization_code",
                    client_id: "undefined",
                    redirect_uri: "https://vt8h1vo4x7.execute-api.us-east-1.amazonaws.com/post/Fitbit_DB_POST",
                    // MUST match redirect_uri of Auth grant
                    state: "anti-forgery token", // send and check in redirect_uri,
                    expires_in: 31536000, // 1 year
                };
                // At the redirect_uri, a code valid for 10m is given
                // That code must then be submitted to:
                // ---POST https://api.fitbit.com/oauth2/token
                // in the form:
                // Header: 
                // Authorization: Basic Base64(cleint_id:client_secret)...
                // Content-Type: application/x-www-form-urlencoded
                // Body: formParamsGrant
                // @return { // example
                //         "access_token": "eyJhbGciOiJIUzI1NiJ9.eyJleHAi.."
                //         "expires_in": 3600,
                //         "refresh_token": "c643a63c072f0f05478e9d18b991db80ef6061e4f8e6c822d83fed53e5fafdd7",
                //         "token_type": "Bearer",
                //         "user_id": "7JY326"
                // }
                // error_description - 
                //
                // or, if no error...
                // The system is ready to make requests to the Fitbit API
                // eg.
                // GET https://api.fitbit.com/1/user/7JY326/profile.json
                // Authorization: Bearer eyJhbGciOiJIUzI1NiJ9.eyJleHAi..
                // (ie. Authorization: "Bearer client_id:client_secret")
                // If the access token(AT) expires, the following error gets
                // returned, requiring the one-time use of the refresh token (RT)
                //
//				{
//					"errors": [
//						{
//							"errorType": "expired_token",
//							"message": "Access token expired: eyJhbGciOiJIU..."
//						}
//					]
//				}
            })
            .catch (err => {
                logger.error("Error retrieving current authenticated user: ", err);
            });
        } catch (e){
            logger.error("Uncaught error: ", e); 
        }
        */
    }

    async handleShowNextMedReminder(e) {
        e.preventDefault();
        try {
            Auth.currentAuthenticatedUser({
                bypassCache: false  // If true, sends a request to Cognito
            }).then(user => {
                if (this.state.testSubMedId !== null){
                    API.graphql(graphqlOperation(
                        `mutation UpdateScheduleReminder {
                            updateScheduleReminder(
                                input: {
                                    id: "${this.state.testSubMedId}",
                                    localOwner: "${user.username}",
                                    timestamp: "1555104240",
                                    medName: "albuterol1",
                                    medDose: "2x puff prn",
                                    scheduleReminderUserId: "${user.username}"
                            }) {
                                id
                                localOwner
                                timestamp
                                medName
                                medDose
                            }
                        }`
                    )).then ( (todoData) => {
                        logger.debug("MUTATION NOTICE (update):", todoData);
                    });
                }
                else {
                    API.graphql(graphqlOperation(
                        `mutation CreateScheduleReminder {
                            createScheduleReminder(input: {
                                localOwner: "${user.username}",
                                    timestamp: "1555104239",
                                    medName: "albuterol1",
                                    medDose: "2x puff prn",
                                    scheduleReminderUserId: "${user.username}"
                            }) {
                                id
                                localOwner
                                timestamp
                            }
                        }`
                    )).then( (todoData) => { 
                        this.setState ({
                            testSubMedId: todoData.data.createScheduleReminder.id
                        });
                        logger.debug("MUTATION NOTICE (create):", this.state.testSubMedId);
                    });
                }
                logger.info(user)
            }).catch(err => logger.error(err));
        }catch (e){
            logger.error(e);
        }
        // Subscribe to creation of Todo
        //TODO
    }

    handleDidITakeMyMed(e) {
        e.preventDefault();
        try {
            Auth.currentAuthenticatedUser({
                bypassCache: false  // If true, sends a request to Cognito
            }).then(user => {
                API.graphql(graphqlOperation(
                    `subscription {
                        onUpdateScheduleReminder(localOwner: "${user.username}") {
                            id
                        }
                    }`
                )).subscribe({
                    next: (todoData) => { logger.debug("SUBSCRIPTION NOTICE:", todoData); }
                })
            }).catch(err => logger.error(err));
        }catch (e){
            logger.error(e);
        }

        //TODO
    }
    
    handleDone(event) {
        event.preventDefault();
        logger.info("clicked Addison Button, reloading page...");
        Hub.dispatch("UIReset");
        // window.location.reload();
    }


    containerStyle = {
        top: "10vh",
        left: "80vw",
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        justifyContent: "spaceBetween",
    };

    buttonStyle = {
        width: "18vw",
        height: "25vh",
        margin: ".5vw .5vh",
    }

    render() {
        return this.props.on ? (
          <div id="appOptionsSidebarContainer" style={this.containerStyle}>
            <button style={this.buttonStyle} className="btnTemplate"  onClick={this.handleShowNextMedReminder}> Show Next Med Reminder </button>
            <button style={this.buttonStyle} className="btnTemplate" onClick={this.handleDidITakeMyMed}> Did I take my med? </button>
            {/*<button style={this.buttonStyle} className="btnAuthFitbit"> AuthFitbit </button>*/}
            <FitbitAuthButton />
            <button style={this.buttonStyle} className="btnDone"  onClick={this.handleDone}> Done </button>
          </div>
        ) : (null);
    }
}
