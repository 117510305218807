//AppSplashScreen.jsx

import React, {Component} from 'react';
import { Hub } from 'aws-amplify';
import autobind from 'class-autobind';

export default class AppSplashScreen extends Component {
  getInitialState() {
    return {
      on: true,
      url: 'https://d3o9tq5hgar52d.cloudfront.net/AddisonCare_Logo_Animated_SplashScreen.mp4'
    }
  }

  handleSceneLoaded(capsule) {
    console.log("in handleSceneLoaded, capsule: ", capsule);
    this.setState({on: false});
  }

  handleUIReset(e) {
    this.setState(this.getInitialState());
  }

  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    autobind(this);

    Hub.listen("sceneLoaded", this.handleSceneLoaded);
    Hub.listen("IntroSceneLoaded", this.handleSceneLoaded);

    Hub.listen("showAppSplashScreen", (data) => this.show());
    Hub.listen("hideAppSplashScreen", (data) => this.hide());
    // Hub.listen("UIReset", this.handleUIReset);

  } //end ctor


  toggle() {
    this.setState({on: !this.state.on }); //toggle 
  }

  show() {
    this.setState({on: true});
  }

  hide() {
  	this.setState({on: false});
  }

  reset() {
    this.setState(this.getInitialState())
  }

  componentDidMount() {
  	let splashVideo = document.getElementById('splash-container');

  	splashVideo.onended = (event) => {
  		this.setState({on: false});
  		Hub.dispatch('splashFinished', {});
  	}
  }


  render() {
    return this.state.on ? ( 
    	<div className={ "Video-Container"}>
	    	<video style={{zIndex: "10000000"}} id={"splash-container"} autoPlay muted>
	    		<source src={this.state.url} type={"video/mp4"} />
	    	</video>
    	</div>
    ) : (null);
  }
};
