import React, {useState} from 'react';
import { Hub } from 'aws-amplify';
import { AppCalibration } from '../components'

const AppCalibrationContainer = () => {

    const [calibrationOn, setCalibration] = useState(false);
    const [on, setOn] = useState(true)

    Hub.listen("ToggleCalibration", (data) => {
    setCalibration(!calibrationOn);
    Hub.dispatch("HideAppInitialSidebar");
    });

    Hub.listen("PlayCalibration", (data) => {
        console.log("in AppCalibration Container, heard PlayCalibration channel");
        setOn(true)
        setCalibration(!calibrationOn)
        Hub.dispatch("HideAppInitialSidebar");
    })

     Hub.listen("AppCalibrationContainer", (capsule) => {
        const { event, data, message } = capsule; // eslint-disable-line no-unused-vars

        switch (event) {
            case ("hide"):
                setOn(false);
                break;
            case ("show"):
                setOn(true);
                break;
            default:
                console.log("default case reached in AppTutorialSplashScreen Hub channel...");
        };
    });
  
  return  on? (
       <div>
        <AppCalibration />
       </div>
    ) : (null)
  }


export default AppCalibrationContainer;
