//AppChart.jsx

import React from 'react';
// import { Hub } from 'aws-amplify';


export default class AppChart extends React.Component {
    render() {
        return (
            <div id="appChart" className="appChart" {...this.props}> 
                    {this.props.children}
                
            </div>
        );
    }

} 