import React, { Component } from 'react';
import $ from 'jquery';
import { Hub } from 'aws-amplify';

export default class MedicationChartTab extends Component {
  getInitialState() {
    return { on: false };
  }

  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    // Hub.listen("PopulateMedTable", this.onHubCapsule);
    Hub.listen('SceneReset', () => {
      this.reset();
    });

    Hub.listen("btndone", () =>{
      this.hide();
    });

    Hub.listen('PopulateMedTable', (capsule) => {
      const { payload } = capsule;
      if (Array.isArray(payload)) {
        this.buildHtmlTable('#excelDataTable', payload);
      } else {
        const meds = [];
        for (const key in payload) {
          meds.push(payload[key]);
        }
        this.buildHtmlTable('#excelDataTable', meds);
      }
      this.show();
    });


    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.reset = this.reset.bind(this);
    this.buildHtmlTable = this.buildHtmlTable.bind(this);
    this.getInitialState = this.getInitialState.bind(this);
    this.addAllColumnHeaders = this.addAllColumnHeaders.bind(this);
  }


  // ignore this for now
  componentDidMount() {}

  // ignore this for now
  componentDidUpdate() {}

  show() {
    this.setState({ on: true });
  }

  hide() {
    this.setState({ on: false });
  }

  reset() {
    this.setState(this.getInitialState());
  }


  buildHtmlTable(selector, medSchedule) {
    $('#excelDataTable').html(''); // this clears it out incase double input
    const columns = this.addAllColumnHeaders(medSchedule, selector);
    for (let i = 0; i < medSchedule.length; i++) {
      const row$ = $('<tr/>');
      for (let colIndex = 0; colIndex < columns.length; colIndex++) {
        let cellValue = medSchedule[i][columns[colIndex]];
        if (cellValue === null) { cellValue = ''; }
        row$.append($('<td/>').html(cellValue));
      }
      $(selector).append(row$);
    }
  }

  addAllColumnHeaders(medSchedule, selector) {
    const columnSet = [];
    const headerTr$ = $('<tr/>');
    for (let i = 0; i < medSchedule.length; i++) {
      const rowHash = medSchedule[i];
      for (const key in rowHash) {
        if ($.inArray(key, columnSet) === -1) {
          columnSet.push(key);
          headerTr$.append($('<th/>').html(key));
        }
      }
    }
    $(selector).append(headerTr$);
    return columnSet;
  }

  render() {
    return (<div id="MedicationChartTab" className={this.state.on ? 'MedicationChartTab' : 'hidden'}><table className="med-table" id="excelDataTable" /></div>);
  }
}
