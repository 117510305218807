//AppUserInterface.jsx

import React from 'react';
import { Logger, Hub } from 'aws-amplify';
import { AppSplashScreen, AppSidebarContainer, AppButtons, DevButtons, AppGameContainer, AppCalibrationContainer, AppChartContainer, AppImageContainer, AppVideoContainer } from './containers'; 
import { /*AppInitialSidebar, AppHealthSidebar, AppAddisonQuestionsSidebar, AppAboutPrompt,*/ MedTable, GreatJobTab, Timer, AlertTab,  } from './components';
import AppSyncManager from './AppSyncManager';
import autobind from 'class-autobind';

const logger = new Logger('AppUserInterface');

export default class AppUserInterface extends React.Component {
	//define your container's initial state
	getInitialState() {
		return {
			appButtonsOn: true,
			devMode: false,
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if(nextState.devMode !== this.state.devMode) {
			return true;
		}

		return false;
	}

	constructor(props) {
		super(props)
		autobind(this);

		//assign your container's initial state
		this.state = this.getInitialState();

		//create listeners for events relevant to this component/container
		Hub.listen("ShowAppInitialSidebar", (data) => {
			this.setState({initialSidebarOn: true});
		})

		Hub.listen("HideAppInitialSidebar", (data) => {
			this.setState({initialSidebarOn: false});
		})

		Hub.listen("toggleDevMode", (data) => {
			console.log("toggleDevMode heard from AppUserInterface")
			this.setState({devMode: !(this.state.devMode)})
		})

		Hub.listen("disableDevMode", (data) => {
			console.log("disableDevMode heard from AppUserInterface")
			this.setState({devMode: false})
		})

		let _init = async () => {
		
				//reset your container's state on UIReset channel events
				Hub.listen("UIReset", (data) => this.setState(this.getInitialState()));
		
				window.appSyncManager = window.appSyncManager || new AppSyncManager();
				let devMode = false;
		
				logger.debug(`devMode ${devMode} before running window.appSyncManager.isDeveloper()`)
		
		        if(await window.appSyncManager.isDeveloper()) {
		            devMode = true;
		        } else {
		        	Hub.dispatch("disableDevMode"); //why is this necessary? weird state bug --jkeys
		        }
		
				logger.debug(`[AppUserInterface] isDeveloper: ${devMode}`)
		
		        this.state.devMode = devMode;
		}
		
		_init();
	}


	//pull in any data that sub-containers will need
	async componentDidMount() {

	}

	componentWillUnmount() {
		const listeners = ["ShowAppInitialSidebar", "HideAppInitialSidebar", "toggleDevMode", "disableDevMode", "UIReset"];

		listeners.forEach( (listener) => {
			Hub.remove(listener);
		});
	}


	render() {
		return (
		<React.Fragment>
			{/* Splash screens / loading backgrounds */}
			<AppSplashScreen />

        	{/* Button containers */}
    	    <AppButtons on={this.state.appButtonsOn}/>
        	<DevButtons on={this.state.devMode} /> 

        	{/* Misc. containers */}
        	<AppSidebarContainer />

        	<AppVideoContainer />
        	<AppImageContainer />
			<AppCalibrationContainer />
        	<AppGameContainer />
        	<AppChartContainer />

        	<MedTable />
        	<GreatJobTab/>
        	<Timer />
        	<AlertTab />
        </React.Fragment>
		)
	}
}
