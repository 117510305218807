import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
export default class FinishedButton extends Component {
	getInitialState() {
		return {
			on: false
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        this.onHubCapsule = this.onHubCapsule.bind(this);

        Hub.listen("ShowFinished", this.onHubCapsule);
        Hub.listen("showFinished", this.onHubCapsule);
        Hub.listen("hideFinished", this.onHubCapsule);
        Hub.listen("VitalsDone", this.onHubCapsule);
        Hub.listen("toggleFinishedBtn", this.onHubCapsule);
        Hub.listen("SceneReset", this.onHubCapsule);
        Hub.listen("UIReset", this.onHubCapsule);
        Hub.listen("finished", this.onHubCapsule);
        this.handleClick = this.handleClick.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);
    }



    handleClick(event) {
      event.preventDefault();
      this.setState({on: false});
      Hub.dispatch("HandleFinished");
      window.eventManager.emit("HandleFinished");
    }


    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "showFinished" || channel === "ShowFinished") {
        	this.setState({on: true});
        }
        
        else if(channel === "VitalsDone" || "SceneReset" || channel === "finished" || channel === "hideFinished") {
        	this.setState({on: false});
        }
        
        else if(channel === "toggleFinishedBtn") {
        	this.setState({on: !(this.state.on)});
        }
    }


    render() {
        if(!(this.state.on)) return (null);

        return (
            <button className={"btnSmallSierra btnNutrition"} onClick={this.handleClick} id="btnFinished">Finished</button>
        );
    }
}