import S3 from 'aws-sdk/clients/s3';
import { Logger, Analytics, Auth, XR, Hub } from 'aws-amplify';
import autobind from 'class-autobind';

//var autoCreds = require ('./CredentialsModule');
import autoCreds from './CredentialsModule';
const introConfig = require('./config/introScene.json'); // This file will be generated by the Sumerian AWS Console
const testTLSConfig = require('./config/testTLSScene.json');

const logger = new Logger('AmplifySceneLoader');

var sceneConfigs = {
    testTLSScene: {
        name: "testTLSScene",
        region: "us-east-1",
        config: testTLSConfig,
        events: {
            load: ["loadTestTLSScene"],
            play: ["playTestTLSScene"]
        }
    },
    medicationManagementScene: {
        name: "medicationManagementScene",
        region: "us-east-1",
        events: {
            load: ["loadMedicationManagementScene"],
            play: ["playMedicationManagementScene"]
        }
    },
    vitalsHistoryScene: {
        name: "vitalsHistoryScene",
        region: "us-east-1",
        events: {
            load: ["loadVitalsHistoryScene"],
            play: ["playvitalsHistoryScene"]
        }
    },
    bingoScene: {
        name: "bingoScene",
        region: 'us-east-1',
        events: {
            load: ["loadBingoScene"],
            play: ["playBingoScene"],
        }
    },
    calibrationScene:{
        name:"calibrationScene",
        region:'us-east-1',
        events: {
           load:["loadCalibrationScene"],
           play:["playCalibrationScene"],
        }
    },
    introScene: {
        name: "introScene",
        config: introConfig,
        domId: "intro-dom-id",
        region: 'us-east-1',
        events: {
            load: ["loadIntroScene"],
            play: ["playIntroScene"],
            sumerianplay: [""],
            sumerianLoad: [""]
        }
    }, //end introScene
    helpScene: {
        name: "helpScene",
        events: {
            load: ['loadHelpScene'],
            play: ['playHelpScene', 'RR_Scene']
        }
    },
    copingScene: {
        name: "copingScene",

        events: {
            load: ["loadCopingScene"],
            play: ["playCopingScene"],
        },
    },
    activityMonitoringScene: {
        name: "activityMonitoringScene",

        events: {
            load: ["loadActivityMonitoringScene"],
            play: ["playActivityMonitoringScene"],
        },
    },
    nutritionScene: {
        name: "nutritionScene",
        events: {
            load: ["loadNutritionScene"],
            play: ["playNutritionScene"],
        },
    },
    gaitSceneCFRSI: {
        name: "gaitSceneCFRSI",
        events: {
            load: ["loadGaitSceneCFRSI"],
            play: ["playGaitSceneCFRSI"],
        },
    },
    gaitSceneCFRSI_Maria: {
        name: "gaitSceneCFRSI_Maria",
        events: {
            load: ["loadGaitSceneCFRSI_Maria"],
            play: ["playGaitSceneCFRSI_Maria"],
        },
    },
    gaitSceneHeartDisease: {
        name: "gaitSceneHeartDisease",
        events: {
            load: ["loadGaitSceneHeartDisease"],
            play: ["playGaitSceneHeartDisease"],
        },
    },
    gaitSceneDiabetes: {
        name: "gaitSceneDiabetes",
        events: {
            load: ["loadGaitSceneDiabetes"],
            play: ["playGaitSceneDiabetes"],
        },
    },
    medSceneAsthma: {
        name: "medSceneAsthma",
        events: {
            load: ["loadMedSceneAsthma"],
            play: ["playMedSceneAsthma"],
        }
    }, //end medScene
    medSceneDiabetes: {
        name: "medSceneDiabetes",
        events: {
            load: ["loadMedSceneDiabetes"],
            play: ["playMedSceneDiabetes"],
            sumerianplay: ["Diabetes_Meds"],
            sumerianLoad: ["Diabetes_Meds"]
        }
    }, //end medScene
    medSceneHeartDisease: {
        name: "medSceneHeartDisease",
        events: {
            load: ["loadMedSceneHeartDisease"],
            play: ["playMedSceneHeartDisease"],
        }
    }, //end medScene
    vitalsScene: {
        name: "vitalsScene",
        events: {
            load: ["loadVitalsScene"],
            play: ["playVitalsScene"],
        }
    }, //end vitalsScene
    vitalsSceneAsthma: {
        name: "vitalsSceneAsthma",
        events: {
            load: ["loadVitalsSceneAsthma"],
            play: ["playVitalsSceneAsthma"],
        }
    }, //end vitalsScene
    vitalsSceneDiabetes: {
        name: "vitalsSceneDiabetes",
        events: {
            load: ["loadVitalsSceneDiabetes"],
            play: ["playVitalsSceneDiabetes"],
            sumerianplay: ["Diabetes_Vitals"],
            sumerianLoad: "Diabetes_Vitals"
        }
    }, //end vitalsScene
    vitalsSceneHeartDisease: {
        name: "vitalsSceneHeartDisease",
        events: {
            load: ["loadVitalsSceneHeartDisease"],
            play: ["playVitalsSceneHeartDisease"],
            sumerianplay: [""],
            sumerianLoad: [""]
        }
    }, //end vitalsScene
}; //end sceneConfigs


export default class AmplifySceneLoader {
    constructor() {
        this.state = {
            sceneConfigs,
            // currentScene: sceneConfigs.introScene,
            currentScene: sceneConfigs.testTLSScene,
            loaded: false,
            configsLoaded: false,
            sceneController: null
        };

        this.sceneConfigs = sceneConfigs;
        // this.currentScene = sceneConfigs.introScene;
        this.currentScene = sceneConfigs.testTLSScene;

        autobind(this);
    }

    async loadConfigFromS3() {
        // let creds = await Auth.currentCredentials(); // eslint-disable-line no-unused-vars

        const s3 = new S3({
            apiVersion: '2006-03-01',
            credentials: autoCreds,
            region: "us-east-1"
        });
        const prefix = "scene_configs/";
        const lengthWithSlash = prefix.length;

        var params = {
            Bucket: 'addison-cdn',
            MaxKeys: 1000,
            Prefix: 'scene_configs'
        };

        let configs = {};

        try {
            const results = await s3.listObjectsV2(params).promise();

            const data = results.Contents;

            const objectKeys = data.map((obj) => obj.Key);

            logger.debug("objectKeys: ", objectKeys);

            objectKeys.forEach(async (key) => {
                try {
                    if (key === prefix) return; //skip the prefix ("empty") key

                    let keyWithoutPrefix = key.slice(lengthWithSlash);
                    const SUFFIX = ".json";
                    const SUFFIX_LENGTH = SUFFIX.length;
                    keyWithoutPrefix = keyWithoutPrefix.slice(0, keyWithoutPrefix.length - SUFFIX_LENGTH)

                    let getParams = {
                        Bucket: "addison-cdn",
                        Key: key
                    };

                    let obj = await s3.getObject(getParams).promise();

                    let config = obj.Body.toString('utf-8').replace(/\r?\n|\r/g, "");
                    config = JSON.parse(config);

                    configs[keyWithoutPrefix] = {
                        name: keyWithoutPrefix,
                        config: config,
                        events: {
                            play: "play" + keyWithoutPrefix.charAt(0).toUpperCase() + keyWithoutPrefix.slice(1),
                            load: "load" + keyWithoutPrefix.charAt(0).toUpperCase() + keyWithoutPrefix.slice(1),
                        }
                    };

                } catch (e) {
                    console.warn("error getting object from s3, error: ", e, e.stack);
                }

            });
        } catch (e) {
            console.warn("error listing objects from scene_configs folder in addison-cdn bucket, error: ", e, e.stack);
        }

        sceneConfigs = configs; //replace the hardcoded global reference
        this.sceneConfigs = configs;
        return configs;
    }


    async initListeners() {
        this.sceneConfigs = await this.loadConfigFromS3();

        this.state.userName = (await Auth.currentSession()).getIdToken().payload["cognito:username"];
         for (var sceneConfig in this.sceneConfigs) {
            let config = this.sceneConfigs[sceneConfig];

            Hub.listen(config.events.play, this.onHubCapsule);
            Hub.listen(config.events.load, this.onHubCapsule);
        }
        Hub.dispatch("sceneConfigsGenerated")
    }


    generateCallbacks() {
        let generatePlayCallback = (sceneConfig) => {
            return () => sceneConfig.events.play.forEach((event) => {
                this.sceneController.sumerian.SystemBus.emit(event, sceneConfig);
                Hub.dispatch(event); //also notify components of scene switch
            });
        };

        let generateLoadCallback = (sceneConfig) => {
            return () => sceneConfig.events.load.forEach((event) => {
                this.sceneController.sumerian.SystemBus.emit(event, sceneConfig)
                Hub.dispatch(event); //also notify components of scene load
            });
        }

        for (var sceneConfig in this.sceneConfigs) {
            let config = this.sceneConfigs[sceneConfig];

            config.playCallback = generatePlayCallback(config);
            config.loadCallback = generateLoadCallback(config);
        }
    }

    
    cleanupListeners() {
        for(var sceneConfig in sceneConfigs) {
            let config = this.sceneConfigs[sceneConfig];
            Hub.remove(config.events.play, this);
            Hub.remove(config.events.load, this);
        }
    }
    

    async updateSceneController() {
        this.state.sceneController = await XR.getSceneController(this.state.currentScene.name);
    }

    putSceneController(sceneController) {
        logger.debug("amplifySceneLoader has sceneController reference")
        this.state.sceneController = sceneController;
        this.sceneController = sceneController;
    }

    getSceneController() {
        return this.sceneController;
    }

    getCurrentScene() {
        return this.state.sceneConfig;
    }

    async dispatchPlay(sceneConfig) {
        this.state.userName = (await Auth.currentSession()).getIdToken().payload["cognito:username"];

        if(!this.state.configsLoaded) {
            this.sceneConfigs = await this.loadConfigFromS3();
        }

        logger.debug("in dispatchPlay, sceneConfig: ", sceneConfig);

        Hub.dispatch("UIReset");
        Hub.dispatch("disableDevMode");
        Hub.dispatch("hideAppInitialSidebar");
        Hub.dispatch("ShowBackgroundImage");
        Hub.dispatch("ShowLoadingBackground");
        Hub.dispatch("PlayingNewScene", sceneConfig);
        logger.debug("in dispatchPlay, sceneConfig: ", sceneConfig);

        this.state.currentScene = sceneConfig;
        this.currentScene = sceneConfig;
        if (this.state.currentScene.name === "helpScene") Hub.dispatch("loadHelpScene");
        if (this.state.currentScene.name === "medScene") {
            // giving medScene chance to load
            setTimeout(() => {this.state.sceneController.sumerian.SystemBus.emit("medList", sceneConfig.medList, true); }, 1000);
          
          delete sceneConfigs.medList; 
        } 

        try {
            this.sceneController.sumerian.SystemBus.emit(sceneConfig.events.play, sceneConfig);

            const user = await Auth.currentSession();
            logger.debug('user: ', user);
            setTimeout( () => this.sceneController.sumerian.SystemBus.emit('TokensGenerated', user), 1000);

            //TLS loaded, record that this user has engaged with the TLS
            const event = {
                name: "newSceneLoaded",
                attributes: {
                    name: this.state.userName,
                    sceneName: this.currentScene.name,
                }

            };

            Analytics.record(event);

            logger.debug("newSceneLoaded event: ", event)

            Hub.dispatch("hideAppInitialSidebar");

        } catch (e) {
            logger.debug("error in dispatchPlay, error: ", e);
        }
    }

    async dispatchLoad(sceneConfig) {
        logger.debug("in dispatchLoad, sceneConfig: ", sceneConfig);

        this.state.sceneController.sumerian.SystemBus.emit(sceneConfig.events.load, sceneConfig);
    }

    dispatchPlayMedScene(meds){
        if(sceneConfigs.medScene) {
            sceneConfigs.medScene.medList = meds;
            const config = sceneConfigs.medScene;
            logger.debug("medscene config : ", config);
            this.dispatchPlay(config);
        } else {
            // waiting for medlist key to be populated call function again. 
            setTimeout(()  => {this.dispatchPlayMedScene(meds);}, 100);
            
        }
    }

    dispatchPlayBingo() {
        const config = sceneConfigs.bingoScene;
        this.dispatchPlay(config);
    }

    dispatchPlayMedicationManagement() {
        const config = sceneConfigs.medicationManagementScene;
        logger.debug("in dispatchPlayMedicationManagement, config: ", config);
        this.dispatchPlay(config);

        Hub.dispatch("AppMedicationManagement", {
            event: "show",
            data: {},
            message: "showing app medication management"
        });
    }
    dispatchPlayCalibration(){
        const config = sceneConfigs.calibrationScene;
        this.dispatchPlay(config);        

        const calibrationEvent = {
            event: "playCalibration",
            data: {},
            message:"starting calibration scene"
        }

        Hub.dispatch("PlayCalibration", calibrationEvent);
        window.eventManager.emit("StartCalibration")
        Hub.dispatch("disableDevMode")
    }

    dispatchPlayIntro() {
        const config = sceneConfigs.introScene;
        this.dispatchPlay(config);
    }

    dispatchPlayCFRSI() {
        const config = sceneConfigs.gaitSceneCFRSI;
        this.dispatchPlay(config);
    }

    dispatchLoadCopingScene() {
        const config = sceneConfigs.copingScene;
        this.dispatchLoad(config);
    }

    dispatchLoadActivityMonitoringScene() {
        const config = sceneConfigs.activityMonitoringScene;
        this.dispatchLoad(config);
    }

    dispatchLoadNutritionScene() {
        const config = sceneConfigs.nutritionScene;
        this.dispatchLoad(config);
    }

    dispatchPlayCopingScene() {
        const config = sceneConfigs.copingScene;
        this.dispatchPlay(config);
    }

    dispatchPlayActivityMonitoringScene() {
        const config = sceneConfigs.activityMonitoringScene;
        this.dispatchPlay(config);
    }


    dispatchPlayNutritionScene() {
        const config = sceneConfigs.nutritionScene;
        this.dispatchPlay(config);
    }

    dispatchPlayGaitDiabetesScene() {
        const config = sceneConfigs.gaitSceneDiabetes;
        this.dispatchPlay(config);
        Hub.dispatch("playGaitSceneDiabetes");
    }

    dispatchPlayGaitHeartDiseaseScene() {
        const config = sceneConfigs.gaitSceneHeartDisease;
        this.dispatchPlay(config);
        Hub.dispatch("playGaitSceneHeartDisease");
    }
    dispatchPlayAsthmaVitals() {
        const config = this.sceneConfigs.vitalsScene;
        this.dispatchPlay(config);
    }

    dispatchPlayVitals() {
        const config = this.sceneConfigs.vitalsScene;
        logger.debug(`in window.amplifySceneLoader.dispatchPlayVitals, config: ${JSON.stringify(config, null, 2)} `)
        this.dispatchPlay(config);
    }

    dispatchPlayVitalsHistory() {
        const config = this.sceneConfigs.vitalsHistoryScene;
        logger.debug(`in window.amplifySceneLoader.dispatchPlayVitalsHistory, config: ${JSON.stringify(config, null, 2)} `)
        this.dispatchPlay(config);
    }

    dispatchPlayDiabetesVitals() {
        const config = sceneConfigs.vitalsSceneDiabetes;
        this.dispatchPlay(config);
    }

    dispatchPlayHeartDiseaseVitals() {
        const config = sceneConfigs.vitalsSceneHeartDisease;
        this.dispatchPlay(config);
    }

    dispatchPlayAsthmaMedReminder() {
        logger.debug("medAsthmaScene config ", sceneConfigs);
        const config = sceneConfigs.medSceneAsthma;
        this.dispatchPlay(config);
    }

    dispatchPlayDiabetesMedReminder() {
        const config = sceneConfigs.medSceneDiabetes;
        this.dispatchPlay(config);
    }

    dispatchPlayHeartDiseaseMedReminder() {
        const config = sceneConfigs.medSceneHeartDisease;
        this.dispatchPlay(config);
    }

    dispatchPlayHelpScene() {
        const config = sceneConfigs.helpScene;
        this.dispatchPlay(config);
    }

    async loadScene(sceneConfig) {
        await XR.loadScene(sceneConfig.name, sceneConfig.domId);
    }

    async playScene(sceneName) {
        await XR.start(sceneName);
    }

    async onHubCapsule(capsule) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        logger.debug(`in AmplifySceneLoader.onHubCapsule, channel: ${channel}, payload: ${JSON.stringify(payload, null, 2)}`);

        for (var sceneConfig in this.sceneConfigs) {
            let config = this.sceneConfigs[sceneConfig];

            if (channel === config.events.load) {
                this.dispatchLoad(config);
            }

            if (channel === config.events.play) {
                this.dispatchPlay(config);
            }
        }
    }

    getConfig(sceneKey) {
        return this.sceneConfigs[sceneKey];
    }

    async getSceneConfigs() {
        return await this.loadConfigFromS3();
        // return this.sceneConfigs;
    }
} //end AmplifySceneLoader

async function getSceneConfigs() {
    return await window.amplifySceneLoader.getSceneConfigs();
}

export { sceneConfigs, getSceneConfigs };

// window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();a
