//AppSidebarContainer.jsx

import React from 'react';
import { Hub } from 'aws-amplify';
import { AppInitialSidebar, AppHealthSidebar, /*AppAboutPrompt,*/ AppRewardsSidebar, AppCommunicationsSidebar, AppMedicationsSidebar, AppVitalsSidebar } from '../components';
import autobind from 'class-autobind';

export default class AppSidebarContainer extends React.Component {
	getInitialState() {
		return {
			initialSidebarOn: false,
			healthSidebarOn: false,
			communicationsSidebarOn: false,
			vitalsSidebarOn: false,
			medicationsSidebarOn: false,
			rewardSidebarOn: false,
		}
	}

	resetSidebars(sidebarToEnable) {
		this.setState(this.getInitialState());

		switch(sidebarToEnable) {
			case "AppInitialSidebar":
				this.setState({initialSidebarOn: true});
				break;
			case "AppHealthSidebar":
				this.setState({initialSidebarOn: false, healthSidebarOn: true});
				break;
			case "AppCommunicationsSidebar":
				this.setState({initialSidebarOn: false, communicationsSidebarOn: true});
				break;
			case "AppRewardsSidebar":
				this.setState({initialSidebarOn: false, rewardSidebarOn: true});
				break;
			case "AppMedicationsSidebar":
				this.setState({initialSidebarOn: false, medicationsSidebarOn: true});
				break;
			case "AppVitalsSidebar":
				this.setState({initialSidebarOn: false, vitalsSidebarOn: true});
				break;
			default:
				console.warn("in resetSidebars, should never hit default case...");
		}
	}

	constructor(props) {
		super(props);
		autobind(this);

		this.state = this.getInitialState();

		//create listeners for events relevant to this component/container
		Hub.listen("showAppInitialSidebar", (data) => {
			this.resetSidebars("AppInitialSidebar");
		})

		Hub.listen("hideAppInitialSidebar", (data) => {
			this.resetSidebars("AppInitialSidebar"); //hide all the others
			this.setState({initialSidebarOn: false}); //then hide the initial
		})

		Hub.listen("showAppCommunicationsSidebar", (data) => this.resetSidebars("AppCommunicationsSidebar"));

		Hub.listen("showAppHealthSidebar", (data) => this.resetSidebars("AppHealthSidebar"));

		Hub.listen("showAppCommunicationsSidebar", (data) => this.resetSidebars("AppCommunicationsSidebar"));

		Hub.listen("showAppRewardsSidebar", (data) => this.resetSidebars("AppRewardsSidebar"));

		Hub.listen("showAppVitalsSidebar", (data) => this.resetSidebars("AppVitalsSidebar"));

		Hub.listen("showAppMedicationsSidebar", (data) => this.resetSidebars("AppMedicationsSidebar"));

		//reset your container's state on UIReset channel events
		Hub.listen("UIReset", (data) => this.setState(this.getInitialState()));
	}

	render() {
		return (
		<React.Fragment>
			{/* Sidebars */}			
	        <AppInitialSidebar on={this.state.initialSidebarOn} /> 
	        <AppHealthSidebar on={this.state.healthSidebarOn} />
	        <AppCommunicationsSidebar on={this.state.communicationsSidebarOn} />
	        <AppRewardsSidebar on={this.state.rewardSidebarOn} />
	        <AppMedicationsSidebar on={this.state.medicationsSidebarOn} />
	        <AppVitalsSidebar on={this.state.vitalsSidebarOn} />
        </React.Fragment>
        )
	}

}