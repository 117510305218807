// eslint-disable
// this is an auto generated file. This will be overwritten

export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    userName
    localOwner
    groups
    firstRun
    isDeveloper
    devices {
      items {
        id
        localOwner
        xmit
        rapidPhoneNumber
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        peripherals {
          items {
            id
            localOwner
            name
            deviceType
          }
          nextToken
        }
      }
      nextToken
    }
    readings {
      items {
        id
        localOwner
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        timestamp
        reading
      }
      nextToken
    }
    events {
      items {
        id
        localOwner
        Account
        TimeStamp
        AlarmCode
        EventCode
        Event
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        Longitude
        Latitude
      }
      nextToken
    }
    userProfile {
      id
      localOwner
      userName
      user {
        id
        userName
        localOwner
        groups
        firstRun
        isDeveloper
        devices {
          items {
            id
            localOwner
            xmit
            rapidPhoneNumber
          }
          nextToken
        }
        readings {
          items {
            id
            localOwner
            timestamp
            reading
          }
          nextToken
        }
        events {
          items {
            id
            localOwner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        userProfile {
          id
          localOwner
          userName
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          firstName
          lastName
          preferredName
          weight
          height
          medicationComplianceStreak
          numMedications
          medications {
            nextToken
          }
        }
        responsibleParties {
          items {
            id
            localOwner
            phone
          }
          nextToken
        }
        scheduleReminders {
          items {
            id
            localOwner
            timestamp
            medName
            medDose
            lastTaken
            type
          }
          nextToken
        }
        carePlan {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          thresholds {
            nextToken
          }
          vitalTypes
        }
        lexInteractions {
          items {
            id
            localOwner
            inputTranscript
            timestamp
            botName
            botAlias
            slots
            requestAttributes
          }
          nextToken
        }
      }
      firstName
      lastName
      preferredName
      weight
      height
      medicationComplianceStreak
      numMedications
      medications {
        items {
          id
          localOwner
          name
          dosage
          reminderTime
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
        }
        nextToken
      }
    }
    responsibleParties {
      items {
        id
        localOwner
        phone
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
      }
      nextToken
    }
    scheduleReminders {
      items {
        id
        localOwner
        timestamp
        medName
        medDose
        lastTaken
        type
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
      }
      nextToken
    }
    carePlan {
      id
      localOwner
      user {
        id
        userName
        localOwner
        groups
        firstRun
        isDeveloper
        devices {
          items {
            id
            localOwner
            xmit
            rapidPhoneNumber
          }
          nextToken
        }
        readings {
          items {
            id
            localOwner
            timestamp
            reading
          }
          nextToken
        }
        events {
          items {
            id
            localOwner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        userProfile {
          id
          localOwner
          userName
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          firstName
          lastName
          preferredName
          weight
          height
          medicationComplianceStreak
          numMedications
          medications {
            nextToken
          }
        }
        responsibleParties {
          items {
            id
            localOwner
            phone
          }
          nextToken
        }
        scheduleReminders {
          items {
            id
            localOwner
            timestamp
            medName
            medDose
            lastTaken
            type
          }
          nextToken
        }
        carePlan {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          thresholds {
            nextToken
          }
          vitalTypes
        }
        lexInteractions {
          items {
            id
            localOwner
            inputTranscript
            timestamp
            botName
            botAlias
            slots
            requestAttributes
          }
          nextToken
        }
      }
      thresholds {
        items {
          id
          localOwner
          carePlan {
            id
            localOwner
            vitalTypes
          }
          name
          minimum
          maximum
        }
        nextToken
      }
      vitalTypes
    }
    lexInteractions {
      items {
        id
        localOwner
        inputTranscript
        timestamp
        botName
        botAlias
        slots
        requestAttributes
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userName
      localOwner
      groups
      firstRun
      isDeveloper
      devices {
        items {
          id
          localOwner
          xmit
          rapidPhoneNumber
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          peripherals {
            nextToken
          }
        }
        nextToken
      }
      readings {
        items {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          timestamp
          reading
        }
        nextToken
      }
      events {
        items {
          id
          localOwner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          Longitude
          Latitude
        }
        nextToken
      }
      userProfile {
        id
        localOwner
        userName
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        firstName
        lastName
        preferredName
        weight
        height
        medicationComplianceStreak
        numMedications
        medications {
          items {
            id
            localOwner
            name
            dosage
            reminderTime
          }
          nextToken
        }
      }
      responsibleParties {
        items {
          id
          localOwner
          phone
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      scheduleReminders {
        items {
          id
          localOwner
          timestamp
          medName
          medDose
          lastTaken
          type
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      carePlan {
        id
        localOwner
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        thresholds {
          items {
            id
            localOwner
            name
            minimum
            maximum
          }
          nextToken
        }
        vitalTypes
      }
      lexInteractions {
        items {
          id
          localOwner
          inputTranscript
          timestamp
          botName
          botAlias
          slots
          requestAttributes
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getThreshold = `query GetThreshold($id: ID!) {
  getThreshold(id: $id) {
    id
    localOwner
    carePlan {
      id
      localOwner
      user {
        id
        userName
        localOwner
        groups
        firstRun
        isDeveloper
        devices {
          items {
            id
            localOwner
            xmit
            rapidPhoneNumber
          }
          nextToken
        }
        readings {
          items {
            id
            localOwner
            timestamp
            reading
          }
          nextToken
        }
        events {
          items {
            id
            localOwner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        userProfile {
          id
          localOwner
          userName
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          firstName
          lastName
          preferredName
          weight
          height
          medicationComplianceStreak
          numMedications
          medications {
            nextToken
          }
        }
        responsibleParties {
          items {
            id
            localOwner
            phone
          }
          nextToken
        }
        scheduleReminders {
          items {
            id
            localOwner
            timestamp
            medName
            medDose
            lastTaken
            type
          }
          nextToken
        }
        carePlan {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          thresholds {
            nextToken
          }
          vitalTypes
        }
        lexInteractions {
          items {
            id
            localOwner
            inputTranscript
            timestamp
            botName
            botAlias
            slots
            requestAttributes
          }
          nextToken
        }
      }
      thresholds {
        items {
          id
          localOwner
          carePlan {
            id
            localOwner
            vitalTypes
          }
          name
          minimum
          maximum
        }
        nextToken
      }
      vitalTypes
    }
    name
    minimum
    maximum
  }
}
`;
export const listThresholds = `query ListThresholds(
  $filter: ModelThresholdFilterInput
  $limit: Int
  $nextToken: String
) {
  listThresholds(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      localOwner
      carePlan {
        id
        localOwner
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        thresholds {
          items {
            id
            localOwner
            name
            minimum
            maximum
          }
          nextToken
        }
        vitalTypes
      }
      name
      minimum
      maximum
    }
    nextToken
  }
}
`;
export const getLexInteraction = `query GetLexInteraction($id: ID!) {
  getLexInteraction(id: $id) {
    id
    localOwner
    inputTranscript
    timestamp
    botName
    botAlias
    slots
    requestAttributes
    user {
      id
      userName
      localOwner
      groups
      firstRun
      isDeveloper
      devices {
        items {
          id
          localOwner
          xmit
          rapidPhoneNumber
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          peripherals {
            nextToken
          }
        }
        nextToken
      }
      readings {
        items {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          timestamp
          reading
        }
        nextToken
      }
      events {
        items {
          id
          localOwner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          Longitude
          Latitude
        }
        nextToken
      }
      userProfile {
        id
        localOwner
        userName
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        firstName
        lastName
        preferredName
        weight
        height
        medicationComplianceStreak
        numMedications
        medications {
          items {
            id
            localOwner
            name
            dosage
            reminderTime
          }
          nextToken
        }
      }
      responsibleParties {
        items {
          id
          localOwner
          phone
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      scheduleReminders {
        items {
          id
          localOwner
          timestamp
          medName
          medDose
          lastTaken
          type
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      carePlan {
        id
        localOwner
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        thresholds {
          items {
            id
            localOwner
            name
            minimum
            maximum
          }
          nextToken
        }
        vitalTypes
      }
      lexInteractions {
        items {
          id
          localOwner
          inputTranscript
          timestamp
          botName
          botAlias
          slots
          requestAttributes
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
    }
  }
}
`;
export const listLexInteractions = `query ListLexInteractions(
  $filter: ModelLexInteractionFilterInput
  $limit: Int
  $nextToken: String
) {
  listLexInteractions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      localOwner
      inputTranscript
      timestamp
      botName
      botAlias
      slots
      requestAttributes
      user {
        id
        userName
        localOwner
        groups
        firstRun
        isDeveloper
        devices {
          items {
            id
            localOwner
            xmit
            rapidPhoneNumber
          }
          nextToken
        }
        readings {
          items {
            id
            localOwner
            timestamp
            reading
          }
          nextToken
        }
        events {
          items {
            id
            localOwner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        userProfile {
          id
          localOwner
          userName
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          firstName
          lastName
          preferredName
          weight
          height
          medicationComplianceStreak
          numMedications
          medications {
            nextToken
          }
        }
        responsibleParties {
          items {
            id
            localOwner
            phone
          }
          nextToken
        }
        scheduleReminders {
          items {
            id
            localOwner
            timestamp
            medName
            medDose
            lastTaken
            type
          }
          nextToken
        }
        carePlan {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          thresholds {
            nextToken
          }
          vitalTypes
        }
        lexInteractions {
          items {
            id
            localOwner
            inputTranscript
            timestamp
            botName
            botAlias
            slots
            requestAttributes
          }
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const getActivityEvent = `query GetActivityEvent($id: ID!) {
  getActivityEvent(id: $id) {
    id
    localOwner
  }
}
`;
export const listActivityEvents = `query ListActivityEvents(
  $filter: ModelActivityEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listActivityEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      localOwner
    }
    nextToken
  }
}
`;
export const getMissedMedicationReminderEvent = `query GetMissedMedicationReminderEvent($id: ID!) {
  getMissedMedicationReminderEvent(id: $id) {
    id
    localOwner
  }
}
`;
export const listMissedMedicationReminderEvents = `query ListMissedMedicationReminderEvents(
  $filter: ModelMissedMedicationReminderEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listMissedMedicationReminderEvents(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      localOwner
    }
    nextToken
  }
}
`;
export const getCarePlan = `query GetCarePlan($id: ID!) {
  getCarePlan(id: $id) {
    id
    localOwner
    user {
      id
      userName
      localOwner
      groups
      firstRun
      isDeveloper
      devices {
        items {
          id
          localOwner
          xmit
          rapidPhoneNumber
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          peripherals {
            nextToken
          }
        }
        nextToken
      }
      readings {
        items {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          timestamp
          reading
        }
        nextToken
      }
      events {
        items {
          id
          localOwner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          Longitude
          Latitude
        }
        nextToken
      }
      userProfile {
        id
        localOwner
        userName
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        firstName
        lastName
        preferredName
        weight
        height
        medicationComplianceStreak
        numMedications
        medications {
          items {
            id
            localOwner
            name
            dosage
            reminderTime
          }
          nextToken
        }
      }
      responsibleParties {
        items {
          id
          localOwner
          phone
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      scheduleReminders {
        items {
          id
          localOwner
          timestamp
          medName
          medDose
          lastTaken
          type
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      carePlan {
        id
        localOwner
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        thresholds {
          items {
            id
            localOwner
            name
            minimum
            maximum
          }
          nextToken
        }
        vitalTypes
      }
      lexInteractions {
        items {
          id
          localOwner
          inputTranscript
          timestamp
          botName
          botAlias
          slots
          requestAttributes
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
    }
    thresholds {
      items {
        id
        localOwner
        carePlan {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          thresholds {
            nextToken
          }
          vitalTypes
        }
        name
        minimum
        maximum
      }
      nextToken
    }
    vitalTypes
  }
}
`;
export const listCarePlans = `query ListCarePlans(
  $filter: ModelCarePlanFilterInput
  $limit: Int
  $nextToken: String
) {
  listCarePlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      localOwner
      user {
        id
        userName
        localOwner
        groups
        firstRun
        isDeveloper
        devices {
          items {
            id
            localOwner
            xmit
            rapidPhoneNumber
          }
          nextToken
        }
        readings {
          items {
            id
            localOwner
            timestamp
            reading
          }
          nextToken
        }
        events {
          items {
            id
            localOwner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        userProfile {
          id
          localOwner
          userName
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          firstName
          lastName
          preferredName
          weight
          height
          medicationComplianceStreak
          numMedications
          medications {
            nextToken
          }
        }
        responsibleParties {
          items {
            id
            localOwner
            phone
          }
          nextToken
        }
        scheduleReminders {
          items {
            id
            localOwner
            timestamp
            medName
            medDose
            lastTaken
            type
          }
          nextToken
        }
        carePlan {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          thresholds {
            nextToken
          }
          vitalTypes
        }
        lexInteractions {
          items {
            id
            localOwner
            inputTranscript
            timestamp
            botName
            botAlias
            slots
            requestAttributes
          }
          nextToken
        }
      }
      thresholds {
        items {
          id
          localOwner
          carePlan {
            id
            localOwner
            vitalTypes
          }
          name
          minimum
          maximum
        }
        nextToken
      }
      vitalTypes
    }
    nextToken
  }
}
`;
export const getResponsibleParty = `query GetResponsibleParty($id: ID!) {
  getResponsibleParty(id: $id) {
    id
    localOwner
    phone
    user {
      id
      userName
      localOwner
      groups
      firstRun
      isDeveloper
      devices {
        items {
          id
          localOwner
          xmit
          rapidPhoneNumber
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          peripherals {
            nextToken
          }
        }
        nextToken
      }
      readings {
        items {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          timestamp
          reading
        }
        nextToken
      }
      events {
        items {
          id
          localOwner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          Longitude
          Latitude
        }
        nextToken
      }
      userProfile {
        id
        localOwner
        userName
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        firstName
        lastName
        preferredName
        weight
        height
        medicationComplianceStreak
        numMedications
        medications {
          items {
            id
            localOwner
            name
            dosage
            reminderTime
          }
          nextToken
        }
      }
      responsibleParties {
        items {
          id
          localOwner
          phone
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      scheduleReminders {
        items {
          id
          localOwner
          timestamp
          medName
          medDose
          lastTaken
          type
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      carePlan {
        id
        localOwner
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        thresholds {
          items {
            id
            localOwner
            name
            minimum
            maximum
          }
          nextToken
        }
        vitalTypes
      }
      lexInteractions {
        items {
          id
          localOwner
          inputTranscript
          timestamp
          botName
          botAlias
          slots
          requestAttributes
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
    }
  }
}
`;
export const listResponsiblePartys = `query ListResponsiblePartys(
  $filter: ModelResponsiblePartyFilterInput
  $limit: Int
  $nextToken: String
) {
  listResponsiblePartys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      localOwner
      phone
      user {
        id
        userName
        localOwner
        groups
        firstRun
        isDeveloper
        devices {
          items {
            id
            localOwner
            xmit
            rapidPhoneNumber
          }
          nextToken
        }
        readings {
          items {
            id
            localOwner
            timestamp
            reading
          }
          nextToken
        }
        events {
          items {
            id
            localOwner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        userProfile {
          id
          localOwner
          userName
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          firstName
          lastName
          preferredName
          weight
          height
          medicationComplianceStreak
          numMedications
          medications {
            nextToken
          }
        }
        responsibleParties {
          items {
            id
            localOwner
            phone
          }
          nextToken
        }
        scheduleReminders {
          items {
            id
            localOwner
            timestamp
            medName
            medDose
            lastTaken
            type
          }
          nextToken
        }
        carePlan {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          thresholds {
            nextToken
          }
          vitalTypes
        }
        lexInteractions {
          items {
            id
            localOwner
            inputTranscript
            timestamp
            botName
            botAlias
            slots
            requestAttributes
          }
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const getUserProfile = `query GetUserProfile($id: ID!) {
  getUserProfile(id: $id) {
    id
    localOwner
    userName
    user {
      id
      userName
      localOwner
      groups
      firstRun
      isDeveloper
      devices {
        items {
          id
          localOwner
          xmit
          rapidPhoneNumber
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          peripherals {
            nextToken
          }
        }
        nextToken
      }
      readings {
        items {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          timestamp
          reading
        }
        nextToken
      }
      events {
        items {
          id
          localOwner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          Longitude
          Latitude
        }
        nextToken
      }
      userProfile {
        id
        localOwner
        userName
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        firstName
        lastName
        preferredName
        weight
        height
        medicationComplianceStreak
        numMedications
        medications {
          items {
            id
            localOwner
            name
            dosage
            reminderTime
          }
          nextToken
        }
      }
      responsibleParties {
        items {
          id
          localOwner
          phone
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      scheduleReminders {
        items {
          id
          localOwner
          timestamp
          medName
          medDose
          lastTaken
          type
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      carePlan {
        id
        localOwner
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        thresholds {
          items {
            id
            localOwner
            name
            minimum
            maximum
          }
          nextToken
        }
        vitalTypes
      }
      lexInteractions {
        items {
          id
          localOwner
          inputTranscript
          timestamp
          botName
          botAlias
          slots
          requestAttributes
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
    }
    firstName
    lastName
    preferredName
    weight
    height
    medicationComplianceStreak
    numMedications
    medications {
      items {
        id
        localOwner
        name
        dosage
        reminderTime
        userProfile {
          id
          localOwner
          userName
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          firstName
          lastName
          preferredName
          weight
          height
          medicationComplianceStreak
          numMedications
          medications {
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const listUserProfiles = `query ListUserProfiles(
  $filter: ModelUserProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      localOwner
      userName
      user {
        id
        userName
        localOwner
        groups
        firstRun
        isDeveloper
        devices {
          items {
            id
            localOwner
            xmit
            rapidPhoneNumber
          }
          nextToken
        }
        readings {
          items {
            id
            localOwner
            timestamp
            reading
          }
          nextToken
        }
        events {
          items {
            id
            localOwner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        userProfile {
          id
          localOwner
          userName
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          firstName
          lastName
          preferredName
          weight
          height
          medicationComplianceStreak
          numMedications
          medications {
            nextToken
          }
        }
        responsibleParties {
          items {
            id
            localOwner
            phone
          }
          nextToken
        }
        scheduleReminders {
          items {
            id
            localOwner
            timestamp
            medName
            medDose
            lastTaken
            type
          }
          nextToken
        }
        carePlan {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          thresholds {
            nextToken
          }
          vitalTypes
        }
        lexInteractions {
          items {
            id
            localOwner
            inputTranscript
            timestamp
            botName
            botAlias
            slots
            requestAttributes
          }
          nextToken
        }
      }
      firstName
      lastName
      preferredName
      weight
      height
      medicationComplianceStreak
      numMedications
      medications {
        items {
          id
          localOwner
          name
          dosage
          reminderTime
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getAneltoEvents = `query GetAneltoEvents($id: ID!) {
  getAneltoEvents(id: $id) {
    id
    userEventsId
    Account
    BPM
    BPMHigh
    BPMLow
    Battery
    BloodPressure
    BlueId
    DeviceNumber
    Diastolic
    Event
    EventCode
    Glucose
    HeartRate
    Id
    PulseAvg
    PulseHigh
    PulseLow
    PulseOx
    RawData
    Systolic
    Thermometer
    TimeStamp
    Timestamp
    Units
    User
    UserId
    Value
    Vital
    Weight
  }
}
`;
export const listAneltoEventss = `query ListAneltoEventss(
  $filter: ModelAneltoEventsFilterInput
  $limit: Int
  $nextToken: String
) {
  listAneltoEventss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userEventsId
      Account
      BPM
      BPMHigh
      BPMLow
      Battery
      BloodPressure
      BlueId
      DeviceNumber
      Diastolic
      Event
      EventCode
      Glucose
      HeartRate
      Id
      PulseAvg
      PulseHigh
      PulseLow
      PulseOx
      RawData
      Systolic
      Thermometer
      TimeStamp
      Timestamp
      Units
      User
      UserId
      Value
      Vital
      Weight
    }
    nextToken
  }
}
`;
export const getMedication = `query GetMedication($id: ID!) {
  getMedication(id: $id) {
    id
    localOwner
    name
    dosage
    reminderTime
    userProfile {
      id
      localOwner
      userName
      user {
        id
        userName
        localOwner
        groups
        firstRun
        isDeveloper
        devices {
          items {
            id
            localOwner
            xmit
            rapidPhoneNumber
          }
          nextToken
        }
        readings {
          items {
            id
            localOwner
            timestamp
            reading
          }
          nextToken
        }
        events {
          items {
            id
            localOwner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        userProfile {
          id
          localOwner
          userName
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          firstName
          lastName
          preferredName
          weight
          height
          medicationComplianceStreak
          numMedications
          medications {
            nextToken
          }
        }
        responsibleParties {
          items {
            id
            localOwner
            phone
          }
          nextToken
        }
        scheduleReminders {
          items {
            id
            localOwner
            timestamp
            medName
            medDose
            lastTaken
            type
          }
          nextToken
        }
        carePlan {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          thresholds {
            nextToken
          }
          vitalTypes
        }
        lexInteractions {
          items {
            id
            localOwner
            inputTranscript
            timestamp
            botName
            botAlias
            slots
            requestAttributes
          }
          nextToken
        }
      }
      firstName
      lastName
      preferredName
      weight
      height
      medicationComplianceStreak
      numMedications
      medications {
        items {
          id
          localOwner
          name
          dosage
          reminderTime
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
        }
        nextToken
      }
    }
  }
}
`;
export const listMedications = `query ListMedications(
  $filter: ModelMedicationFilterInput
  $limit: Int
  $nextToken: String
) {
  listMedications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      localOwner
      name
      dosage
      reminderTime
      userProfile {
        id
        localOwner
        userName
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        firstName
        lastName
        preferredName
        weight
        height
        medicationComplianceStreak
        numMedications
        medications {
          items {
            id
            localOwner
            name
            dosage
            reminderTime
          }
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const getScheduleReminder = `query GetScheduleReminder($id: ID!) {
  getScheduleReminder(id: $id) {
    id
    localOwner
    timestamp
    medName
    medDose
    lastTaken
    type
    user {
      id
      userName
      localOwner
      groups
      firstRun
      isDeveloper
      devices {
        items {
          id
          localOwner
          xmit
          rapidPhoneNumber
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          peripherals {
            nextToken
          }
        }
        nextToken
      }
      readings {
        items {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          timestamp
          reading
        }
        nextToken
      }
      events {
        items {
          id
          localOwner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          Longitude
          Latitude
        }
        nextToken
      }
      userProfile {
        id
        localOwner
        userName
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        firstName
        lastName
        preferredName
        weight
        height
        medicationComplianceStreak
        numMedications
        medications {
          items {
            id
            localOwner
            name
            dosage
            reminderTime
          }
          nextToken
        }
      }
      responsibleParties {
        items {
          id
          localOwner
          phone
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      scheduleReminders {
        items {
          id
          localOwner
          timestamp
          medName
          medDose
          lastTaken
          type
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      carePlan {
        id
        localOwner
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        thresholds {
          items {
            id
            localOwner
            name
            minimum
            maximum
          }
          nextToken
        }
        vitalTypes
      }
      lexInteractions {
        items {
          id
          localOwner
          inputTranscript
          timestamp
          botName
          botAlias
          slots
          requestAttributes
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
    }
  }
}
`;
export const listScheduleReminders = `query ListScheduleReminders(
  $filter: ModelScheduleReminderFilterInput
  $limit: Int
  $nextToken: String
) {
  listScheduleReminders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      localOwner
      timestamp
      medName
      medDose
      lastTaken
      type
      user {
        id
        userName
        localOwner
        groups
        firstRun
        isDeveloper
        devices {
          items {
            id
            localOwner
            xmit
            rapidPhoneNumber
          }
          nextToken
        }
        readings {
          items {
            id
            localOwner
            timestamp
            reading
          }
          nextToken
        }
        events {
          items {
            id
            localOwner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        userProfile {
          id
          localOwner
          userName
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          firstName
          lastName
          preferredName
          weight
          height
          medicationComplianceStreak
          numMedications
          medications {
            nextToken
          }
        }
        responsibleParties {
          items {
            id
            localOwner
            phone
          }
          nextToken
        }
        scheduleReminders {
          items {
            id
            localOwner
            timestamp
            medName
            medDose
            lastTaken
            type
          }
          nextToken
        }
        carePlan {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          thresholds {
            nextToken
          }
          vitalTypes
        }
        lexInteractions {
          items {
            id
            localOwner
            inputTranscript
            timestamp
            botName
            botAlias
            slots
            requestAttributes
          }
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const getPeripheral = `query GetPeripheral($id: ID!) {
  getPeripheral(id: $id) {
    id
    localOwner
    name
    deviceType
    device {
      id
      localOwner
      xmit
      rapidPhoneNumber
      user {
        id
        userName
        localOwner
        groups
        firstRun
        isDeveloper
        devices {
          items {
            id
            localOwner
            xmit
            rapidPhoneNumber
          }
          nextToken
        }
        readings {
          items {
            id
            localOwner
            timestamp
            reading
          }
          nextToken
        }
        events {
          items {
            id
            localOwner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        userProfile {
          id
          localOwner
          userName
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          firstName
          lastName
          preferredName
          weight
          height
          medicationComplianceStreak
          numMedications
          medications {
            nextToken
          }
        }
        responsibleParties {
          items {
            id
            localOwner
            phone
          }
          nextToken
        }
        scheduleReminders {
          items {
            id
            localOwner
            timestamp
            medName
            medDose
            lastTaken
            type
          }
          nextToken
        }
        carePlan {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          thresholds {
            nextToken
          }
          vitalTypes
        }
        lexInteractions {
          items {
            id
            localOwner
            inputTranscript
            timestamp
            botName
            botAlias
            slots
            requestAttributes
          }
          nextToken
        }
      }
      peripherals {
        items {
          id
          localOwner
          name
          deviceType
          device {
            id
            localOwner
            xmit
            rapidPhoneNumber
          }
        }
        nextToken
      }
    }
  }
}
`;
export const listPeripherals = `query ListPeripherals(
  $filter: ModelPeripheralFilterInput
  $limit: Int
  $nextToken: String
) {
  listPeripherals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      localOwner
      name
      deviceType
      device {
        id
        localOwner
        xmit
        rapidPhoneNumber
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        peripherals {
          items {
            id
            localOwner
            name
            deviceType
          }
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const getDevice = `query GetDevice($id: ID!) {
  getDevice(id: $id) {
    id
    localOwner
    xmit
    rapidPhoneNumber
    user {
      id
      userName
      localOwner
      groups
      firstRun
      isDeveloper
      devices {
        items {
          id
          localOwner
          xmit
          rapidPhoneNumber
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          peripherals {
            nextToken
          }
        }
        nextToken
      }
      readings {
        items {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          timestamp
          reading
        }
        nextToken
      }
      events {
        items {
          id
          localOwner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          Longitude
          Latitude
        }
        nextToken
      }
      userProfile {
        id
        localOwner
        userName
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        firstName
        lastName
        preferredName
        weight
        height
        medicationComplianceStreak
        numMedications
        medications {
          items {
            id
            localOwner
            name
            dosage
            reminderTime
          }
          nextToken
        }
      }
      responsibleParties {
        items {
          id
          localOwner
          phone
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      scheduleReminders {
        items {
          id
          localOwner
          timestamp
          medName
          medDose
          lastTaken
          type
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      carePlan {
        id
        localOwner
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        thresholds {
          items {
            id
            localOwner
            name
            minimum
            maximum
          }
          nextToken
        }
        vitalTypes
      }
      lexInteractions {
        items {
          id
          localOwner
          inputTranscript
          timestamp
          botName
          botAlias
          slots
          requestAttributes
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
    }
    peripherals {
      items {
        id
        localOwner
        name
        deviceType
        device {
          id
          localOwner
          xmit
          rapidPhoneNumber
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          peripherals {
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const listDevices = `query ListDevices(
  $filter: ModelDeviceFilterInput
  $limit: Int
  $nextToken: String
) {
  listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      localOwner
      xmit
      rapidPhoneNumber
      user {
        id
        userName
        localOwner
        groups
        firstRun
        isDeveloper
        devices {
          items {
            id
            localOwner
            xmit
            rapidPhoneNumber
          }
          nextToken
        }
        readings {
          items {
            id
            localOwner
            timestamp
            reading
          }
          nextToken
        }
        events {
          items {
            id
            localOwner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        userProfile {
          id
          localOwner
          userName
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          firstName
          lastName
          preferredName
          weight
          height
          medicationComplianceStreak
          numMedications
          medications {
            nextToken
          }
        }
        responsibleParties {
          items {
            id
            localOwner
            phone
          }
          nextToken
        }
        scheduleReminders {
          items {
            id
            localOwner
            timestamp
            medName
            medDose
            lastTaken
            type
          }
          nextToken
        }
        carePlan {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          thresholds {
            nextToken
          }
          vitalTypes
        }
        lexInteractions {
          items {
            id
            localOwner
            inputTranscript
            timestamp
            botName
            botAlias
            slots
            requestAttributes
          }
          nextToken
        }
      }
      peripherals {
        items {
          id
          localOwner
          name
          deviceType
          device {
            id
            localOwner
            xmit
            rapidPhoneNumber
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getReading = `query GetReading($id: ID!) {
  getReading(id: $id) {
    id
    localOwner
    user {
      id
      userName
      localOwner
      groups
      firstRun
      isDeveloper
      devices {
        items {
          id
          localOwner
          xmit
          rapidPhoneNumber
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          peripherals {
            nextToken
          }
        }
        nextToken
      }
      readings {
        items {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          timestamp
          reading
        }
        nextToken
      }
      events {
        items {
          id
          localOwner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          Longitude
          Latitude
        }
        nextToken
      }
      userProfile {
        id
        localOwner
        userName
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        firstName
        lastName
        preferredName
        weight
        height
        medicationComplianceStreak
        numMedications
        medications {
          items {
            id
            localOwner
            name
            dosage
            reminderTime
          }
          nextToken
        }
      }
      responsibleParties {
        items {
          id
          localOwner
          phone
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      scheduleReminders {
        items {
          id
          localOwner
          timestamp
          medName
          medDose
          lastTaken
          type
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      carePlan {
        id
        localOwner
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        thresholds {
          items {
            id
            localOwner
            name
            minimum
            maximum
          }
          nextToken
        }
        vitalTypes
      }
      lexInteractions {
        items {
          id
          localOwner
          inputTranscript
          timestamp
          botName
          botAlias
          slots
          requestAttributes
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
    }
    timestamp
    reading
  }
}
`;
export const listReadings = `query ListReadings(
  $filter: ModelReadingFilterInput
  $limit: Int
  $nextToken: String
) {
  listReadings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      localOwner
      user {
        id
        userName
        localOwner
        groups
        firstRun
        isDeveloper
        devices {
          items {
            id
            localOwner
            xmit
            rapidPhoneNumber
          }
          nextToken
        }
        readings {
          items {
            id
            localOwner
            timestamp
            reading
          }
          nextToken
        }
        events {
          items {
            id
            localOwner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        userProfile {
          id
          localOwner
          userName
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          firstName
          lastName
          preferredName
          weight
          height
          medicationComplianceStreak
          numMedications
          medications {
            nextToken
          }
        }
        responsibleParties {
          items {
            id
            localOwner
            phone
          }
          nextToken
        }
        scheduleReminders {
          items {
            id
            localOwner
            timestamp
            medName
            medDose
            lastTaken
            type
          }
          nextToken
        }
        carePlan {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          thresholds {
            nextToken
          }
          vitalTypes
        }
        lexInteractions {
          items {
            id
            localOwner
            inputTranscript
            timestamp
            botName
            botAlias
            slots
            requestAttributes
          }
          nextToken
        }
      }
      timestamp
      reading
    }
    nextToken
  }
}
`;
export const getRapidEvent = `query GetRapidEvent($id: ID!) {
  getRapidEvent(id: $id) {
    id
    localOwner
    Account
    TimeStamp
    AlarmCode
    EventCode
    Event
    user {
      id
      userName
      localOwner
      groups
      firstRun
      isDeveloper
      devices {
        items {
          id
          localOwner
          xmit
          rapidPhoneNumber
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          peripherals {
            nextToken
          }
        }
        nextToken
      }
      readings {
        items {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          timestamp
          reading
        }
        nextToken
      }
      events {
        items {
          id
          localOwner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          Longitude
          Latitude
        }
        nextToken
      }
      userProfile {
        id
        localOwner
        userName
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        firstName
        lastName
        preferredName
        weight
        height
        medicationComplianceStreak
        numMedications
        medications {
          items {
            id
            localOwner
            name
            dosage
            reminderTime
          }
          nextToken
        }
      }
      responsibleParties {
        items {
          id
          localOwner
          phone
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      scheduleReminders {
        items {
          id
          localOwner
          timestamp
          medName
          medDose
          lastTaken
          type
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      carePlan {
        id
        localOwner
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        thresholds {
          items {
            id
            localOwner
            name
            minimum
            maximum
          }
          nextToken
        }
        vitalTypes
      }
      lexInteractions {
        items {
          id
          localOwner
          inputTranscript
          timestamp
          botName
          botAlias
          slots
          requestAttributes
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
    }
    Longitude
    Latitude
  }
}
`;
export const listRapidEvents = `query ListRapidEvents(
  $filter: ModelRapidEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listRapidEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      localOwner
      Account
      TimeStamp
      AlarmCode
      EventCode
      Event
      user {
        id
        userName
        localOwner
        groups
        firstRun
        isDeveloper
        devices {
          items {
            id
            localOwner
            xmit
            rapidPhoneNumber
          }
          nextToken
        }
        readings {
          items {
            id
            localOwner
            timestamp
            reading
          }
          nextToken
        }
        events {
          items {
            id
            localOwner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        userProfile {
          id
          localOwner
          userName
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          firstName
          lastName
          preferredName
          weight
          height
          medicationComplianceStreak
          numMedications
          medications {
            nextToken
          }
        }
        responsibleParties {
          items {
            id
            localOwner
            phone
          }
          nextToken
        }
        scheduleReminders {
          items {
            id
            localOwner
            timestamp
            medName
            medDose
            lastTaken
            type
          }
          nextToken
        }
        carePlan {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          thresholds {
            nextToken
          }
          vitalTypes
        }
        lexInteractions {
          items {
            id
            localOwner
            inputTranscript
            timestamp
            botName
            botAlias
            slots
            requestAttributes
          }
          nextToken
        }
      }
      Longitude
      Latitude
    }
    nextToken
  }
}
`;
export const getSceneEvent = `query GetSceneEvent($id: ID!) {
  getSceneEvent(id: $id) {
    id
    localOwner
    name
    sceneToLoad
    user {
      id
      userName
      localOwner
      groups
      firstRun
      isDeveloper
      devices {
        items {
          id
          localOwner
          xmit
          rapidPhoneNumber
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          peripherals {
            nextToken
          }
        }
        nextToken
      }
      readings {
        items {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          timestamp
          reading
        }
        nextToken
      }
      events {
        items {
          id
          localOwner
          Account
          TimeStamp
          AlarmCode
          EventCode
          Event
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          Longitude
          Latitude
        }
        nextToken
      }
      userProfile {
        id
        localOwner
        userName
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        firstName
        lastName
        preferredName
        weight
        height
        medicationComplianceStreak
        numMedications
        medications {
          items {
            id
            localOwner
            name
            dosage
            reminderTime
          }
          nextToken
        }
      }
      responsibleParties {
        items {
          id
          localOwner
          phone
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      scheduleReminders {
        items {
          id
          localOwner
          timestamp
          medName
          medDose
          lastTaken
          type
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
      carePlan {
        id
        localOwner
        user {
          id
          userName
          localOwner
          groups
          firstRun
          isDeveloper
          devices {
            nextToken
          }
          readings {
            nextToken
          }
          events {
            nextToken
          }
          userProfile {
            id
            localOwner
            userName
            firstName
            lastName
            preferredName
            weight
            height
            medicationComplianceStreak
            numMedications
          }
          responsibleParties {
            nextToken
          }
          scheduleReminders {
            nextToken
          }
          carePlan {
            id
            localOwner
            vitalTypes
          }
          lexInteractions {
            nextToken
          }
        }
        thresholds {
          items {
            id
            localOwner
            name
            minimum
            maximum
          }
          nextToken
        }
        vitalTypes
      }
      lexInteractions {
        items {
          id
          localOwner
          inputTranscript
          timestamp
          botName
          botAlias
          slots
          requestAttributes
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
        }
        nextToken
      }
    }
    sceneConfig
  }
}
`;
export const listSceneEvents = `query ListSceneEvents(
  $filter: ModelSceneEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listSceneEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      localOwner
      name
      sceneToLoad
      user {
        id
        userName
        localOwner
        groups
        firstRun
        isDeveloper
        devices {
          items {
            id
            localOwner
            xmit
            rapidPhoneNumber
          }
          nextToken
        }
        readings {
          items {
            id
            localOwner
            timestamp
            reading
          }
          nextToken
        }
        events {
          items {
            id
            localOwner
            Account
            TimeStamp
            AlarmCode
            EventCode
            Event
            Longitude
            Latitude
          }
          nextToken
        }
        userProfile {
          id
          localOwner
          userName
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          firstName
          lastName
          preferredName
          weight
          height
          medicationComplianceStreak
          numMedications
          medications {
            nextToken
          }
        }
        responsibleParties {
          items {
            id
            localOwner
            phone
          }
          nextToken
        }
        scheduleReminders {
          items {
            id
            localOwner
            timestamp
            medName
            medDose
            lastTaken
            type
          }
          nextToken
        }
        carePlan {
          id
          localOwner
          user {
            id
            userName
            localOwner
            groups
            firstRun
            isDeveloper
          }
          thresholds {
            nextToken
          }
          vitalTypes
        }
        lexInteractions {
          items {
            id
            localOwner
            inputTranscript
            timestamp
            botName
            botAlias
            slots
            requestAttributes
          }
          nextToken
        }
      }
      sceneConfig
    }
    nextToken
  }
}
`;
