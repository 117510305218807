import React from "react";

import './styles.scss';

const BingoTable = ({ tbody }) => (
  <div className="bingo-boredo">
    <div className="bingo-table__container">
      <table className="bingo-table" id="board">
        <thead>
          <tr>
            <th className="bingo-table__header bingo-table__header--1">B</th>
            <th className="bingo-table__header bingo-table__header--2">I</th>
            <th className="bingo-table__header bingo-table__header--3">N</th>
            <th className="bingo-table__header bingo-table__header--4">G</th>
            <th className="bingo-table__header bingo-table__header--5">O</th>
          </tr>
        </thead>
        <tbody className="bingo-table__cells">{tbody}</tbody>
      </table>
    </div>
  </div>
);

export default BingoTable;
