import React, { Component } from 'react';
// import { XR, Hub } from 'aws-amplify';

export default class FitbitAuthButton extends Component {
	getInitialState() {
		return {
			on: true
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();
        this.handleOnClick = this.handleOnClick.bind(this);
        this.href = "https://fitbit.com/oauth2/authorize?client_id=22DQGM&response_type=code&scope=activity%20sleep";
        this.popupAuth = null;
    }

    componentDidMount() {
        //todo
        //
        // Subscribe to UserAuxApi[username][Fitbit.Iconic] (eg.)
        // then use this.setState to re-render the button 
        // finally, wait for the subscription notification fired
        // from the ApiGateway Lambda acting as the redirect_uri server
        // & close the popup (if still open) once the notification arrives

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars
    }

    handleOnClick (e){
        console.log(this);
        e.preventDefault(); 
        this.popupAuth = window.open(
            'https://fitbit.com/oauth2/authorize?client_id=22DQGM&response_type=code&scope=activity%20sleep' ,
            //this.href,
            'popUpWindow',
            'height=500,width=400,left=100,top=10,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=yes,directories=no');
        this.popupLocation = this.popupAuth.location;
        this.popupAuth.addEventListener('hashchange', function() {
            window.close(this.popupAuth);
        }, false);
//        setTimeout (() => {
//            if (this.popupAuth && ! this.popupAuth.closed) 
//                window.close(this.popupAuth);
//        }, 30000);

    }

    render() { 
        return (
    	  <div className={this.state.on ? "" : "hidden"}>
			<a target="_blank" href="https://fitbit.com/oauth2/authorize?client_id=22DQGM&response_type=code&scope=activity%20sleep" className="btnWide" id="btnAuth"
            onClick={this.handleOnClick}>
				<svg
					viewBox="0 0 442.533 442.533"
					width="2.9vw"
					height="2.9vw"
					overflow="visible"
			     >
			  		<g transform="translate(-240,50)"> 
						<path
							className="checkMark"
							fill="#d2d6e8"
							d="M434.539,98.499l-38.828-38.828c-5.324-5.328-11.799-7.993-19.41-7.993c-7.618,0-14.093,2.665-19.417,7.993L169.59,247.248   l-83.939-84.225c-5.33-5.33-11.801-7.992-19.412-7.992c-7.616,0-14.087,2.662-19.417,7.992L7.994,201.852   C2.664,207.181,0,213.654,0,221.269c0,7.609,2.664,14.088,7.994,19.416l103.351,103.349l38.831,38.828   c5.327,5.332,11.8,7.994,19.414,7.994c7.611,0,14.084-2.669,19.414-7.994l38.83-38.828L434.539,137.33   c5.325-5.33,7.994-11.802,7.994-19.417C442.537,110.302,439.864,103.829,434.539,98.499z" 
							/>
					</g>
				</svg>Authenticate Fitbit
			</a>
		  </div>
        );
    }
}

