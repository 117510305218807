//AppInitialSideBar.jsx

import React from 'react';
import { Hub } from 'aws-amplify';
import autobind from 'class-autobind';
  
export default class AppInitialSidebar extends React.Component {
    containerStyle = {
        top: "10vh",
        left: "80vw",
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        justifyContent: "spaceBetween",
    };

    promptStyle = {
        fontSize: "1.5vw",
        color: "#d2d6e8",
        fontWeight: "bold",
        borderRadius: ".75vw",
        padding: "1vw",
        border: "0vw",
        alignItems: "center",
        textAlign: "center",
        width: "20vw",
        background: "#0B2545",
        margin: "0vw",
        position: "absolute",
        top: "25vh",
        left: "50vw",
    };

    buttonStyle = {
        width: "18vw",
        height: "25vh",
        margin: ".5vw .5vh",
    }

    constructor(props) {
        super(props);

        autobind(this);

        this.state = {};
    } 

    handleHealthClick(e) {
        e.preventDefault();
        console.log("My Health clicked");
        Hub.dispatch("hideAppSidebars");
        Hub.dispatch("showAppHealthSidebar");
    }

    handleAboutClick(e) {
        e.preventDefault();
        console.log("Communications clicked");
        Hub.dispatch("showAppCommunicationsSidebar");
    }

    handleRewardsClick(e) {
        e.preventDefault();
        console.log("Rewards clicked");
        Hub.dispatch("showAppRewardsSidebar");
        setTimeout( () => {
            document.activeElement.blur();
        }, 1000);
    }
 
    render() {
        return this.props.on ? (
            <div>
                <div className="appSidebarContainer" style={this.containerStyle}>
                    <button style={this.buttonStyle} className="btnTemplate" onClick={this.handleHealthClick}> My health </button>
                    <button style={this.buttonStyle} className="btnTemplate"  onClick={this.handleAboutClick}> Comms. </button>
                    <button style={this.buttonStyle} className="btnTemplate" onClick={this.handleRewardsClick}> Rewards </button>
                </div>
                <div style={this.promptStyle}>
                    Start by saying: "Addison"<br/><br/>
                    Wait until you see the green <br/>"I'm Listening" icon. <br/>
                </div>
            </div>
        ) : (null)
    }
}