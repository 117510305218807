import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
import AmplifySceneLoader from '../../AmplifySceneLoader';

export default class HelpButton extends Component {
	getInitialState() {
		return {
			on: true
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        Hub.listen("HeardHelp", (capsule) => {
        	// const { payload } = capsule;
        }); //TODO: get actual name


        this.handleClick = this.handleClick.bind(this);
        window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    handleClick() {
    	window.amplifySceneLoader.dispatchPlayHelpScene();
    	//window.eventManager.emit("loadHelpScreen");
        Hub.dispatch("loadHelpScreen");
    }

    render() {
        return (

            <button style={{zIndex: "10000"}} className={this.state.on ? "btnhelp left-button ui-button" : "hidden"} onClick={this.handleClick} id="btnhelp">

			<svg 
			     viewBox="0 0 512 512" 
			     width="2.5vw" 
			     height="2.5vw" 
			     overflow="visible"
			     >
				<g transform="translate (-500,40)">
					<path
						  className="white"
						  fill="#d2d6e8" 
						  d="M423.683,315.569c0.052,7.011-4.099,13.325-10.46,16.275c-40.022,18.564-95.66,30.088-157.224,30.088  s-117.202-11.523-157.225-30.088c-6.36-2.951-10.511-9.265-10.46-16.275C89.57,147.062,120.395,0,256,0  S422.43,147.062,423.683,315.569z" 
						  />
					<path
						 className="green"
						 fill="#00AC9F" 
						 d="M317.793,342.844v-42.706H194.207v42.706c0,11.399-7.294,21.519-18.109,25.123l-84.126,28.042  c-18.023,6.008-30.179,22.875-30.179,41.873v56.463c0,9.751,7.904,17.655,17.655,17.655h353.103c9.751,0,17.655-7.904,17.655-17.655  v-56.463c0-18.998-12.157-35.865-30.18-41.873l-84.126-28.042C325.087,364.362,317.793,354.242,317.793,342.844z" 
						  />
					<path 
						  className="green"
						  fill="#00AC9F"  
						  d="M194.207,342.844c0,10.685-6.453,20.173-16.161,24.298c98.92,16.858,139.72-53.762,139.72-53.762  l0.028-0.019v-13.223H194.207V342.844z" 
						  />
					<path 
						  className="green"
						  fill="#00AC9F"  
						  d="M142.72,185.469l-10.251-0.033c-9.772-0.031-17.711,7.883-17.711,17.655v34.899  c0,9.888,8.12,17.848,18.007,17.652l15.215-0.303L142.72,185.469z" 
						  />
					<path 
						  className="green"
						  fill="#00AC9F"  
						  d="M369.28,185.469l10.251-0.033c9.772-0.031,17.711,7.883,17.711,17.655v34.899  c0,9.888-8.12,17.848-18.007,17.652l-15.215-0.303L369.28,185.469z" 
						  />
					<path 
						  className="green"
						  fill="#00AC9F" 
						  d="M141.241,105.931v38.061c0,15.816,0.607,31.625,1.82,47.393l5.087,66.129  c1.216,15.807,9.448,30.238,22.435,39.329l53.308,37.315c9.407,6.585,20.612,10.118,32.095,10.118h0.026  c11.482,0,22.688-3.532,32.095-10.118l53.308-37.315c12.988-9.091,21.22-23.522,22.435-39.329l5.087-66.129  c1.213-15.768,1.82-31.578,1.82-47.393v-38.061H141.241z" 
						  />
					<path 
						  className="white"
						  fill="#d2d6e8"  
						  d="M420.026,396.009l-68.74-22.913c-32.717,44.385-70.904,69.187-95.286,77.111  c-24.382-7.924-62.569-32.726-95.286-77.111l-68.74,22.913c-18.024,6.008-30.18,22.875-30.18,41.873v56.463  c0,9.75,7.905,17.655,17.655,17.655h353.103c9.75,0,17.655-7.905,17.655-17.655v-56.463  C450.207,418.883,438.05,402.017,420.026,396.009z" 
						  />
					<path 
						  className="white"
						  fill="#d2d6e8"  
						  d="M132.414,97.103v61.793c104.585,0,184.198-36.41,197.532-44.455  c14.33,35.627,40.151,44.455,40.151,44.455h9.49V97.103H132.414z" 
						  />
					<path 
						  className="white"
						  fill="#d2d6e8"  
						  d="M379.234,255.641l-15.147-0.301c-6.74,12.245-26.929,32.295-87.123,32.295v17.655  c80.439,0,101.08-34.278,106.324-50.073C381.98,255.496,380.632,255.669,379.234,255.641z" 
						  />
					<path 
						  className="white"
						  fill="#d2d6e8"  
						  d="M269.241,308.966h-8.828c-7.313,0-13.241-5.929-13.241-13.241l0,0  c0-7.313,5.929-13.241,13.241-13.241h8.828c7.313,0,13.241,5.929,13.241,13.241l0,0  C282.483,303.037,276.554,308.966,269.241,308.966z" 
						  />
			  </g> 
			</svg>
			Help
			</button>

        );
    }
}