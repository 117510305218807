//AppHealthSidebar.jsx

import React from 'react';
import { Hub } from 'aws-amplify';
  
export default class AppCommunicationsSidebar extends React.Component {
    constructor(props) {
        super(props);

        this.handleDone = this.handleDone.bind(this);
        this.handleCustomerSupportClick = this.handleCustomerSupportClick.bind(this);
    }

    containerStyle = {
        top: "10vh",
        left: "80vw",
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        justifyContent: "spaceBetween",
    };

    buttonStyle = {
        width: "18vw",
        height: "25vh",
        margin: ".5vw .5vh",
    }

    handleCustomerSupportClick(e) {
        e.preventDefault();

        //TODO: dispatch events
    }

    handleDone(event) {
        event.preventDefault();
        console.log("clicked Addison Button, reloading page...");
        Hub.dispatch("UIReset");
        // window.location.reload();
    }

    render() {
        return this.props.on ? (
          <div className="appSidebarContainer" style={this.containerStyle}>
            <button style={this.buttonStyle} className="btnTemplate"  onClick={this.handleCustomerSupportClick}> Customer Support </button>
            <button style={this.buttonStyle} className="btnTemplate" onClick={() => console.log("TBD clicked")}> TBD </button>
            <button style={this.buttonStyle} className="btnDone"  onClick={this.handleDone}> Done </button>
          </div>
        ) : (null);
    }
}