import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import AWSAppSyncClient, { createAppSyncLink, createLinkWithCache } from 'aws-appsync';
import { ApolloProvider/*, withApollo*/ } from 'react-apollo';
import { ApolloLink } from 'apollo-link';
import { withAuthenticator } from 'aws-amplify-react';
import { withClientState } from 'apollo-link-state';
import { Rehydrated } from 'aws-appsync-react';
import awsmobile from './aws-exports.js';
import gql from 'graphql-tag';
import Amplify, { Logger, Auth, Analytics } from 'aws-amplify'; // eslint-disable-line no-unused-vars
import App from './App';
import AWS from 'aws-sdk/global';
import './App.css';
// import * as serviceWorker from './serviceWorker';

import autoCreds, {amplifyConfig} from './CredentialsModule';
const logger = new Logger('index.js');

// Configure Amplify
const stateLink = createLinkWithCache(cache => withClientState({
    cache,
    resolvers: {
        Mutation: {
            updateNetworkStatus: (_, { isConnected }, { cache }) => {
                const data = {
                    networkStatus: {
                        __typename: 'NetworkStatus',
                        isConnected,
                    },
                };
                cache.writeData({ data });
                return null;
            },
        },
    },
    defaults: {
        networkStatus: {
            __typename: 'NetworkStatus',
            isConnected: false,
        },
    },
}));
const appSyncLink = createAppSyncLink({
    url: "https://jpl2zjoe6zfcnal3etgty5psbi.appsync-api.us-east-1.amazonaws.com/graphql",
    region: 'us-east-1',
    auth: {
        type: "AMAZON_COGNITO_USER_POOLS",
        //apiKey: aws_exports.apiKey,
        jwtToken: async () => {
            return (await Auth.currentSession()).getIdToken().getJwtToken();
        },
    },
});

const link = ApolloLink.from([stateLink, appSyncLink]);

const WithAppSyncProvider = (Comp) => class extends Component {
    render() {
        const client = new AWSAppSyncClient(
            {
                region: 'us-east-1',//config.aws_appsync_region,
                url: awsmobile.aws_appsync_graphqlEndpoint,
                auth: {
                    type: amplifyConfig.aws_appsync_authenticationType,
                    jwtToken: async () => (await Auth.currentSession()).idToken.jwtToken,
                },
            }, 
            { link }
            );

        const result = async () => await client.mutate({
            mutation: gql `mutation updateNetworkStatus($isConnected: Boolean) {
                updateNetworkStatus(isConnected: $isConnected) @client {
                  isConnected
                }
              }`,
            variables: { isConnected: true },
        });

        (async () => await result())();

        

        return (
    <ApolloProvider client={client}>
        <Rehydrated>
          <Comp authState={this.props.authState} 
                authData={this.props.authData} 
                loadingState={loadingState} 
                onStateChange={this.props.onStateChange}/>
        </Rehydrated>
    </ApolloProvider>
        );
    }
};


const root = document.getElementById('root');
const render = (Comp) => {
  ReactDOM.render(
      <Comp />,
    root
  );
};

const handleGlobalErrors = (window) => {
    window.LOG_LEVEL = 'DEBUG';
    window.addEventListener('unhandledrejection', (err) => {
        logger.debug("[ADDISON] unhandledrejection detected")
    });
}

handleGlobalErrors(window);

/* 
 * Begin Auth cred deployment
 */

Auth.configure(amplifyConfig);
   /*
Auth.signOut({ global: true })
    .then(data => console.log(data))
    .catch(err => console.log(err));
// */

let loadingState = {"wasLoggedIn": true}; 

// Attempt to deploy credentials before rendering if already logged in.
// If not logged in, set a flag to deploy credentials at first available
// moment (see CredentialsModule.js).
//
autoCreds.deploy("index")
    .then((conf) => {
        // We were still logged in - creds are up to date - ready to render app
        loadingState.wasLoggedIn = true;
    })
    .catch((err) => {
        // We were not logged in - default creds will expire (Amplify bug)
        // if a service is initiated with them - eg. Analytics.record(<signIn>)
        // and/or if an AWS service object sees prolonged use (over 1hr)
        Analytics.configure ({disabled:true}); 

        // Simply states whether or not we were logged in before load
        loadingState.wasLoggedIn = false; 

        // Make sure to re-enable Analytics after initial login
        // by referencing loading state in the eventual deployment
        // Analytics.enable(); 

    }).finally (() => {
        // withAuthenticator handles rendering in either login state
        render (withAuthenticator(WithAppSyncProvider(App)));
    });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
