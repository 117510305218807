//index.jsx (AppSudoku)

import React, { useEffect, useState } from 'react';
import { Hub, Logger } from 'aws-amplify';
import _ from 'lodash';
import './style.css';

const sudoku = require('sudoku');

const logger = new Logger('AppSudoku'); //eslint-disable-line no-unused-vars

const AppSudokuContainer = (props) => {
    const [on, setOn] = useState(false);
    const [rows, setRows] = useState([]);





    Hub.listen("AppSudoku", (capsule) => {
        const { payload } = capsule;
        const { event } = payload;

        switch (event) {
            case "play":
            case "show":
                setOn(true);
                break;
            case "stop":
            case "hide":
            default:
                setOn(false);
        }
    })

    /*** componentDidMount ***/
    useEffect(() => {
        const board = sudoku.makepuzzle();
        // logger.debug(`[AppSudokuContainer] board: ${board}`)
        const _rows = _.chunk(board, 9);

        const tileRows = _rows.map((row, index, array) => {
                const r = row.map((cell, i, a) => {
                	// logger.debug(`current ${cell} at index ${index*9+i}`)
                    return cell !== null ? 
                    	(<input value={cell+1} readOnly maxLength="1" key={index*9+i} className="sudoku-cell" />) :
                    	(<input maxLength="1" key={index*9+i} className="sudoku-cell" />);
                })

                return r;
            });

            setRows(tileRows);

        const cleanup = () => {
            Hub.remove("AppSudoku");
        }

        return cleanup;
    }, []);

    return on ?
        (
            <div className="sudoku-board">
			{
				//map 
				rows.map( (row, index, array) => 
					(<div key={index} className="sudoku-row">
						{row}
					</div>)
				)
			}
		</div>


        ) : (null);
}

export default AppSudokuContainer;