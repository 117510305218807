import React from "react";

import '../styles.scss'

const  MicInstructions = () => {


return (
    <div className='instruction'>
            If your microphone is connected you will see "Microphone Connected" on the microphone button.
            If your microphone is not connected, the microphone button will display "Test Microphone" 
    </div>
  )
  
}

export default MicInstructions; 