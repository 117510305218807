//AppAboutPrompt.jsx

import React from 'react';
  
export default class AppAboutPrompt extends React.Component {
    containerStyle = {
        fontSize: "1.5vw",
        color: "#d2d6e8",
        fontWeight: "bold",
        //cursor: "pointer",
        borderRadius: ".75vw",
        padding: "1vw",
        border: "0vw",
        alignItems: "center",
        textAlign: "center",
        width: "30vw",
        background: "#0B2545",
        margin: "0vw",
        position: "absolute",
        top: "20vh",
        left: "45vw",
    };

    buttonStyle = {
        width: "12vw",
        height: "20vh",
        margin: ".5vw .5vh",
    }

    render() {
        return this.props.on ? (
            <div id="appOptionsSidebarContainer" style={this.containerStyle}>
                - "Where did you go to school?"
                <br/>
                <br/>

                - "When is your Birthday?"
                <br/>
                <br/>

                - "How long have you been a Caregiver?"
                <br/>
                <br/>

                - "Do you have any hobbies?"
                <br/>
                <br/>

                - "Thank you."
            </div>
        ) : (null);
    }
}