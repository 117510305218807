import { Hub } from "aws-amplify";
import React from "react";
import { Client } from "boardgame.io/react";
import { Game, INVALID_MOVE } from "boardgame.io/core";
import _ from "lodash";

import RecentBalls from "./RecentBalls/index";
import CurrentBall from "./CurrentBall/index";
import BingoTable from "./BingoTable/index";

import "./styles.scss";

// 5x5 board
const NUM_TILES = 5 * 5;
const LETTERS = ["B", "I", "N", "G", "O"];

const ballColors = ["#E53935", "#8E24AA", "#2196F3", "#4CAF50", "#FF9800"];

/**
 * generates a combination object of objects containg all possible balls
 * @returns {Object} availableBalls
 */
function generateCombination() {
  let availableBalls = {};
  let availableBallsLength = 0;
  LETTERS.forEach(currentLetter => {
    for (let i = 0; i < 15; i++) {
      availableBalls[currentLetter + ++availableBallsLength] = {
        letter: currentLetter,
        number: availableBallsLength,
        color: _.sample(ballColors)
      };
    }
  });

  return availableBalls;
}

/**
 * isVictory() checks to see if a win condition
 * has been met
 * @param {Array} tiles - G.tiles
 */
function isVictory(tiles) {
  // all possible win conditions
  let leftDiagonal = [0, 6, 12, 18, 24];
  let rightDiagonal = [4, 8, 12, 16, 20];
  let colA = [0, 5, 10, 15, 20];
  let colB = [1, 6, 11, 16, 21];
  let colC = [2, 7, 12, 17, 22];
  let colD = [3, 8, 13, 18, 23];
  let colE = [4, 9, 14, 19, 24];
  let row1 = [0, 1, 2, 3, 4];
  let row2 = [5, 6, 7, 8, 9];
  let row3 = [10, 11, 12, 13, 14];
  let row4 = [15, 16, 17, 18, 19];
  let row5 = [20, 21, 22, 23, 24];

  // if either diagonals are fully filled, the game is won
  let win = true;
  leftDiagonal.forEach(i => {
    if (tiles[i].props.className !== "selected") win = false;
  });

  if (win) return true;

  // if either diagonals are fully filled, the game is won
  win = true;
  rightDiagonal.forEach(i => {
    if (tiles[i].props.className !== "selected") win = false;
  });

  if (win) return true;

  // if either diagonals are fully filled, the game is won
  win = true;
  colA.forEach(i => {
    if (tiles[i].props.className !== "selected") win = false;
  });

  if (win) return true;

  // if either diagonals are fully filled, the game is won
  win = true;
  colB.forEach(i => {
    if (tiles[i].props.className !== "selected") win = false;
  });

  if (win) return true;

  // if either diagonals are fully filled, the game is won
  win = true;
  colC.forEach(i => {
    if (tiles[i].props.className !== "selected") win = false;
  });

  if (win) return true;

  // if either diagonals are fully filled, the game is won
  win = true;
  colD.forEach(i => {
    if (tiles[i].props.className !== "selected") win = false;
  });

  if (win) return true;

  // if either diagonals are fully filled, the game is won
  win = true;
  colE.forEach(i => {
    if (tiles[i].props.className !== "selected") win = false;
  });

  if (win) return true;

  // if either diagonals are fully filled, the game is won
  win = true;
  row1.forEach(i => {
    if (tiles[i].props.className !== "selected") win = false;
  });

  if (win) return true;

  // if either diagonals are fully filled, the game is won
  win = true;
  row2.forEach(i => {
    if (tiles[i].props.className !== "selected") win = false;
  });

  if (win) return true;

  // if either diagonals are fully filled, the game is won
  win = true;
  row3.forEach(i => {
    if (tiles[i].props.className !== "selected") win = false;
  });

  if (win) return true;

  // if either diagonals are fully filled, the game is won
  win = true;
  row4.forEach(i => {
    if (tiles[i].props.className !== "selected") win = false;
  });

  if (win) return true;

  // if either diagonals are fully filled, the game is won
  win = true;
  row5.forEach(i => {
    if (tiles[i].props.className !== "selected") win = false;
  });

  if (win) return true;

  // if none are true, then the game is not won
  return false;
}

// Boardgame.io object
const BingoGame = Game({
  // constructor for bingo game
  setup: () => {
    // the game state
    let G = {
      // stores player bingo sheet data
      tiles: Array(NUM_TILES).fill(null),

      // stores the last 5 balls played
      history: [
        { letter: "", number: "" },
        { letter: "", number: "" },
        { letter: "", number: "" },
        { letter: "", number: "" },
        { letter: "", number: "" }
      ],

      // stores all current available balls
      availableBalls: generateCombination(),
    
      //state if game has been started 
      startBingo: false
   
    };

    /**
     * generate tiles[] (player's bingo card),
     * gets a value from potential values, removes
     * the value, repeats 5 times per column
     */
    let colAPotentialValues = _.range(1, 15);
    let colBPotentialValues = _.range(16, 30);
    let colCPotentialValues = _.range(31, 45);
    let colDPotentialValues = _.range(46, 60);
    let colEPotentialValues = _.range(61, 75);

    let colA = [0, 5, 10, 15, 20];
    let colB = [1, 6, 11, 16, 21];
    let colC = [2, 7, 12, 17, 22];
    let colD = [3, 8, 13, 18, 23];
    let colE = [4, 9, 14, 19, 24];

    colA.forEach(i => {
      let num = _.sample(colAPotentialValues);
      colAPotentialValues = colAPotentialValues.filter(e => e !== num);
      G.tiles[i] = (
        <div className="not-selected" number={num}>
          <p className="cell-label">{num}</p>
        </div>
      );
    });

    colB.forEach(i => {
      let num = _.sample(colBPotentialValues);
      colBPotentialValues = colBPotentialValues.filter(e => e !== num);
      G.tiles[i] = (
        <div className="not-selected" number={num}>
          <p className="cell-label">{num}</p>
        </div>
      );
    });

    colC.forEach(i => {
      let num = _.sample(colCPotentialValues);
      colCPotentialValues = colCPotentialValues.filter(e => e !== num);
      G.tiles[i] = (
        <div className="not-selected" number={num}>
          <p className="cell-label">{num}</p>
        </div>
      );
    });

    colD.forEach(i => {
      let num = _.sample(colDPotentialValues);
      colDPotentialValues = colDPotentialValues.filter(e => e !== num);
      G.tiles[i] = (
        <div className="not-selected" number={num}>
          <p className="cell-label">{num}</p>
        </div>
      );
    });

    colE.forEach(i => {
      let num = _.sample(colEPotentialValues);
      colEPotentialValues = colEPotentialValues.filter(e => e !== num);
      G.tiles[i] = (
        <div className="not-selected" number={num}>
          <p className="cell-label">{num}</p>
        </div>
      );
    });

    // set middle tile to freebie
    G.tiles[12] = (
      <div className="selected">
        <div className="token">
          <p className="cell-label">X</p>
        </div>
      </div>
    ); //middle square is free

    // initial letter/number combination is empty
    G.currentLetter = "";
    G.currentNumber = "";

    return G;
  },

  /**
   * funcations tha tell the game state {G} how to change when a
   * particular move is made
   */
  moves: {
    // onClick handler for each individual tile
    clickCell(G, ctx, id) {
      // console.log("in clickCell, id:", id);
      // console.log(`in clickCell, G.currentLetter = ${G.currentLetter}`);
      
      
      // if tile has already been picked
      if (G.tiles[id].props.className === "selected") {
        console.log("tile already has been claimed");
        return INVALID_MOVE;
      }

      // if tile value doesn't matched picked ball's value
      if (
        G.tiles[id].props.number !== G.currentNumber &&
        G.tiles[id].props.number !== G.history[0].number &&
        G.tiles[id].props.number !== G.history[1].number &&
        G.tiles[id].props.number !== G.history[2].number &&
        G.tiles[id].props.number !== G.history[3].number &&
        G.tiles[id].props.number !== G.history[4].number
      ) {
        console.log(
          "tile does not contain the same number as the current combination"
        );
        return INVALID_MOVE;
      }
      // delete forgotten balls that are selected on board from the avaliable ball array
      for(let i=0; i <= G.history.length; i++){
        if(G.tiles[id].props.number === G.history[i].number){
          delete G.availableBalls[G.history[i].letter + G.tiles[id].props.number]
          console.log(`Deleted forgotten ball ${G.history[i].letter} ${G.tiles[id].props.number}`)
          break
        }else{
            break
        }
        
        
      }
      // if (
    
      //   G.tiles[id].props.number == G.history[0].number ||
      //   G.tiles[id].props.number == G.history[1].number ||
      //   G.tiles[id].props.number == G.history[2].number ||
      //   G.tiles[id].props.number == G.history[3].number ||
      //   G.tiles[id].props.number == G.history[4].number
      // ){
      //   delete G.availableBalls[G.tiles[id].props.letter + G.tiles[id].props.number];
      //   console.log("deleted forgotten ball")
      // }

      // else move is valid, switch to claimed tile
      G.tiles[id] = (
        <div className="selected">
          <div
            className="token"
            style={{
              "background-color":
                G.tiles[id].props.number === G.currentNumber
                  ? G.currentColor
                  : G.history.find(
                      ball => G.tiles[id].props.number === ball.number
                    ).color
            }}
          >
            <p className="cell-label">{G.tiles[id].props.number}</p>
          </div>
        </div>
      );
        
      // return the game state {G}
      return G;
    },

    


    /**
     * getNextBall() is the main function used to
     * advance the player to the next ball
     */
    getNextBall(G, ctx) {
      if (Object.keys(G.availableBalls).length > 0) {
        // add newest ball to beginning of history array
        G.history.unshift({
          letter: G.currentLetter,
          number: G.currentNumber,
          color: G.currentColor
        });

        // only remove ball from availableBalls if selected
        if (!didForget(G)) {
          console.log(`DELETED ${G.currentLetter + G.currentNumber}`);
          delete G.availableBalls[G.currentLetter + G.currentNumber];
        }

        let duplicate = true;

        while (duplicate) {
          // generate the next ball and store them in state for convenience

          //generate new ball only if ball is in history
          G.currentCombination = getBall(G);
          for (let i = 0; i < 5; i++) {
            if (G.history[i].number === G.currentCombination.number) {
              break;
            } else if (i === 4) {
              duplicate = false;
            }
          }
        }

        G.currentLetter = G.currentCombination.letter;
        G.currentNumber = G.currentCombination.number;
        G.currentColor = G.currentCombination.color;

        // emit the event in Sumerian
        window.eventManager.emit(
          "drawBingo",
          `<speak>The next combination is ${G.currentLetter} ${
            G.currentNumber
          }</speak>`
        );
      } else {
        // all out of balls
        console.log("the end");

        // TODO: replay? button here
      }
    },
    startBingoGame(G) {

        G.startBingo = true;
    }
  },

  // controls the flow of the game
  flow: {
    // uses isVictory to determine if win condition has been met
    endGameIf: (G, ctx) => {
      if (isVictory(G.tiles)) return { winner: ctx.currentPlayer };
    }
  }
});

/**
 * getBall() picks a random ball from availableBalls, deletes
 * it from possible values, and returns the picked ball
 * @param {Object} G
 * @returns {Object} drawnBall
 */
const getBall = ({ availableBalls }) => {
  let drawnBall = _.sample(availableBalls);
  return drawnBall;
};



/**
 * checks to see if the user forgot to select the currentBall
 * @param {Object} G
 * @returns {boolean} forgot - true if forgot, else false
 */
const didForget = G => {
  let forgot = false;
  G.tiles.forEach(tile => {
    if (tile.props.number === G.currentNumber) {
      console.log("Forgot!");
      forgot = true;
    }
  });

  return forgot;
};

/**
 * Main BingoBoard React Component
 */
class BingoBoard extends React.Component {
  constructor(props) {
    super(props);

    Hub.listen("successfulSetup", () => {
      this.setState({ sceneLoaded: true });
    });

    // component state
    this.state = this.getInitialState();
    this.onClick = this.onClick.bind(this);
  }

  // onClick event handler
  async onClick(id) {
    //console.log("tile clicked, id:", id);

    let res = this.props.moves.clickCell(id);

    // console.log("res: ", res);

    if (res !== INVALID_MOVE) {
      this.props.events.endTurn();
    }
  }

  // onClick handler for draw again button
  onDrawClick(moves) {
    // console.log(`Next bingo ball is ${this.props.G.currentLetter}${this.props.G.currentNumber} . Again that number is ${this.props.G.currentLetter}${this.props.G.currentNumber}`)
    // window.eventManager.emit('draw',`Next bingo ball is ${this.props.G.currentLetter}${this.props.G.currentNumber}. Again that number is ${this.props.G.currentLetter}${this.props.G.currentNumber}`)
    moves.getNextBall();
  }

  onPlayBingo(moves){
      moves.startBingoGame();
  }

  getInitialState() {
    return {
      on: false,
      sceneLoaded: false
    };
  }

  render() {
    if (!this.props.on) return null;

    // game has been won
    if (this.props.ctx.gameover)
      window.eventManager.emit(
        "winBingo",
        `<speak>BINGO <mark name="gesture:Wave"/></speak>`
      );

    // generate bingo board
    let tbody = [];
    for (let i = 0; i < 5; i++) {
      let cells = [];
      for (let j = 0; j < 5; j++) {
        const id = 5 * i + j;
        cells.push(
          <td className="cell" key={id} onClick={() => this.onClick(id)}>
            {this.props.G.tiles[id]}
          </td>
        );
      }
      tbody.push(<tr key={i}>{cells}</tr>);
    }

    return (
      <div>
        {this.state.sceneLoaded && (
          <div className="app-bingo__container">
            {this.props.ctx.gameover && <p></p>}

            <CurrentBall
              state={this.props.G}
              onDrawClick={this.onDrawClick}
              moves={this.props.moves}
              onPlayBingo={this.onPlayBingo}
            />

            <RecentBalls state={this.props.G} />

            <BingoTable tbody={tbody} />
          </div>
        )}
      </div>
    );
  }
}

// game config
const AppBingoGame = Client({
  game: BingoGame,
  board: BingoBoard,
  debug: false,
  // debug: true,
  numPlayers: 1
});

export default AppBingoGame;
