import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
import AmplifySceneLoader from '../../AmplifySceneLoader';

export default class IdeasButton extends Component {
	getInitialState() {
		return {
			on: false
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        this.handleClick = this.handleClick.bind(this);
        this.onHubCapsule = this.onHubCapsule.bind(this);

        this.state.on = this.props.on;

        Hub.listen("ShowIdeas", this.onHubCapsule);
        Hub.listen("ShowIdeasButton", this.onHubCapsule);
        Hub.listen("HideIdeas", this.onHubCapsule);
        Hub.listen("hideIdeasBtn", this.onHubCapsule);
        Hub.listen("toggleIdeas", this.onHubCapsule);
        Hub.listen("showIdeasBtn", this.onHubCapsule);

        window.amplifySceneLoader = window.amplifySceneLoader || new AmplifySceneLoader();
    }



    handleClick(event) {
      event.preventDefault();
      this.setState({on: false});
      window.eventManager.emit("post_to_lex", "show me ideas");
    }


    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "ShowIdeas" || channel === "ShowIdeasButton" || channel === "showIdeasBtn") {
        	this.setState({on: true});
        }

        else if(channel === "HideIdeas" || channel === "HideIdeasButton" || channel === "hideIdeasBtn") {
        	this.setState({on: false});
        }

        else if(channel === "toggleIdeas") {
        	this.setState({on: !(this.state.on)});
        }
    }


    render() {
        return (
			<button className={this.state.on ? "btnSmallSierra btnNutrition" : "hidden"} onClick={this.handleClick} id="btnIdeas">Ideas</button>
        );
    }
}
