import React, { Component } from 'react';
import { Hub } from 'aws-amplify';

export default class SnoozeButton extends Component {
    getInitialState() {
        return {
            on: false
        };
    }

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        Hub.listen("ShowNotYet", () => {
            this.show()
        });
        Hub.listen("snooze", () => {
            this.hide();
        });
        Hub.listen("YesBtn", () => {
            this.hide();
        });

        Hub.listen("snooze_last", () => {
            this.hide();
        });

		//this.onHubCapsule = this.onHubCapsule.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.reset = this.reset.bind(this);
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
    }

    componentDidMount() {}

    componentDidUpdate() {}

    show() {
        this.setState({ on: true });
    }


    hide() {
        this.setState({ on: false });
    }

    reset() {
        this.setState(this.getInitialState());
    }

    // onHubCapsule(capsule) {
    //     const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

    //     if (channel === "UIReset") {
    //         this.reset();
    //     } else if (channel === "ShowNotYet") {
    //         this.show();
    //     } else if (channel === "HideSnooze" || channel === "snooze" || channel === "snooze_last" || channel === "YesBtn" || channel === "SceneReset" || channel === "not yet" || channel === "yes") {
    //         this.hide();
    //     }
    // }

    handleClick(event) {
        event.preventDefault();
        this.hide();
        Hub.dispatch("HideYes");
        // window.eventManager.emit("post_to_lex", "no");
        window.eventManager.emit("post_to_lex", "not yet");
    }


    render() {
        return (
            <button className={ this.state.on ? "btnnotyet" : "hidden"} onClick={this.handleClick} id="btnnotyet">

				<svg viewBox="0 0 398 398" width="4vw" height="4vw" overflow="visible">
    <g transform="translate(30,25)">
        <path className="NoXSvg" fill="#d2d6e8" d="M288.8,199l97.1-97.1c6.2-6.2,9.2-13.7,9.2-22.5s-3.1-16.3-9.2-22.5L341,12c-6.2-6.2-13.7-9.2-22.5-9.2 S302.2,5.9,296,12l-97,97.2L101.9,12c-6.2-6.2-13.6-9.2-22.5-9.2C70.6,2.8,63.1,5.9,57,12L12,57c-6.2,6.2-9.2,13.7-9.2,22.5 S5.9,95.8,12,102l97.1,97.1l-97.1,97c-6.2,6.2-9.2,13.7-9.2,22.5s3.1,16.3,9.2,22.5L57,386c6.2,6.2,13.7,9.2,22.5,9.2 s16.3-3.1,22.5-9.2l97.1-97.1l97.1,97.1c6.2,6.2,13.6,9.2,22.5,9.2c8.8,0,16.3-3.1,22.5-9.2l44.8-45c6.2-6.2,9.2-13.7,9.2-22.5 s-3.1-16.3-9.2-22.5L288.8,199z" />
    </g>
</svg>

			&nbsp;&nbsp;Not Yet&nbsp;&nbsp;

			</button>
        );
    }
}