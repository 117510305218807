import React, { Component } from 'react';
import { Hub } from 'aws-amplify';

export default class ListButton extends Component {
	getInitialState() {
		return {
			on: false,
            firstClick: true
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        this.onHubCapsule = this.onHubCapsule.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.state.on = this.props.on;

        Hub.listen("ShowList", this.onHubCapsule);
        Hub.listen("ShowListButton", this.onHubCapsule);
        Hub.listen("HideList", this.onHubCapsule);
        Hub.listen("HideListButton", this.onHubCapsule);
        Hub.listen("toggleList", this.onHubCapsule);
        Hub.listen("showListBtn", this.onHubCapsule);
        Hub.listen("hideListBtn", this.onHubCapsule);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "ShowList" || channel === "ShowListButton" || channel === "showListBtn") {
        	this.setState({on: true});
        }
        
        else if(channel === "HideList" || channel === "HideListButton" || channel === "hideListBtn") {
        	this.setState({on: false});
        }
        
        else if(channel === "toggleList") {
        	this.setState({on: !(this.state.on)});
        }
    }

    handleClick(event) {
        window.eventManager.emit("post_to_lex", "show me list");

        // if(this.state.firstClick) {

        //     Hub.dispatch("showList1");
        //     this.setState({firstClick: false});
        // } else {
        //     Hub.dispatch("hideList1");
        //     Hub.dispatch("showList2");
            // this.setState({on: false});
        // }
    }

    render() {
        return (
        	<button className={this.state.on ? "btnSmallSierra btnNutrition" : "hidden"} onClick={this.handleClick} id="btnList">List</button>
        );
    }
}


