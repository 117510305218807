//AppAddisonQuestionsSidebar.jsx

import React from 'react';
import { Hub } from 'aws-amplify';
  
export default class AppAddisonQuestionsSidebar extends React.Component {
    getInitialState() {
        return {
            on: false,
            aboutCaregiversOn: false,
            aboutAddisonOn: false
        }
    }

    constructor(props) {
        super(props);

        this.state = this.getInitialState();

        this.handleDone = this.handleDone.bind(this);
        this.handleMoreAboutCaregivers = this.handleMoreAboutCaregivers.bind(this);
        this.handleMoreAboutAddisonClick = this.handleMoreAboutAddisonClick.bind(this);

        this.onHubCapsule = this.onHubCapsule.bind(this);

        Hub.listen("ShowAppAddisonQuestionsSidebar", this.onHubCapsule, "AppAddisonQuestionsSidebar");
        Hub.listen("HideAppAddisonQuestionsSidebar", this.onHubCapsule, "AppAddisonQuestionsSidebar");

        Hub.listen("ShowAppAddisonQuestionsSidebar", this.onHubCapsule, "AppAddisonQuestionsSidebar");
        Hub.listen("ShowAppAddisonQuestionsSidebar", this.onHubCapsule, "AppAddisonQuestionsSidebar");

        Hub.listen("ShowMoreAboutAddison", this.onHubCapsule, "AppAddisonQuestionsSidebar");
        Hub.listen("ShowMoreAboutCaregivers", this.onHubCapsule, "AppAddisonQuestionsSidebar");

        Hub.listen("DoneCommand", this.onHubCapsule, "AppAddisonQuestionsSidebar");
        Hub.listen("UIReset", this.onHubCapsule, "AppAddisonQuestionsSidebar");
    }

    onHubCapsule(capsule) {
        const { channel } = capsule;

        if(channel === "UIReset") {
            this.setState(this.getInitialState())
        }

        else if(channel === "ShowAppAddisonQuestionsSidebar") {
            this.setState({on: true});
        }
        else if (channel === "HideAppAddisonQuestionsSidebar") {
            this.setState({on: false})
        } else if(channel === "ShowMoreAboutCaregivers"){
            console.log("Showing More About Caregivers");
            this.setState({aboutCaregiversOn: true, aboutAddisonOn: false})
        } else if(channel === "ShowMoreAboutAddison"){
            console.log("Showing More About Caregivers");

            this.setState({aboutCaregiversOn: false, aboutAddisonOn: true})
        } else if(channel === "DoneCommand"){
            Hub.dispatch("UIReset");
            Hub.dispatch("ShowInitSidebar");
        }
    }

    containerStyle = {
        top: "10vh",
        left: "80vw",
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        justifyContent: "spaceBetween",
    };

    buttonStyle = {
        width: "18vw",
        height: "25vh",
        margin: ".5vw .5vh",
    }

    aboutStyle = {
        fontSize: "1.5vw",
        color: "#d2d6e8",
        fontWeight: "bold",
        //cursor: "pointer",
        borderRadius: ".75vw",
        padding: "1vw",
        border: "0vw",
        alignItems: "center",
        textAlign: "center",
        width: "30vw",
        background: "#0B2545",
        margin: "0vw",
        position: "absolute",
        top: "25vh",
        left: "45vw",
    };

    handleMoreAboutCaregivers(e) {
        e.preventDefault();
        console.log("clicked Did I Take My Meds button...");
        //window.eventManager.emit("post_to_lex", "caregiver burnout");
        this.setState({aboutCaregiversOn: true, aboutAddisonOn: false})

    }

    handleMoreAboutAddisonClick(e) {
        e.preventDefault();
        console.log("clicked More About Addison button...");
        this.setState({aboutCaregiversOn: false, aboutAddisonOn: true})

        //window.eventManager.emit("post_to_lex", "birthday");
    }

    handleDone(event) {
        event.preventDefault();
        console.log("clicked Addison Button, reloading page...");
        Hub.dispatch("UIReset");
        Hub.dispatch("ShowInitSidebar");
        Hub.dispatch("ShowInitialSidebar");

        // window.location.reload();
    }

    render() {

        //This is the 'about' menu that pops up
        let about;
        if(this.state.aboutCaregiversOn){
            about =  (
            <div style={this.aboutStyle}>
                Ask addison any of these questions.
                <br/>
                <br/>
                
                - "How many family caregivers are there?"
                <br/>
                <br/>

                - "How much is family caregiving worth?"
                <br/>
                <br/>

                - "Tell me about caregiver burnout."
                <br/>
                <br/>

                - "What about caregiver jobs?"
                <br/>
                <br/>

                - "I appreciate it."
            </div>);
        }
        else if(this.state.aboutAddisonOn){
            about = (
            <div style={this.aboutStyle}>
                Ask addison any of these questions.
                <br/>
                <br/>

                - "Where did you go to school?"
                <br/>
                <br/>

                - "When is your Birthday?"
                <br/>
                <br/>

                - "How long have you been a Caregiver?"
                <br/>
                <br/>

                - "Do you have any hobbies?"
                <br/>
                <br/>

                - "Thank you."
            </div>);
        }
        else
            about = null;

        return this.state.on ? (
            <div>
                <div id="appOptionsSidebarContainer" style={this.containerStyle}>
                <button style={this.buttonStyle} className="btnTemplate" onClick={this.handleMoreAboutAddisonClick}> More about yourself</button>
                <button style={this.buttonStyle} className="btnTemplate"  onClick={this.handleMoreAboutCaregivers}> More about Caregivers </button>
                <button style={this.buttonStyle} className="btnDone"  onClick={this.handleDone}> Done </button>
                </div>
                {about}
            </div>
        ) : (null);
    }
}