import React, { Component } from 'react';
// import { XR, Hub } from 'aws-amplify';

export default class HelpDialogButton extends Component {
	getInitialState() {
		return {
			on: true
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars
    }


    render() { 
        return (
    	  <div className={this.state.on ? "" : "hidden"}>
			<button className="btnWide" id="btnBetter">
				<svg
					viewBox="0 0 442.533 442.533"
					width="2.9vw"
					height="2.9vw"
					overflow="visible"
			     >
			  		<g transform="translate(-240,50)"> 
						<path
							className="checkMark"
							fill="#d2d6e8"
							d="M434.539,98.499l-38.828-38.828c-5.324-5.328-11.799-7.993-19.41-7.993c-7.618,0-14.093,2.665-19.417,7.993L169.59,247.248   l-83.939-84.225c-5.33-5.33-11.801-7.992-19.412-7.992c-7.616,0-14.087,2.662-19.417,7.992L7.994,201.852   C2.664,207.181,0,213.654,0,221.269c0,7.609,2.664,14.088,7.994,19.416l103.351,103.349l38.831,38.828   c5.327,5.332,11.8,7.994,19.414,7.994c7.611,0,14.084-2.669,19.414-7.994l38.83-38.828L434.539,137.33   c5.325-5.33,7.994-11.802,7.994-19.417C442.537,110.302,439.864,103.829,434.539,98.499z" 
							/>
					</g>
				</svg>I Feel Better
			</button>

			<br />
			<br />
			<br />
			<br />
			<br />
			<br />

			<button className="btnWide" id="btnNeedHelp">
				<svg
				   	viewBox="0 0 398 398"
					width="2.25vw"
					height="2.25vw"
					overflow="visible"
					padding-right="0px"
			     >
					<g transform="translate(-240,20)">
						<path
							className="notYetX"
							fill="#d2d6e8"
							d="M288.8,199l97.1-97.1c6.2-6.2,9.2-13.7,9.2-22.5s-3.1-16.3-9.2-22.5L341,12c-6.2-6.2-13.7-9.2-22.5-9.2 S302.2,5.9,296,12l-97,97.2L101.9,12c-6.2-6.2-13.6-9.2-22.5-9.2C70.6,2.8,63.1,5.9,57,12L12,57c-6.2,6.2-9.2,13.7-9.2,22.5 S5.9,95.8,12,102l97.1,97.1l-97.1,97c-6.2,6.2-9.2,13.7-9.2,22.5s3.1,16.3,9.2,22.5L57,386c6.2,6.2,13.7,9.2,22.5,9.2 s16.3-3.1,22.5-9.2l97.1-97.1l97.1,97.1c6.2,6.2,13.6,9.2,22.5,9.2c8.8,0,16.3-3.1,22.5-9.2l44.8-45c6.2-6.2,9.2-13.7,9.2-22.5 s-3.1-16.3-9.2-22.5L288.8,199z"
							/>
					</g>
			</svg>
				I Need More Help
			</button>
		  </div>
        );
    }
}

