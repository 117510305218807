import React, { useState, useEffect, useRef } from 'react';
import { Logger, Hub } from 'aws-amplify';
// import * as handTrack from 'handtrackjs';
import FaceDetector from 'facedetector'; // eslint-disable-line no-unused-vars

// const handtrackWorker = new Worker('AppHandtrackWebWorker.js', { type: "module" });

const logger = new Logger('AppMedicationManagementContainer'); // eslint-disable-line no-unused-vars

const AppMedicationManagementContainer = (props) => {
    const [on, setOn] = useState(false);
    const [sources, setSources] = useState([]);
    const [offscreen, setOffscreen] = useState(null);
    const selectRef = useRef(null);
    const videoRef = useRef(null);
    const selectButtonRef = useRef(null);
    const canvasRef = useRef(null);

    var curStream = null/*,
        model = null*/;


    /**
     * This retrieves video sources and passes them to callback parameter
     */

    function getVideoSources(callback) {
        var videoSources = [];
        callback = callback || function() {};

        navigator.mediaDevices.enumerateDevices().then(function(mediaSources) {
            //filter out everything but the video sources
            const _sources = mediaSources.filter((source) => source.kind === 'videoinput');

            setSources(_sources);

            sources.forEach(function(source, index) {
                videoSources.push({
                    id: source.id,
                    label: source.label || 'Camera ' + (videoSources.length + 1)
                });
            });

            callback(videoSources);
        });
    }
    
    /*
    function resizeCanvas(element, canvasId) {
        var w = element.offsetWidth;
        var h = element.offsetHeight;
        // var cv = document.getElementById(canvasId);
        var cv = canvasRef.current;
        cv.width = w;
        cv.height = h;
    }
    */

    useEffect(() => {
        /**
         * Change stream source according to dropdown selection
         */

        // let cSelect = document.getElementById('cameraSelect');
        const cSelect = selectRef.current;
        if (cSelect !== null) {
            cSelect.addEventListener('change', function() {
                if (curStream && curStream.active) {
                    getCamera();
                }
            });
        }

        /**
         * Click handler to init the camera grab
         */

        // let cSelectButton = document.getElementById('cameraSelectButton');
        const cSelectButton = selectButtonRef.current;
        if (cSelectButton !== null) {
            cSelectButton.addEventListener('click', function(e) {
                // camera is active, stop stream
                if (curStream && curStream.active) {
                    curStream.stop();
                    document.getElementById("videoSrc").src = "";
                    // handtrackWorker.postMessage({
                    //     event: "stopCamera"
                    // });
                } else {
                    getCamera();
                    // handtrackWorker.postMessage({
                    //     event: "getCamera"
                    // });
                }
            });
        }



        /**
         * Populate camera sources drop down
         */

        getVideoSources(function(cameras) {
            var ddl = document.getElementById('cameraSelect');

            // if(!ddl) return;

            // if (!selectRef.current) return;

            // var ddl = selectRef.current;
            if (cameras.length === 1) {
                // if only 1 camera is found drop down can be disabled
                ddl.disabled = true;
            }

            cameras.forEach(function(camera) {
                var opt = document.createElement('option');
                opt.value = camera.id;
                opt.appendChild(document.createTextNode(camera.label));

                ddl.appendChild(opt);
            });
        });


        /**
         * Updates button state according to Camera stream status
         */

        const updateButtonState = () => {
            var btn = document.getElementById('cameraSelectButton');

            // if (!selectButtonRef.current) return;
            if (!btn) return;

            btn.disabled = false;

            if ((!curStream) || (!curStream.active)) {
                btn.innerHTML = "Enable Camera";
            } else {
                btn.innerHTML = "Disable Camera";
            }
        }

        function getCamera() {
            // var cameraSrcId = document.getElementById('cameraSelect').value;

            // constraints allow us to select a specific video source 
            var constraints = {
                video: {
                    width: { exact: 320 },
                    height: { exact: 240 },
                },
                audio: false,
                depth: false
            }

            navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia


            navigator.getUserMedia(constraints, function(stream) {
                // var videoElm = document.getElementById('videoSrc');
                // const canvasElem = document.getElementById('videoCanvas');

                if (!canvasRef.current || !videoRef.current) return;
                var videoElm = videoRef.current;
                // const canvasElem = canvasRef.current;

                videoElm.width = 320;
                videoElm.height = 240;
                videoElm.srcObject = stream;

                if (!offscreen) {
                    // resizeCanvas(videoElm, "videoCanvas");
                    const _offscreen = document.getElementById('videoCanvas').transferControlToOffscreen();
                    setOffscreen(_offscreen);
                    // handtrackWorker.postMessage({ canvas: _offscreen, event: 'canvas' }, [_offscreen]);
                }



                // const context = canvasElem.getContext('2d');

                // handtrackWorker.postMessage({
                //     event: "getUserMedia",
                //     // videoElm,
                //     // stream,

                // });


                // const modelParams = {
                //     flipHorizontal: true,
                //     imageScaleFactor: 0.6,
                //     maxNumBoxes: 20,
                //     iouThreshold: 0.6,
                //     scoreThreshold: 0.75
                // }

/*                handtrackWorker.onmessage = async (msg) => {
                    if (model && msg && msg.predictions) {
                        // model.renderPredictions(msg.predictions, canvasElem, context, videoElm);
                        // model.renderPredictions(msg.predictions, offscreen, context, videoElm);
                        logger.debug(`msg from web worker ${msg}`);
                    }
                };
*/
                //clone the stream so we have two streams to feed to two models
                // var streamClone = stream.clone();

                /*
                                const modelParams = {
                                    flipHorizontal: true,
                                    imageScaleFactor: 0.6,
                                    maxNumBoxes: 20,
                                    iouThreshold: 0.6,
                                    scoreThreshold: 0.75
                                }

                                model = null; //don't keep multiple models loaded in memory
                                handTrack.load(modelParams).then(lmodel => {
                                    model = lmodel;

                                    runDetection();
                                });

                */

                /*
                                const runDetection = () => {
                                    const begin = Date.now(); // eslint-disable-line no-unused-vars
                                    var end = null; // eslint-disable-line no-unused-vars
                                    model.detect(videoElm).then(predictions => {
                                        if (predictions[0]) {
                                            //console.log("Predictions: ", predictions)
                                            model.renderPredictions(predictions, canvasElem, context, videoElm);
                                        }

                                        setTimeout(runDetection, 1000 / 10); //15 FPS
                                    });

                                }
                */
                //videoElm.src = URL.createObjectURL(stream);
                stream.onended = function() {
                    updateButtonState();
                }

                //after getUserMedia

                /*
            faceDetector = new FaceDetector({
                video: document.getElementById("videoSrc"),
                flipLeftRight: false,
                flipUpsideDown: false
            });


            faceDetector.setOnFaceAddedCallback(function(addedFaces, detectedFaces) {
                for (var i = 0; i < addedFaces.length; i++) {
                    console.log("[facedetector] New face detected id=" + addedFaces[i].faceId + " index=" + addedFaces[i].faceIndex);
                }
            });

            faceDetector.setOnFaceLostCallback(function(lostFaces, detectedFaces) {
                for (var i = 0; i < lostFaces.length; i++) {
                    console.log("[facedetector] Face removed id=" + lostFaces[i].faceId + " index=" + lostFaces[i].faceIndex);
                }
            });

            faceDetector.setOnFaceUpdatedCallback(function(detectedFaces) {
                for (var i = 0; i < detectedFaces.length; i++) {
                    var face = detectedFaces[i];
                    console.log(face.faceId + " x=" + face.x + " y=" + face.y + " w=" + face.width + " h=" + face.height);
                }
            });

            faceDetector.startDetecting();
*/
                //TODO if face detected and hand detected

                videoElm.onplay = function() {
                    if (curStream !== null) {
                        // stop previous stream
                        // curStream.stop();
                    }

                    curStream = stream;
                    updateButtonState();
                }
            }, function(e) {
                curStream = null;
                console.error(e);
            });
        }



        Hub.listen("AppMedicationManagement", (capsule) => {
            const { payload } = capsule;
            const { event, message, data } = payload; // eslint-disable-line no-unused-vars

            switch (event) {
                case "show":
                    setOn(true);
                    break;
                case "hide":
                    setOn(false);
                    break;
                default:
                    console.log("hit default case in AppMedicationManagement Hub channel...");
            }
        })

        const cleanup = () => {
            Hub.remove("AppMedicationManagement");
            // model = null;
            // this.model = null;
        }

        return cleanup;
        // }, []);
    }, [selectButtonRef.current, selectRef.current, videoRef.current, canvasRef.current]);



    const containerStyle = {
        position: "absolute",
        zIndex: "900000000",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    };

    const canvasStyle = {
        position: "absolute",
        zIndex: "900000000",
        display: "flex",

    };

    return (
        <div style={containerStyle} className={on ? "" : "hidden"} id="videoStreamContainer">
            <video ref={videoRef} id="videoSrc" autoPlay></video>
            <canvas ref={canvasRef} style={canvasStyle} id="videoCanvas"></canvas>
            <p style={containerStyle}> 
                <button ref={selectButtonRef} id="cameraSelectButton">Enable Camera</button>
                <select ref={selectRef} id="cameraSelect"></select>
            </p>
        </div>
    );
};

export default AppMedicationManagementContainer;