import React, { Component } from 'react';
import { Hub } from 'aws-amplify';
import autobind from 'class-autobind';

export default class BeginButton extends Component {
	getInitialState() {
		return {
			on: false,
            owner: "CFRSI"
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        autobind(this);

        Hub.listen("ShowBeginButton", this.onHubCapsule);
        Hub.listen("HideBeginButton", this.onHubCapsule);
        Hub.listen("ToggleBeginButton", this.onHubCapsule);
    }

    onHubCapsule( capsule ) {
        const { channel, payload } = capsule; // eslint-disable-line no-unused-vars

        if(channel === "UIReset" || channel === "SceneReset") {
            //TODO
        } 

        else if (channel === "ShowBeginButton") {
            this.setState({on: true});
        } 

        else if (channel === "HideBeginButton") {
            this.setState({on: false});
        }
    }

    handleClick(event) {
        window.eventManager.emit("Begin");
        Hub.dispatch("HideBeginButton");

        if(this.state.owner === "CFRSI") {
            // Hub.dispatch("")
        }
    }


    render() {
        if(!(this.props.on)) return (null);

        const buttonStyle = {
            left: "50vw",
            top: "50vh"
        };

        return (
			<button type="button" id="beginButton" style={buttonStyle} className="begin-btn" onClick={this.handleClick}>Begin</button>
        );
    } 
}