import React, {Component} from 'react';
import { Hub } from 'aws-amplify';

export default class SupportButton extends Component {
	getInitialState() {
		return {
			on: true,
		}
	}

    constructor( props ) {
        super( props );
        this.state = this.getInitialState();

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.reset = this.reset.bind(this);
        this.getInitialState = this.getInitialState.bind(this);

        this.handleClick = this.handleClick.bind(this);


        Hub.listen("UIReset", (capsule) => this.setState(this.getInitialState()));
    }

    //ignore this for now
    componentDidMount() {

    }

    //ignore this for now
    componentDidUpdate() {

    }

    reset() {
        this.setState(this.getInitialState());
    }

    show() {
      this.setState({on: true});
    }

    hide() {
      this.setState({on: false});
    }

    handleClick(event) {
        event.preventDefault();
        console.log("clicked Support Button, reloading page...");
        // window.location.reload();
        // console.log("Addison Button clicked, emitting playIntroScene to Sumerian...");
        // window.amplifySceneLoader.dispatchPlayIntro();
    }

	render() {
		return (
 
			<button style={{zIndex: "10000"}} onClick={this.handleClick} className="btnaddison left-button ui-button" id="btnSupport"> 

				<svg viewBox="0 0 267.2 267.2" height="2.5vw" width="2.5vw" overflow="visible">
					<g transform="translate(50,25)">
			      <circle
			        className="a"
			        cx="130.28" 
			        cy="130.81" 
			        r="123.92"
			        fill="#D2D6E8"
			      
			        />
						<path 
			        className ="background"
			        fill="#0098C6"
							d="M147.6,68.3c-11.5-2.4-23.2-1.9-34.5,2c-11.5,4-21.3,10.2-28.9,19.8c-9.9,12.6-14.4,27-14.8,42.8
							c-0.1,6.2,0.7,12.3,2.2,18.3c5.1,20.5,16.9,35.5,36.5,43.8c14.4,6.1,29.4,6.6,44.5,2.5c18.3-5,31.7-16.2,39.5-33.3
							c9.5-20.7,9.3-41.8-0.6-62.4C182.6,83.3,167.4,72.4,147.6,68.3z"
			        />
						<path 
			        className="background"
			        fill="#0098C6"
							d="M133.6,3.3L133.6,3.3C61.9,3.3,3.3,61.9,3.3,133.6l0,0c0,71.6,58.6,130.2,130.2,130.2h0
							c71.6,0,130.2-58.6,130.2-130.2l0,0C263.8,61.9,205.2,3.3,133.6,3.3z M215.2,204.4c0,2.4-0.1,4.8-0.2,7c-4.1,1.7-7.5,1.9-15.5,0.8
							c-0.2-1.3-0.6-2.8-0.6-4.2c-0.1-7.7,0-15.4,0-23.1c0-1.7-0.3-3.4-0.5-5.1c-0.5-0.1-0.9-0.3-1.4-0.4c-0.7,1-1.5,2-2.2,3.1
							c-11.6,17.2-27.9,27.2-48.1,30.8c-15.4,2.8-30.7,2.1-45.4-3.6c-23.5-9.2-38.6-26.2-45.8-50.3c-3.5-12-4.6-24.2-3-36.6
							c2.4-18.7,9.6-35.1,23.3-48.4c10.9-10.5,23.9-16.8,38.7-20.1c8.9-2,18-2.4,26.9-1.6c26.6,2.2,47.8,14,62.2,36.9
							c5.9,9.4,9.4,19.9,10.6,31c0.7,5.9,0.9,11.8,0.9,17.8C215.3,160.4,215.2,182.4,215.2,204.4z"
			        />
				</g>
			</svg>



			&nbsp;Support&nbsp;

			  
			</button>

		);
	}
}